// import qs from "qs";
import requests from "./httpService";
// import Cookies from "js-cookie";
import {
   callPath,
   updatePath,
   searchReadPath,
   createPath,
   uploadFilePath,
} from "./endpoint";

const ProductService = {
   getAll: async () => {
      const jsonData = {
         model: "product.template",
         method: "web_search_read",
         // with_context: { allowed_company_ids: context },
         kwargs: {
            domain: [],
            specification: {
               image_256: {},
               product_variant_count: {},
               sale_ok: {},
               currency_id: {
                  fields: {},
               },
               cost_currency_id: {
                  fields: {},
               },
               priority: {},
               name: {},
               default_code: {},
               responsible_id: {
                  fields: {
                     display_name: {},
                  },
               },
               product_tag_ids: {
                  fields: {
                     display_name: {},
                  },
               },
               barcode: {},
               list_price: {},
               standard_price: {},
               categ_id: {
                  fields: {
                     display_name: {},
                  },
               },
               detailed_type: {},
               type: {},
               show_on_hand_qty_status_button: {},
               qty_available: {},
               virtual_available: {},
               active: {},
               activity_exception_decoration: {},
               activity_exception_icon: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getById: async (id) => {
      const jsonData = {
         model: "product.template",
         method: "web_search_read",
         kwargs: {
            domain: [["id", "=", id]],
            specification: {
               image_256: {},
               sale_ok: {},
               name: {},
               default_code: {},
               uom_id: {},
               uom_po_id: {},
               attribute_line_ids: {
                  fields: {
                     product_template_value_ids: {},
                     value_count: {},
                     sequence: {},
                     attribute_id: {
                        fields: {
                           display_name: {},
                        },
                     },
                     value_ids: {
                        fields: {
                           display_name: {},
                           color: {},
                        },
                        context: {
                           show_attribute: false,
                        },
                     },
                  },
               },
               weight: {},
               barcode: {},
               list_price: {},
               standard_price: {},
               categ_id: {},
               detailed_type: {},
               type: {},
               qty_available: {},
               property_stock_inventory: {},
               brand_id: {},
               description: {},
               description_pickingout: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getProductInPOS: async () => {
      const jsonData = {
         model: "product.template",
         method: "web_search_read",
         kwargs: {
            domain: [
               ["active", "=", true],
               ["available_in_pos", "=", true],
            ],
            specification: {
               image_256: {},
               product_variant_count: {},
               sale_ok: {},
               currency_id: {
                  fields: {},
               },
               cost_currency_id: {
                  fields: {},
               },
               priority: {},
               name: {},
               default_code: {},
               responsible_id: {
                  fields: {
                     display_name: {},
                  },
               },
               product_tag_ids: {
                  fields: {
                     display_name: {},
                  },
               },
               barcode: {},
               list_price: {},
               standard_price: {},
               categ_id: {
                  fields: {
                     display_name: {},
                  },
               },
               detailed_type: {},
               type: {},
               show_on_hand_qty_status_button: {},
               qty_available: {},
               virtual_available: {},
               active: {},
               activity_exception_decoration: {},
               activity_exception_icon: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getCategories: async () => {
      const jsonData = {
         model: "product.category",
         fields: ["id", "name", "complete_name"],
         domain: [],
      };
      return requests.post(searchReadPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getPositions: async () => {
      const jsonData = {
         model: "stock.location",
         fields: ["id", "name", "complete_name"],
         domain: [["usage", "=", "inventory"]],
      };
      return requests.post(searchReadPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getBrands: async () => {
      const jsonData = {
         model: "product.brand",
         fields: ["name", "id"],
         domain: [],
      };
      return requests.post(searchReadPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getAttributes: async () => {
      const jsonData = {
         model: "product.attribute",
         fields: ["name", "id"],
         domain: [],
      };
      return requests.post(searchReadPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getAttributesValues: async (body) => {
      return requests.post(searchReadPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   addOne: async (body) => {
      return requests.post(createPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   updateOne: async (body) => {
      return requests.post(updatePath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   setImage: async (body, queryParams) => {
      const query = new URLSearchParams(queryParams).toString();
      return requests.post(uploadFilePath + "?" + query, body, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   delete: async (body) => {
      return requests.post(callPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   createOnHandQty: async (body) => {
      return requests.post(createPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   confirmOnHandQty: async (body) => {
      return requests.post(callPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getProductsVariant: async (id) => {
      const jsonData = {
         model: "product.product",
         method: "web_search_read",
         kwargs: {
            specification: {
               priority: {},
               default_code: {},
               barcode: {},
               name: {},
               product_template_variant_value_ids: {
                  fields: {
                     display_name: {},
                  },
               },
               lst_price: {},
               standard_price: {},
               categ_id: {
                  fields: {
                     display_name: {},
                  },
               },
               type: {},
               qty_available: {},
               active: {},
            },
            // offset: 0,
            order: "active desc",
            // limit: 80,
            // count_limit: 10001,
            domain: [["product_tmpl_id", "=", parseInt(id)]],
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   editProductVariant: async (body) => {
      return requests.post(updatePath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getExtraCost: async (ids) => {
      const jsonData = {
         model: "product.template.attribute.value",
         method: "web_search_read",
         kwargs: {
            specification: {
               id: {},
               name: {},
               price_extra: {},
            },
            count_limit: 10001,
            domain: ["&", ["id", "in", ids], ["ptav_active", "=", true]],
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   updateExtraPrice: async (body) => {
      return requests.post(updatePath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   addValueOne: async (body) => {
      return requests.post(createPath, body, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },
   deleteAttributeValue: async (body) => {
      return requests.post(callPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   deleteAttribute: async (body) => {
      return requests.post(callPath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getUnits: async () => {
      const jsonData = {
         model: "uom.uom",
         fields: ["id", "name"],
         domain: [],
      };
      return requests.post(searchReadPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
};

export default ProductService;
