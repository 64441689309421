/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import RenderWidget from "./widget/RenderWidget";

const RowTree = ({
   index,
   fields,
   defaultValue,
   modelData,
   handleRemove,
   getOnChangeData,
}) => {
   const { register, control, watch } = useForm({
      mode: "onChange",
      values: defaultValue,
   });

   const data = watch();

   useEffect(() => {
      getOnChangeData(index, { ...data });
   }, [data]);

   return (
      <tr>
         {fields
            .filter((column) => !column.hide)
            ?.map((formItem, i) => {
               const { type, name, relation, required, placeholder, readonly } =
                  formItem?.fieldValue;
               const inputElement = RenderWidget(type, formItem.widget, {
                  name,
                  readonly,
                  placeholder,
                  required,
                  relation,
                  control,
                  register,
                  modelsData: modelData,
                  savedDataDetail: defaultValue,
               });

               if (!inputElement) return null;

               return (
                  <td key={i} className="p-2 border border-gray-300">
                     {inputElement}
                  </td>
               );
            })}
         <td className="p-2 border border-gray-300">
            <button
               type="button"
               className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
               onClick={() => handleRemove(index)}
            >
               Remove
            </button>
         </td>
      </tr>
   );
};

export default RowTree;
