/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";
import { PlusCircle } from "react-feather";

const Breadcrumbs = (props) => {
   return (
      <div className="page-header">
         <div className="add-item d-flex">
            <div className="page-title">
               <h4>{props.maintitle}</h4>
               <h6>{props.subtitle}</h6>
            </div>
         </div>
         <div className="page-btn">
            <button onClick={props.onClick}>
               <Link className="btn btn-added">
                  <PlusCircle className="me-2" />
                  {props.addButton}
               </Link>
            </button>
         </div>
      </div>
   );
};

export default Breadcrumbs;
