/* eslint-disable react/prop-types */
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import Select from "react-select";
import useAsync from "../../../core/hooks/useAsync";

import GeneralService from "../../../core/services/GeneralService";
const Many2OneField = ({ relation, name, control, savedDataDetail }) => {
   const { data: dataOfSelection } = useAsync(
      () => GeneralService.getSelectionItem({ model: relation }),
      `data_${relation}`
   );
   const options =
      dataOfSelection?.records?.map((val) => ({
         value: val.id,
         label: val.name,
      })) || [];
   const defaultValue = options.find(
      (option) => option.value === savedDataDetail[name]
   );
   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => {
            return (
               <Select
                  {...field}
                  options={options}
                  classNamePrefix="custom-select"
                  placeholder="Select an option"
                  isSearchable={true}
                  onChange={(selectedOption) =>
                     field.onChange(selectedOption.value)
                  }
                  value={
                     options.find((option) => option.value === field.value) ||
                     defaultValue
                  }
               />
            );
         }}
      />
   );
};

export default Many2OneField;
