import CategoryService from "../services/CategoryService";
import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { useTranslation } from "react-i18next";
const useCategorySubmit = () => {
  const { t } = useTranslation();
  const { setIsUpdate } = useContext(SidebarContext);
  const onSubmitModal = (id, parent_id, name) => {
    if (id) {
      const categoryData = {
        model: "product.category",
        domain: [["id", "=", `${id}`]],
        values: { name: name, parent_id: parent_id },
      };
      CategoryService.editOne(categoryData)
        .then(() => {
          alertify.set("notifier", "position", "top-right");
          alertify.success(t("edit_success"));
          setIsUpdate(true);
        })
        .catch((err) => {
          alertify.set("notifier", "position", "top-right");
          alertify.error(err?.message || "Something went wrong");
          console.log("🚀 ~ onImageUpload ~ err:", err);
        });
    } else {
      const categoryData = {
        model: "product.category",
        values: { name: name, parent_id: parent_id },
      };
      CategoryService.addOne(categoryData)
        .then(() => {
          alertify.set("notifier", "position", "top-right");
          alertify.success(t("add_success"));
          setIsUpdate(true);
        })
        .catch((err) => {
          alertify.set("notifier", "position", "top-right");
          alertify.error(err?.message || "Something went wrong");
          console.log("🚀 ~ onImageUpload ~ err:", err);
        });
    }
  };
  const onDelete = (id) => {
    const categoryData = {
      model: "product.category",
      ids: id,
      method: "unlink",
    };
    CategoryService.deleteOne(categoryData)
      .then(() => {
        alertify.set("notifier", "position", "top-right");
        alertify.success(t("delete_success"));
      })
      .catch((err) => {
        alertify.set("notifier", "position", "top-right");
        alertify.error(err?.message || "Something went wrong");
      });
  };
  return {
    onSubmitModal,
    onDelete
  };
};

export default useCategorySubmit;
