import requests from "./httpService";
import { callPath} from "./endpoint";

const SettingsService = {
  loadConfig: async () => {
    const jsonData = {
      model: "res.config.settings",
      method: "onchange",
      args: [
        {},
        [],
        {
          group_product_variant: {},
          group_uom: {},
          module_stock_barcode: {},
          group_stock_production_lot: {},
          sale_tax_id: {
            fields: {
              display_name: {}
            }
          },
          pos_ship_later: {},
          group_stock_adv_location: {},
          module_delivery: {},
          module_stock_landed_costs: {},
          group_discount_per_so_line: {},
          module_loyalty: {},
          pos_config_id: {
            fields: {
              display_name: {}
            }
          },
          pos_module_pos_restaurant: {},
          pos_module_pos_restaurant_appointment: {},
          pos_floor_ids: {
            fields: {
              display_name: {}
            }
          },
          pos_iface_splitbill: {},
          pos_payment_method_ids: {
            fields: {
              display_name: {}
            }
          },
          group_cash_rounding: {},
          pos_iface_tipproduct: {},
          pos_auto_validate_terminal_payment: {},
          pos_set_maximum_difference: {},
          pos_start_category: {},
          pos_iface_start_categ_id: {
            fields: {
              display_name: {}
            }
          },
          pos_is_margins_costs_accessible_to_every_user: {},
          pos_iface_orderline_notes: {},
          group_product_pricelist: {},
          pos_restrict_price_control: {}
        }
      ]
    };
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  loadConfigPOS: async (id) => {
    const jsonData = {
      model: "res.config.settings",
      method: "onchange",
      args: [
        {
          pos_config_id: id
        },
        [],
        {
          pos_config_id: {
            fields: {
              display_name: {}
            }
          },
          pos_module_pos_restaurant: {},
          pos_module_pos_restaurant_appointment: {},
          pos_floor_ids: {
            fields: {
              display_name: {}
            }
          },
          pos_iface_splitbill: {},
          pos_payment_method_ids: {
            fields: {
              display_name: {}
            }
          },
          group_cash_rounding: {},
          pos_iface_tipproduct: {},
          pos_auto_validate_terminal_payment: {},
          pos_set_maximum_difference: {},
          pos_start_category: {},
          pos_iface_start_categ_id: {
            fields: {
              display_name: {}
            }
          },
          pos_is_margins_costs_accessible_to_every_user: {},
          pos_iface_orderline_notes: {},
          group_product_pricelist: {},
          pos_restrict_price_control: {},
          pos_manual_discount: {},
          module_loyalty: {}
        }
      ]
    };
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  
  applyConfig: async (id) => {
    const jsonData = {
      model: "res.config.settings",
      method: "execute",
      ids: [
        id
      ]
    }
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  update: async (body) => {
    delete body.key
    const jsonData = {
      model: "res.config.settings",
      method: "web_save",
      kwargs: {
        vals: body,
        specification: {}
      }
    }
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  updateAndApply: async (body) => {
    const idUpdate = await SettingsService.update(body);
    await SettingsService.applyConfig(idUpdate[0].id)
    return idUpdate[0].id
  },
};

export default SettingsService;
