import requests from "./httpService";
import { callPath } from "./endpoint";

const ViewServices = {
   getFieldView: async ({ id }) => {
      const jsonData = {
         model: "ir.actions.act_window",
         method: "web_read",
         ids: [id],
         kwargs: {
            specification: {
               view_id: {
                  fields: {
                     name: {},
                     model: {},
                  },
               },
               res_model: {},
               views: {},
               search_view_id: {},
            },
         },
      };
      const res = await requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
      const model = res[0].res_model;
      const jsonDataView = {
         model: model,
         method: "get_fields_view",
         kwargs: {
            views: res[0].views,
            options: {
               toolbar: true,
            },
         },
      };
      return requests.post(callPath, jsonDataView, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getMenuView: async () => {
      const jsonData = {
         model: "ir.ui.menu",
         method: "web_search_read",
         ids: [],
         with_context: { lang: "en_US" },
         kwargs: {
            specification: {
               active: {},
               name: {},
               is_display: {},
               sequence: {},
               complete_name: {},
               action: {
                  fields: {
                     display_name: {},
                     type: {},
                     binding_view_types: {},
                  },
               },
               web_icon: {},
               web_icon_data: {},
               groups_id: {
                  fields: {
                     full_name: {},
                  },
                  limit: 40,
                  order: "",
               },
               display_name: {},
               child_id: {
                  fields: {
                     active: {},
                     name: {},
                     is_display: {},
                     sequence: {},
                     complete_name: {},
                     action: {
                        fields: {
                           display_name: {},
                           type: {},
                           binding_view_types: {},
                        },
                     },
                     web_icon: {},
                     web_icon_data: {},
                     groups_id: {
                        fields: {
                           full_name: {},
                        },
                        limit: 40,
                        order: "",
                     },
                     display_name: {},
                     child_id: {
                        fields: {
                           active: {},
                           name: {},
                           is_display: {},
                           sequence: {},
                           complete_name: {},
                           action: {
                              fields: {
                                 display_name: {},
                                 type: {},
                                 binding_view_types: {},
                              },
                           },
                           web_icon: {},
                           web_icon_data: {},
                           groups_id: {
                              fields: {
                                 full_name: {},
                              },
                              limit: 40,
                              order: "",
                           },
                           display_name: {},
                           child_id: {
                              fields: {
                                 active: {},
                                 name: {},
                                 is_display: {},
                                 sequence: {},
                                 complete_name: {},
                                 action: {
                                    fields: {
                                       display_name: {},
                                       type: {},
                                       binding_view_types: {},
                                    },
                                 },
                                 web_icon: {},
                                 web_icon_data: {},
                                 groups_id: {
                                    fields: {
                                       full_name: {},
                                    },
                                    limit: 40,
                                    order: "",
                                 },
                                 display_name: {},
                                 child_id: {
                                    fields: {},
                                    limit: 40,
                                    order: "",
                                 },
                              },
                              limit: 40,
                              order: "",
                           },
                        },
                        limit: 40,
                        order: "",
                     },
                  },
                  limit: 40,
                  order: "",
               },
            },
            domain: ["&", ["active", "=", true], ["parent_id", "=", false]],
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getViewById: async ({ id }) => {
      const jsonData = {
         model: "ir.actions.act_window",
         method: "web_read",
         ids: [id],
         kwargs: {
            specification: {
               view_id: {
                  fields: {
                     name: {},
                     model: {},
                  },
               },
               res_model: {},
               views: {},
               search_view_id: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
};

export default ViewServices;
