import { useState, useEffect, useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";

const useMultipleAsync = (asyncFunctions) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isUpdate, setIsUpdate } = useContext(SidebarContext);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);
      setError(null);
      
      try {
        const results = await Promise.all(asyncFunctions.map(fn => fn()));
        
        if (isMounted) {
          setData(results);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
          setData([]);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          setIsUpdate(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [isUpdate]);

  return { data, loading, error };
};

export default useMultipleAsync;