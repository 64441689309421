import React, { useEffect, useState, useContext } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link, useNavigate } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import { useForm } from "react-hook-form";
import useLoginSubmit from "../../../core/hooks/useLoginSubmit";
import { CookieStorage } from "../../../core/utils/Cookies";
import { useTranslation } from "react-i18next";
import ButtonLoading from "../../../InitialPage/ButtonLoading";
import { SidebarContext } from "../../../core/context/SidebarContext";

const Signin = () => {
   const { t } = useTranslation();

   const [isPasswordVisible, setPasswordVisible] = useState(false);
   const [isRemember, setIsRemember] = useState(false);
   let navigate = useNavigate();

   const { onSubmit } = useLoginSubmit({
      navigate: navigate,
      isRemember: isRemember,
   });
   const { isLoading } = useContext(SidebarContext);
   const togglePasswordVisibility = () => {
      setPasswordVisible((prevState) => !prevState);
   };
   const route = all_routes;
   const { register, handleSubmit } = useForm();

   const handleRemember = () => {
      !isRemember ? setIsRemember(true) : setIsRemember(false);
   };
   useEffect(() => {
      const hasAccessToken = () => {
         const token = CookieStorage.getAccessToken();
         return !!token;
      };

      if (hasAccessToken()) {
         navigate("/sales-dashboard");
      }
   }, []);

   return (
      <div className="main-wrapper">
         <div className="account-content">
            <div className="login-wrapper bg-img">
               <div className="login-content">
                  <form
                     onSubmit={handleSubmit(onSubmit)}
                     style={{ width: "70%" }}
                  >
                     <div className="login-userset">
                        <div
                           style={{ width: "200px !important" }}
                           className="login-logo logo-normal"
                        >
                           <ImageWithBasePath
                              src="assets/img/logo.svg"
                              alt="img"
                           />
                        </div>
                        <Link
                           to={route.dashboard}
                           className="login-logo logo-white"
                        >
                           <ImageWithBasePath
                              src="assets/img/logo-white.svg"
                              alt
                           />
                        </Link>
                        <div className="login-userheading">
                           <h3>{t("login")}</h3>
                           <h4>{t("note_login")}</h4>
                        </div>
                        <div className="form-login mb-3">
                           <label className="form-label">{t("account")}</label>
                           <div className="form-addons">
                              <input
                                 {...register("email")}
                                 placeholder={t("placeholder_email")}
                                 type="text"
                                 className="form-control"
                                 defaultValue="fctc@gmail.com"
                              />
                              <ImageWithBasePath
                                 src="assets/img/icons/mail.svg"
                                 alt="img"
                              />
                           </div>
                        </div>
                        <div className="form-login mb-3">
                           <label className="form-label">{t("password")}</label>
                           <div className="pass-group">
                              <input
                                 type={isPasswordVisible ? "text" : "password"}
                                 className="pass-input form-control"
                                 {...register("password")}
                                 placeholder={t("placeholder_password")}
                                 defaultValue="admin"
                              />
                              <span
                                 className={`fas toggle-password ${
                                    isPasswordVisible
                                       ? "fa-eye"
                                       : "fa-eye-slash"
                                 }`}
                                 onClick={togglePasswordVisibility}
                              ></span>
                           </div>
                        </div>
                        <div className="form-login authentication-check">
                           <div className="row">
                              <div className="col-12 d-flex align-items-center justify-content-between">
                                 <div className="custom-control custom-checkbox">
                                    <label className="checkboxs ps-4 mb-0 pb-0 line-height-1">
                                       <input
                                          value={isRemember}
                                          onChange={handleRemember}
                                          type="checkbox"
                                          className="form-control"
                                       />
                                       <span className="checkmarks" />
                                       Remember me
                                    </label>
                                 </div>
                                 <div className="text-end">
                                    <Link
                                       className="forgot-link"
                                       to={route.forgotPassword}
                                    >
                                       {t("forgot_password")}
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="form-login">
                           <ButtonLoading
                              loading={isLoading}
                              className={"btn btn-login"}
                              content={"login"}
                           />
                           {/* <button type="submit" className="btn btn-login">
                    {t("login")}
                  </button> */}
                        </div>
                     </div>
                  </form>
               </div>
               <div className="login-content">
                  <ImageWithBasePath
                     src="assets/img/main-signin.jpg"
                     alt="img"
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Signin;
