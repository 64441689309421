/* eslint-disable react/prop-types */
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";

const DateField = ({ name, control }) => {
   return (
      <Controller
         name={name}
         control={control}
         defaultValue={moment(new Date()).format("YYYY-MM-DD")}
         render={({ field }) => {
            return (
               <div className="bg-[#fff] border-[1px] border-solid border-[#E4E4E7] p-2 rounded-lg">
                  <DatePicker
                     selected={field.value ? new Date(field.value) : null}
                     onChange={(date) => {
                        const formattedDate = moment(date).format("YYYY-MM-DD");
                        field.onChange(formattedDate);
                     }}
                  />
               </div>
            );
         }}
      />
   );
};
export default DateField;
