/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import alertify from "alertifyjs";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import ButtonLoading from "../../../components/ui/ButtonLoading";
import DynamicForm from "../../../components/ui/DynamicForm";
import DynamicTree from "../../../components/ui/DynamicTree";
import useAsync from "../../../core/hooks/useAsync";
import { SidebarContext } from "../../../core/context/SidebarContext";
import { Domain } from "../../../core/domain/domain";
import { evaluateExpr } from "../../../core/domain/py";
import ModelService from "../../../core/services/modelService";
import useFetchData from "../../../core/hooks/useFetchData";
import TabButtons from "../../../components/ui/form/TabButtons";

const FromView = ({ listView, dataViewById }) => {
   const [loadingViewForm, setLoadingViewForm] = useState(true);
   const locations = useLocation();
   const path = locations.pathname;
   const segments = path.split("/");
   const lastSegment = segments.pop();
   const [searchParams] = useSearchParams();
   const idForm = searchParams.get("idForm");
   const model = searchParams.get("model");
   const [specification, setSpecification] = useState();
   const { isLoading, setIsLoading } = useContext(SidebarContext);
   const modelsData = listView?.models[model];
   const context = dataViewById ? dataViewById[0]?.context : null;
   const { detailData } = useFetchData(idForm, model, specification);
   const { data: formOnChange, setData: setOnchangeForm } = useAsync(() =>
      ModelService.onChangeForm({
         idForm: idForm,
         model: model,
         specification: specification,
         context: context,
      })
   );
   const [savedDataDetail, setSavedDataDetail] = useState([]);
   let dataTree = {};
   const { register, handleSubmit, control, getValues, setValue } = useForm({
      mode: "onChange",
      values: savedDataDetail,
   });
   const [selectedTabIndex, setSelectedTabIndex] = useState(0);
   const result = lastSegment?.startsWith("new-")
      ? lastSegment?.substring(4)
      : lastSegment ?? "";
   const formattedPath =
      result.replace("/", "").charAt(0).toUpperCase() + result.slice(1);

   const filterDataByNoneOptional =
      listView?.views?.form?.fields?.filter((value) =>
         Object.keys(listView?.models?.[model] || {}).includes(value.name)
      ) || [];

   const tabsData =
      listView?.views?.form?.tabs?.map((val) => {
         try {
            if (
               val.invisible === undefined ||
               val.invisible === "0" ||
               val.invisible === "False"
            ) {
               return { ...val, hide: false };
            } else if (val.invisible === "1" || val.invisible === "True") {
               return { ...val, hide: true };
            }
            if (formOnChange?.value && val.invisible) {
               try {
                  const domain = new Domain(val.invisible);
                  return { ...val, hide: domain.contains(formOnChange?.value) };
               } catch (error) {
                  return {
                     ...val,
                     hide: evaluateExpr(val.invisible, formOnChange?.value),
                  };
               }
            }
            return { ...val, hide: false };
         } catch (e) {
            console.error(e);
            return { ...val, hide: false };
         }
      }) || [];

   const filterTabByName = tabsData?.map((tab) => {
      if (tab.type === "form") {
         return (
            tab.form
               ?.filter((item) =>
                  Object.keys(listView?.models?.[model] || {}).includes(
                     item.name
                  )
               )
               .map((field) => {
                  const foundItem = listView?.models?.[model]?.[field.name];
                  return {
                     ...field,
                     fieldValue: foundItem,
                  };
               }) || []
         );
      } else if (tab.type.startsWith("tree")) {
         return tab;
      } else if (tab.type === "tree_form") {
         return (
            tab.form
               ?.filter((item) =>
                  Object.keys(listView?.models?.[model] || {}).includes(
                     item.name
                  )
               )
               .map((field) => {
                  const foundItem = listView?.models?.[model]?.[field.name];
                  return {
                     ...field,
                     fieldValue: foundItem,
                  };
               }) || []
         );
      }
      return [];
   });

   const columns = Array.isArray(filterDataByNoneOptional)
      ? filterDataByNoneOptional.map((field) => ({
           ...field,
           fieldValue: listView?.models?.[model]?.[field.name],
           hide:
              field.invisible
                 .map((val) => {
                    try {
                       if (
                          val === undefined ||
                          val === "0" ||
                          val === "False"
                       ) {
                          return false;
                       } else if (val === "1" || val === "True") {
                          return true;
                       }
                       try {
                          if (formOnChange?.value && val) {
                             const domain = new Domain(val);
                             return domain.contains(formOnChange?.value);
                          }
                       } catch (error) {
                          if (formOnChange?.value && val) {
                             return evaluateExpr(val, formOnChange?.value);
                          }
                          return false;
                       }
                       return false;
                    } catch (e) {
                       console.error(e);
                       return false;
                    }
                 })
                 .filter(Boolean).length > 0,
        }))
      : [];

   const onImageUpload = (file, formId) => {
      const formData = new FormData();
      formData.append("ufile", file);
      const queryParams = {
         model: model,
         id: formId,
         field: "image_1920",
         ufile: "Cash Machine.png",
      };
      ModelService.setImage(formData, queryParams)
         .then(() => {
            alertify.set("notifier", "position", "top-right");
            alertify.success("Update Success");
         })
         .catch((err) => {});
   };

   const getChangeData = () => {
      const changedData = formOnChange?.value;
      const currentValues = getValues();
      for (const key in dataTree) {
         if (currentValues[key] !== dataTree[key]) {
            changedData[key] = dataTree[key];
         }
      }
      return changedData;
   };

   const handleDataFromTree = (data) => {
      dataTree = data;
   };

   const onCreateForm = (data) => {
      setIsLoading(true);
      const changedData = getChangeData();

      try {
         if (idForm) {
            ModelService.updateForm({
               model: model,
               body: changedData,
               id: idForm,
            })
               .then((res) => {
                  if (res.status === 400) {
                     alertify.set("notifier", "position", "top-right");
                     alertify.error(
                        res?.data?.arguments[0] || "Something went wrong"
                     );
                  } else {
                     changedData.image_1920 &&
                        onImageUpload(changedData.image_1920, res[0]);
                     alertify.set("notifier", "position", "top-right");
                     alertify.success(
                        `Create Form ${formattedPath} Successfully`
                     );
                     setIsLoading(false);
                     const btnAdd = document?.querySelector(
                        ".breadcrumb-btn-added"
                     );
                     btnAdd?.click();
                  }
               })
               .catch((err) => {
                  alertify.set("notifier", "position", "top-right");
                  alertify.error(err?.message || "Something went wrong");
                  setIsLoading(false);
               });
         } else {
            ModelService.createForm({ model: model, body: changedData })
               .then((res) => {
                  if (res.status === 400) {
                     alertify.set("notifier", "position", "top-right");
                     alertify.error(
                        res?.data?.arguments[0] || "Something went wrong"
                     );
                  } else {
                     changedData.image_1920 &&
                        onImageUpload(changedData.image_1920, res[0]);
                     alertify.set("notifier", "position", "top-right");
                     alertify.success(
                        `Create Form ${formattedPath} Successfully`
                     );
                     setIsLoading(false);
                     const btnAdd = document?.querySelector(
                        ".breadcrumb-btn-added"
                     );
                     btnAdd?.click();
                  }
               })
               .catch((err) => {
                  alertify.set("notifier", "position", "top-right");
                  alertify.error(err?.message || "Something went wrong");
                  setIsLoading(false);
               });
         }
      } catch (error) {
         console.log(error);
         setIsLoading(false);
      }
   };

   useEffect(() => {
      if (listView?.models?.[model] && model) {
         const modelValues = Object.values(listView.models[model]);
         if (modelValues.length > 0) {
            const result = modelValues.reduce((acc, item) => {
               if (item && typeof item === "object" && "name" in item) {
                  acc[item.name] = {};
               }
               return acc;
            }, {});
            setSpecification(result);
         } else {
            console.warn("modelValues is empty");
         }
      } else {
         console.warn("listView.models[model] or model is undefined");
      }
   }, [listView, model]);

   const fetchonChangeForm = async (model, context) => {
      const response = await ModelService.onChangeForm({
         idForm: idForm,
         model: model,
         specification: specification,
         context: context,
      });
      setOnchangeForm(response);
      setLoadingViewForm(false);
   };

   useEffect(() => {
      if (specification && model && context) {
         fetchonChangeForm(model, context);
      }
   }, [specification, model, context]);

   useEffect(() => {
      if (detailData && detailData[0]) {
         const newData = {};
         for (const [key, value] of Object.entries(detailData[0])) {
            newData[key] = value === false ? "" : value;
         }
         setSavedDataDetail(newData);
      }
   }, [detailData]);

   return (
      <>
         {
            <form className="relative" onSubmit={handleSubmit(onCreateForm)}>
               <div className="card">
                  <div className="px-6 py-8 rounded-2xl mb-6 bg-white overflow-y-auto h-[calc(100vh-180px)]">
                     <div className="flex flex-col gap-4">
                        <DynamicForm
                           data={columns}
                           register={register}
                           control={control}
                           modelsData={modelsData}
                           savedDataDetail={savedDataDetail}
                           setValue={setValue}
                           hide={false}
                        />
                        <div className="flex flex-col gap-2">
                           <TabButtons
                              tabsData={tabsData}
                              selectedTabIndex={selectedTabIndex}
                              setSelectedTabIndex={setSelectedTabIndex}
                           />
                           <div className="tab-content">
                              {filterTabByName.map((tabData, index) => {
                                 return (
                                    <div
                                       key={index}
                                       className={`w-full ${
                                          selectedTabIndex === index
                                             ? ""
                                             : "hidden"
                                       }`}
                                    >
                                       {tabData?.type?.startsWith("tree") ? (
                                          <DynamicTree
                                             tabData={tabData}
                                             listView={listView}
                                             model={model}
                                             parentData={getChangeData()}
                                             getValues={getValues()}
                                             setSavedDataDetail={
                                                handleDataFromTree
                                             }
                                          />
                                       ) : (
                                          <DynamicForm
                                             data={tabData}
                                             register={register}
                                             control={control}
                                             modelsData={modelsData}
                                             savedDataDetail={savedDataDetail}
                                             hide={true}
                                          />
                                       )}
                                    </div>
                                 );
                              })}
                           </div>
                        </div>
                        <div className="flex justify-end gap-2">
                           <div className="form-login">
                              <ButtonLoading
                                 loading={isLoading}
                                 className={`bg-[#ED1C24] p-2 font-bold text-[#fff] rounded-[8px] mr-6 ${
                                    isLoading
                                       ? "opacity-50"
                                       : "hover:opacity-85"
                                 }`}
                                 content={`Save ${formattedPath}`}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </form>
         }
      </>
   );
};
export default FromView;
