/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "antd";
import { useTableContext } from "../../feature-module/pages/listView/TableContext";

const Datatable = ({ props, columns, dataSource, onClick }) => {
   const { selectedRowKeys, setSelectedRowKeys } = useTableContext();
   const onSelectChange = (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
   };

   const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
   };
   return (
      <Table
         key={props}
         className="table datanew dataTable no-footer z-0"
         rowSelection={rowSelection}
         columns={columns}
         dataSource={dataSource}
         rowKey={(record) => record.id}
         onRow={(record) => {
            return {
               onClick: () => onClick(record.id),
            };
         }}
         pagination={false}
      />
   );
};

export default Datatable;
