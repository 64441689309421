export const subcateorydata = [
	{
		"id":1,
		"img": "assets/img/products/product1.jpg",
		"category": "Computers",
		"parentcategory": "Computers",
		"categorycode": "CT001",
		"description": "Computers description",
		"createdby": "Admin",
		
	},
	{
		"id":2,
		"img": "assets/img/products/product2.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT002",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":3,
		"img": "assets/img/products/product3.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT003",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":4,
		"img": "assets/img/products/product4.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT004",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":5,
		"img": "assets/img/products/product5.jpg",
		"category": "Accessories",
		"parentcategory": "Accessories",
		"categorycode": "CT005",
		"description": "Accessories description",
		"createdby": "Admin",
		
	},
	{
		"id":6,
		"img": "assets/img/products/product6.jpg",
		"category": "Shoes",
		"parentcategory": "Shoes",
		"categorycode": "CT006",
		"description": "Shoes description",
		"createdby": "Admin",
		
	},
	{
		"id":7,
		"img": "assets/img/products/product7.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT007",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":8,
		"img": "assets/img/products/product8.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT008",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":9,
		"img": "assets/img/products/product9.jpg",
		"category": "Computers",
		"parentcategory": "Computers",
		"categorycode": "CT009",
		"description": "Computers description",
		"createdby": "Admin",
		
	},
	{
		"id":10,
		"img": "assets/img/products/product10.jpg",
		"category": "Health Care",
		"parentcategory": "Health Care",
		"categorycode": "CT0010",
		"description": "Health Care description",
		"createdby": "Admin",
		
	},
	{
		"id":11,
		"img": "assets/img/products/product11.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT004",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":12,
		"img": "assets/img/products/product12.jpg",
		"category": "Accessories",
		"parentcategory": "Accessories",
		"categorycode": "CT005",
		"description": "Accessories description",
		"createdby": "Admin",
		
	},
	{
		"id":13,
		"img": "assets/img/products/product13.jpg",
		"category": "Shoes",
		"parentcategory": "Shoes",
		"categorycode": "CT006",
		"description": "Shoes description",
		"createdby": "Admin",
		
	},
	{
		"id":14,
		"img": "assets/img/products/product14.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT007",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":15,
		"img": "assets/img/products/product15.jpg",
		"category": "Fruits",
		"parentcategory": "Fruits",
		"categorycode": "CT008",
		"description": "Fruits description",
		"createdby": "Admin",
		
	},
	{
		"id":16,
		"img": "assets/img/products/product16.jpg",
		"category": "Computers",
		"parentcategory": "Computers",
		"categorycode": "CT009",
		"description": "Computers description",
		"createdby": "Admin",
		
	},
	{
		"id":17,
		"img": "assets/img/products/product17.jpg",
		"category": "Health Care",
		"parentcategory": "Health Care",
		"categorycode": "CT0010",
		"description": "Health Care description",
		"createdby": "Admin",
		
	}
]