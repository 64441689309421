import requests from "./httpService";
import {
  createPath,
  searchReadPath,
  updatePath,
  callPath,
} from "./endpoint";

const CategoryService = {
  addOne: async (body) => {
    return requests.post(createPath, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  editOne: async (body) => {
    return requests.post(updatePath, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  getCategories: async () => {
    const jsonData = {
      model: "product.category",
      fields: ["id", "complete_name", "name", "product_count", "create_date", "parent_id"],
      domain: [],
    };
    return requests.post(searchReadPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  deleteOne: async (body) => {
    return requests.post(callPath, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default CategoryService;
