import requests from './httpService';
import { callPath } from './endpoint';

const GeneralService = {
  getAll: async ({ model, specification, domains = [], page, sort = '' }) => {
    const jsonData = {
      model: model,
      method: 'web_search_read',
      ids: [],
      kwargs: {
        count_limit: 10001,
        domain: domains,
        limit: 80,
        offset: page,
        order: sort,
        specification: specification,
      },
    };
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  getViewId: async ({ id }) => {
    const jsonData = {
      model: 'ir.actions.act_window',
      method: 'web_read',
      ids: [id],
      kwargs: {
        specification: {
          view_id: {
            fields: {
              name: {},
            },
          },
          views: {},
          res_model: {},
          search_view_id: {},
        },
      },
    };
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  getSelectionItem: async ({ model }) => {
    const jsonData = {
      model: model,
      method: 'web_search_read',
      ids: [],
      kwargs: {
        count_limit: 10001,
        domain: [],
        limit: 10,
        offset: 0,
        order: '',
        specification: {
          id: {},
          name: {},
        },
      },
    };
    return requests.post(callPath, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};
export default GeneralService;
