import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import useAsync from "../../hooks/useAsync";
import PaymentMethodService from "../../services/PaymentMethodService";
import { CookieStorage } from "../../utils/Cookies";
import useSettingSubmit from "../../hooks/useSettingSubmit";
import PropTypes from "prop-types";
// import makeAnimated from "react-select/animated";

const customerStyles = {
    control: (provided) => ({
       ...provided,
       width: "100%"
    }),
    multiValue: (provided) => ({
       ...provided,
    //    width: "100%",
    backgroundColor: "#dee2e6"
    }),
 };

const EditPaymentMethodPOS = (isRefresh) =>{
    // const animatedComponents = makeAnimated();
    const { t } = useTranslation();
    const {
        handleSubmit,
      } = useForm();
    
    const {onUpdate} = useSettingSubmit();

    const { data, loading} = useAsync(
        () => PaymentMethodService.getAll(),
        "paymentMethodList"
    );
    const [settingData, setSettingData] = useState(CookieStorage.getSetting())
    
    const [paymentMethodList, setPaymentMethodList] = useState( [])

    const [paymentMethodListSelected, setPaymentMethodListSelected] = useState( [])
    
    const handleUpdate = () =>{
        const requestData = paymentMethodListSelected?.map((record)=>(
            [4,record.value]
        ))
        onUpdate({
            pos_payment_method_ids: requestData,
            pos_config_id: settingData?.pos_config_id?.id
        }).then(()=>{
            const newData = paymentMethodListSelected?.map((record)=>(
                [
                    4,
                    record.value,
                    {
                        display_name: record.label,
                        id: record.value
                    }
                ]
            ))
            const updateSettings = {
                ...settingData,
                pos_payment_method_ids: newData,
              };
              setSettingData(updateSettings);
              CookieStorage.setSetting(updateSettings);
            
        })
        if (!settingData){
            setSettingData(data)
        }
    }
    
    useEffect(()=>{
        setSettingData(CookieStorage.getSetting())
    },[isRefresh])

    useEffect(()=>{
        
        if (settingData){
            const settingSelected = settingData?.pos_payment_method_ids.map((record) =>({
                value: record[2].id,
                label: record[2].display_name
                }))
                console.log(settingSelected)
            setPaymentMethodListSelected(settingSelected)
        }
        if (data) {
            const newSelected = data?.records.map((record) => ({
              value: record.id,
              label: record.name,
            }));
            setPaymentMethodList(newSelected);
        }
    },[data, settingData])
    
    return ( <>
        { !loading && (
            <div>
          <div className="modal fade" id="edit-payment-method-pos-model">
            <div className="modal-dialog modal-dialog-centered custom-modal-two">
              <div className="modal-content">
                <div className="page-wrapper-new p-0">
                  <div className="content">
                    <div className="modal-header border-0 custom-modal-header">
                      <div className="page-title">
                        <h4>{t("payment_method")}</h4>
                      </div>
                      <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body custom-modal-body">
                      <form onSubmit={handleSubmit(handleUpdate)}>
                        <div className="row">
                              <CreatableSelect
                                isMulti
                                // components={animatedComponents}
                                styles={customerStyles}
                                options={paymentMethodList}
                                value={paymentMethodListSelected}
                                placeholder= {t("select_payment_method_placeholder")}
                                onChange={(selectedOptions) => {
                                    setPaymentMethodListSelected(selectedOptions);
                                }}
                              />
                        </div>

                        <div className="modal-footer-btn">
                          <button
                            type="button"
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                          >
                            {t("cancel_button")}
                          </button>
                          <button
                            type="submit"
                            className="btn btn-submit"
                          >
                            {t("update_button")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
        
        </>
    )
}

EditPaymentMethodPOS.prototype = {
    isRefresh: PropTypes.bool
}

export default EditPaymentMethodPOS