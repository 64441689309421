/* eslint-disable react/prop-types */

import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import Select from "react-select";
import useAsync from "../../../core/hooks/useAsync";
import GeneralService from "../../../core/services/GeneralService";

const Many2ManyTag = ({ relation, name, control }) => {
   const { data: dataOfSelection } = useAsync(
      () => GeneralService.getSelectionItem({ model: relation }),
      `data_${relation}`
   );

   const options =
      dataOfSelection?.records?.map((val) => ({
         value: val.id,
         label: val.name,
      })) || [];

   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <Select
               options={options}
               isMulti
               value={options.filter((option) =>
                  field.value?.includes(option.value)
               )}
               onChange={(selectedOptions) => {
                  const newValue = selectedOptions.map(
                     (option) => option.value
                  );
                  field.onChange(newValue);
               }}
               className="mb-2 col-span-2"
            />
         )}
      />
   );
};

export default Many2ManyTag;
