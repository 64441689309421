import { useState, useEffect, useContext, useRef, useCallback } from "react";
import { SidebarContext } from "../context/SidebarContext";

const cache = new Map();

const useAsyncDepend = (asyncFunction, cacheKey, dependencies = []) => {
    const [data, setData] = useState(() => cache.get(cacheKey) || [] || {});
    const [loading, setLoading] = useState(!cache.has(cacheKey));
    const [error, setError] = useState(null);
    const { isUpdate } = useContext(SidebarContext);
    const dataRef = useRef(data);

    const fetchData = useCallback(
        async (force = false) => {
            if (!force && cache.has(cacheKey)) {
                setData(cache.get(cacheKey));
                setLoading(false);
                return;
            }
            setLoading(true);
            try {
                //console.log("Dependencies: ", dependencies);
                const res = await asyncFunction();

                if (JSON.stringify(res) !== JSON.stringify(dataRef.current)) {
                    setData(res);
                    dataRef.current = res;
                    cache.set(cacheKey, res);
                }
                setError(null);
            } catch (err) {
                //console.log("Error: ", err);
                setError(err);
                setData([]);
            } finally {
                setLoading(false);
            }
        },
        [asyncFunction, cacheKey]
    );

    useEffect(() => {
        fetchData(true);
    }, [isUpdate, ...dependencies]);

    const refetch = useCallback(() => {
        return fetchData(true);
    }, [fetchData]);

    return {
        data,
        error,
        loading,
        setData,
        refetch,
    };
};

export default useAsyncDepend;
