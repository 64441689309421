/* eslint-disable react/prop-types */
import React from "react";
import InputField from "../InputField";

const HiddenInputFields = ({ data, register }) => {
   return (
      <div style={{ display: "none" }}>
         {data
            ?.filter((val) => val.hide !== true)
            .map((value, index) => {
               const { type, name, readonly } = value.fieldValue;
               return (
                  <InputField
                     key={index}
                     type={type}
                     register={register}
                     name={name}
                     readonly={readonly}
                  />
               );
            })}
      </div>
   );
};

export default HiddenInputFields;
