import alertify from "alertifyjs";
import { useTranslation } from "react-i18next";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import SettingsService from "../services/SettingsService";

const useSettingSubmit = () => {
  const { t } = useTranslation();

  const onUpdate = async (request) => (
    SettingsService.updateAndApply(request)
      .then(() => {
        alertify.set("notifier", "position", "top-right");
        alertify.success(t("setting_success_update"));
      })
      .catch((err) => {
        alertify.set("notifier", "position", "top-right");
        alertify.error(err?.message || t("fail_api_default_text"));
      })
    );

  return {
    onUpdate
  };
};

export default useSettingSubmit;
