import React from 'react';

import PropTypes from 'prop-types';

import Loader from "react-js-loader";
const Loading = ({ marginLeft = "0px" }) => {
    return (
        <div style={{ marginTop: "100px", marginLeft: marginLeft }}>
            <Loader type="ekvalayzer" color="#06aeff" bgColor="#06aeff" size={100} />
        </div>
    );

};

Loading.propTypes = {
    marginLeft: PropTypes.string,

};

export default Loading;

// export const LoaderUI = () => {
//     const [loading, setLoading] = useState(false);
//     const location = useLocation();

//     const showLoader = () => {
//         setLoading(true);
//     };

//     const hideLoader = () => {
//         setLoading(false);
//         window.scrollTo(0, 0);
//     };

//     useEffect(() => {
//         showLoader();
//         const timeoutId = setTimeout(() => {
//             hideLoader();
//         }, 1000);

//         return () => {
//             clearTimeout(timeoutId);
//         };
//     }, [location.pathname]);

//     return (
//         <div>
//             {loading && (
//                 <div id="global-loader">
//                     <div className="whirly-loader"></div>
//                 </div>
//             )}
//             <Routes>
//                 <Route path="/" />
//             </Routes>
//         </div>
//     );
// };

