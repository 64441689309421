import requests from "./httpService";

import { callPath, createPath, updatePath, uploadFilePath } from "./endpoint";

const ModelService = {
   getAll: async ({ model, specification, domain = [], page, sort = "" }) => {
      const jsonData = {
         model: model,
         method: "web_search_read",
         ids: [],
         kwargs: {
            count_limit: 10001,
            domain: domain,
            limit: 10,
            offset: page,
            order: sort,
            specification: specification,
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   createForm: async ({ model, body }) => {
      const transformObject = (obj) => {
         return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
               key,
               value === "" ||
               (typeof value === "object" &&
                  value !== null &&
                  Object.keys(value).length === 0)
                  ? false
                  : value,
            ])
         );
      };
      const jsonData = {
         model: model,
         values: { ...transformObject(body) },
      };
      return requests.post(createPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   deleteByForm: async ({ id, model }) => {
      const jsonData = {
         model: model,
         method: "unlink",
         ids: id,
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   updateForm: async ({ model, body, id }) => {
      const jsonData = {
         model: model,
         domain: [["id", "=", id]],
         values: body,
      };
      return requests.post(updatePath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   getDetailDataOfForm: async ({ idForm, model, specification }) => {
      const jsonData = {
         model: model,
         method: "web_read",
         ids: [idForm],
         kwargs: {
            specification: specification,
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   setImage: async (body, queryParams) => {
      const query = new URLSearchParams(queryParams).toString();
      return requests.post(uploadFilePath + "?" + query, body, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   onChangeForm: async ({ idForm, model, specification, context }) => {
      const jsonData = {
         model: model,
         method: "onchange",
         ids: idForm ? [idForm] : [],
         with_context: context,
         args: [{}, [], specification],
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
};

export default ModelService;
