import React from 'react';
import HeaderPDF from './components/headerPDF';
import ClientPDF from './components/clientHeaderPDF';
import FooterPDF from './components/footerPDF';
import PaymentMethodPDF from './components/paymentMethodPDF';
import InvoiceDataPDF from './components/invoiceDataPDF';
import TaxPDF from './components/taxPDF';

const InvoicePDF = React.forwardRef((props,ref) => {
  const listPaymentMethod = [
    { id: 1, name: 'Cash', price: 200000 },
    { id: 2, name: 'Credit Card', price: 2000000 },
  ]

  const listInvoiceData = [
    { id: 1, name: 'Product Name', quantity: 10, price: 200000 },
    { id: 2, name: 'Product Name', quantity: 10, price: 2000000},
    { id: 3, name: 'Product Name', quantity: 10, price: 2000000},
    { id: 4, name: 'Product Name', quantity: 10, price: 2000000}
  ]

  console.log(props)
  return (
    <div ref={ref} style={{ padding: '20px' }}> {/**textAlign: 'center' */}
     <HeaderPDF
      name="Company Name"
      address="Company Address"
      tel = "0123456789"
      tax_id = "123456-890-098"
     />

      <ClientPDF
        name='Client Name'
        address='Client Adddress'
      />

      <InvoiceDataPDF
        list={listInvoiceData}
        total = {20000}
      />

      <PaymentMethodPDF
        list={listPaymentMethod}
      />

      <TaxPDF
        tax = {15} 
        amount = {20000}
        base = {20000}
        total = {20000}
      />

      <FooterPDF/>

    </div>
  )});

InvoicePDF.displayName = "Invoice-PDF"

export default InvoicePDF;