import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Edit, Trash2
} from "react-feather";    // Feather Icons
import useAsync from "../../core/hooks/useAsync";   // For fetching data
import ScrollTable from "../../core/pagination/scrolldatatable";
import { all_routes } from "../../Router/all_routes";   // For router
import { Link } from "react-router-dom";    // For Link
import InvoiceLineEditModal from "../../core/modals/sales/invoicelineedit";  // Invoice Line Modal
import InvoiceLineAddModal from "../../core/modals/sales/invoicelineadd";  // Invoice Line Modal
import InvoiceLineModal from "../../core/modals/sales/invoicelinedetail";  // Invoice Line Modal
import { Alert } from "../../core/modals/confirm/alert"; // Confirmation
import { SuccessConfirm } from "../../core/modals/confirm/successconfirm"; // Success confirmation
import { FailConfirm } from "../../core/modals/confirm/failconfirm"; // Fail confirmation
import { useTranslation } from "react-i18next"; // For translatio
import { ToggleButton, ToggleButtonGroup } from '@mui/material';    // For Toggle Buttons
import { Button } from "../components/button";   // Button
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import InvoiceService from "../../core/services/InvoiceService";    // Invoice Service
import { DatePicker } from "antd";  // DatePicker
import dayjs from 'dayjs';  // For Date Picker
import Select from "react-select";  // For selector
import Breadcrumbs from "../../core/breadcrumbs/breadcrumbs";
import useInvoiceGetDetail from "../../core/hooks/useInvoiceGetDetail";
import Loading from "../../InitialPage/Sidebar/Loading";    // For loading data wait

const InvoiceEdit = () => {
    const dateFormat = "YYYY-MM-DD";    // Date Format

    // Router 
    const route = all_routes;
    const { t } = useTranslation(); // Translation
    const MySwal = withReactContent(Swal);  // Swal object
    // Disable editing
    const [disable, setDisable] = useState(false);
    // Invoice detail
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [currLineData, setCurrLineData] = useState(null); // Current Line Data (that need to be edited)
    const [, setLinesData] = useState([]);
    const [tempLinesData, setTempLinesData] = useState([]);
    // Current lines
    const [currLine, ] = useState(0);

    // Invoice State
    //const [state, setState] = useState(null);

    /* ----- Get invoice id ----- */
    const { id } = useParams();
    const invoiceId = id;

    // Define column table
    const columns = [
        {
            title: t("product_name"),
            dataIndex: "product_id",
            render: (product) => (<span>{product[1]}</span>),
            sorter: (a, b) => a.product_id[1].length - b.product_id[1].length,
        },
        {
            title: t("quantity"),
            dataIndex: "quantity",
            render: (quantity) => (<span>{quantity ? quantity : 0}</span>),
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: t("price_unit"),
            dataIndex: "price_unit",
            render: (price) => (
                <div className='d-flex justify-content-end'>
                    <span>{price ? price : 0} VND</span>
                </div>
            ),
            sorter: (a, b) => a.price_unit - b.price_unit,
        },
        // Action column
        {
            title: t("action"),
            render: (text, record, index) => {

                // Function to delete line by id
                const deleteLine = (index) => {
                    //console.log("Delete a Line");
                    setTempLinesData(prevItems => prevItems.filter((_, i) => i !== index));
                };

                return (
                    <div className="action-table-data">
                        <div className="edit-delete-action">
                            <div className="input-block add-lists"></div>
                            {!disable &&
                                <Link
                                className="me-2 p-2"
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#invoice-line-edit"
                                onClick={() => {
                                    //console.log("Current Line Data: ", tempLinesData[index]);
                                    setCurrLineData(
                                        {
                                            index: index,
                                            data: tempLinesData[index]
                                        }
                                    );
                                }}
                            >

                                <Edit className="feather-edit" />
                            </Link>
                            }
                            {!disable &&
                                <Link
                                    className="confirm-text p-2"
                                    to="#"
                                    onClick={() => deleteLine(index)}
                                >

                                    <Trash2 className="feather-trash-2" />
                                </Link>
                            }

                        </div>
                    </div>
                );
            },
        },
    ];

    /* ----- Get Invoice Details ----- */
    const fetchData = useInvoiceGetDetail(invoiceId);

    /* ----- Save data to State----- */
    useEffect(() => {
        setData(fetchData?.detail);
        setNewData(fetchData?.detail);
    }, [fetchData.detail]);
    useEffect(() => {
        setLinesData(fetchData?.lineDetail);
        setTempLinesData(fetchData?.linesDetail);
    }, [fetchData.linesDetail]);

    //console.log("New Data: ", newData);

    /*----- Set disable -----*/
    useEffect(() => {
        if (data?.state === 'posted') setDisable(true);
        else setDisable(false);
    }, [data]);

    // Fetch Customer
    const fetchCustomersFunc = async () => {
        const response = await InvoiceService.getCustomerAll();
        // Set payment Data
        const result = response?.map((item) => {
            return {
                value: item?.id,
                label: item?.name,
            };
        });
        return result;
    }
    const fetchCustomersRes = useAsync(
        () => fetchCustomersFunc(),
        "customers"
    );
    const getCustomer = (id) => {
        for (let i in fetchCustomersRes?.data) {
            if (fetchCustomersRes?.data[i]?.value === id) return fetchCustomersRes?.data[i];
        }
    }

    
    // Reload data
    const reloadData = async () => {
        const response = await InvoiceService.get(invoiceId);
        //console.log("Data: ", response);
        setData(response[0]);
        setNewData(response[0]);
    }

    // Upload data
    const uploadData = async () => {
        // Delete old lines
        await InvoiceService.deleteLine(data?.invoice_line_ids);//data.line_ids);
        // Update new invoice detail
        let response = await InvoiceService.update(invoiceId, newData);//data.line_ids);
        //console.log("update result: ", response);
        const result = response;
        for (let i in tempLinesData) {
            response = await InvoiceService.createLine(result[0], tempLinesData[i]);//data.line_ids);
            //console.log("Create result: ", response);
        }
        // Show confirm
        //console.log("Result: ", result.length, result[0]);
        if (result.length == 1 && result[0] == invoiceId) {
            SuccessConfirm(
                MySwal,
                t("invoice_update_success"),
                t("back")
            );
        } else {
            FailConfirm(
                MySwal,
                t("invoice_update_fail"),
                t("back")
            );
        }

        // Reload data
        reloadData();
        //console.log(result);
    }

    // Save Temporary Function
    const saveTempFunc = async (newData) => {
        setTempLinesData((prevItems) => Array.isArray(prevItems) ? [...prevItems, newData] : [newData]);
    }

    // Update Temporary Function
    const updateTempFunc = async (index, newData) => {
        setTempLinesData((prevItems) => {
            // Ensure prevItems is an array
            if (!Array.isArray(prevItems)) {
                return [newData];  // If prevItems isn't an array, initialize it with newData
            }

            // Create a new array with the updated item
            const updatedItems = [...prevItems];  // Make a shallow copy of the array
            updatedItems[index] = newData;        // Replace the item at the specified index with newData

            return updatedItems;  // Return the updated array
        });
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">
                    <div className="d-flex justify-content-between">
                        <div className="flex-fill">
                            <Breadcrumbs
                                maintitle={t("invoice_edit")}
                                subtitle={t("invoice_edit_subtitle")}
                                backButton={t('invoice_list_back')}
                                backLink={`${route.invoicereport}`}
                                show="reload back"
                                refreshFunc={fetchData.refetch}
                            />
                        </div>
                        
                        
                        <div className="px-2">
                            
                            {/*----- Invoice State -----*/}
                            <ToggleButtonGroup
                                value={newData?.state}
                                exclusive
                                onChange={(event, newState) => {
                                    setNewData(
                                        prevState => ({
                                            ...prevState,
                                            state: newState
                                        })
                                    );
                                }}
                                aria-label="Platform"
                            >
                                <ToggleButton value="draft" color="primary" disabled={disable}>{t("draft")}</ToggleButton>
                                <ToggleButton value="posted" color="success" disabled={disable}>{t("posted")}</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                    {/*----- Invoice Detail + Lines -----*/}
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            {/*----- Invoice detail - edit -----*/}
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        {/*----- Invoice -----*/}
                                        <div className="bar-code-view col-lg-6 col-sm-12">
                                            <div className="column">
                                                <div className="page-title">
                                                    <h6>
                                                        {t("invoice_no")}:
                                                        
                                                            {(data?.name === "/") &&
                                                                (<span className="badge badge-warning"> {t("draft")}</span>
                                                                )}
                                                        {(data?.name != "/") && <span> {data?.name}</span>}

                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        {/*----- Total price -----*/}
                                        <div className="bar-code-view col-lg-6 col-sm-12">
                                            <div className="column">
                                                <div className="page-title"><h6>{t("tax_excluded")}: {newData?.amount_untaxed_signed}VND</h6></div>
                                                <div className="page-title"><h6>{t("total")}: {newData?.amount_total_signed}VND</h6></div>
                                                <div className="page-title"><h6>{t("invoice_amount_due")}: {newData?.amount_residual_signed}VND</h6></div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*----- Product Detail-----*/}
                                    <div className="row accordion-body">
                                        {/*----- Customer----- */}
                                        <div className="col-lg-12">
                                            <div className="input-blocks mb-3">
                                                <label>{t("customers")}</label>
                                                
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={fetchCustomersRes?.data}
                                                    value={(newData?.partner_id) ? getCustomer(newData?.partner_id[0]) : null}
                                                    placeholder={t("choose_customer")}
                                                    onChange={(newCustomer) => {
                                                        console.log("New Customer: ", newCustomer);
                                                        setNewData(
                                                            prevState => ({
                                                                ...prevState,
                                                                partner_id: [newCustomer?.value, newCustomer?.label]
                                                            })
                                                        );
                                                    }}
                                                    isDisabled={disable}

                                                />
   
                                            </div>
                                        </div>
                                        {/* ----- Invoice date ----- */}
                                        <div className="col-lg-12">
                                            <div className="input-blocks mb-3">
                                                <label>{t("create_date")}</label>
                                                <div className="input-groupicon calender-input">
                                                    
                                                    <DatePicker
                                                        value={newData?.invoice_date ? dayjs(newData?.invoice_date, dateFormat) : null}
                                                        disabled={disable}
                                                        placeholder={t("choose_date")}
                                                        onChange={(event, newDate) => {
                                                            setNewData(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    invoice_date: newDate
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>
                                              
                                            </div>
                                        </div>
                                        {/* ----- Payment Reference ----- */}
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="input-blocks search-form mb-3">
                                                <label>{t("payment_reference")}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"

                                                    placeholder={t("choose")}
                                                    value={newData?.payment_reference ? newData?.payment_reference : null}
                                                    onChange={(newData) => {
                                                        //console.log("New Payment Reference", newData.target.value);
                                                        setNewData(
                                                            prevState => ({
                                                                ...prevState,
                                                                payment_reference: newData?.target?.value
                                                            })
                                                        );
                                                    }}
                                                    disabled={disable}
                                                />

                                            </div>
                                        </div>
                                        {/* ----- Invoice Payment Term ----- 
                                        <div className="col-lg-6 col-sm-12">
                                            <div className="input-blocks mb-3">
                                                <label>{t("invoice_payment_term")}</label>
  
                                                <Select
                                                    classNamePrefix="react-select"
                                                    options={paymentData}
                                                    value={newData.invoice_payment_term_id ? getPayment(newData.invoice_payment_term_id[0]) : null}
                                                    placeholder={t("choose")}
                                                    onChange={(newData) => {
                                                        
                                                        setNewData(
                                                            prevState => ({
                                                                ...prevState,
                                                                invoice_payment_term_id: [newData.value, newData.label]
                                                            })
                                                        );
                                                    }}
                                                    isDisabled={disable}
              
                                                />

                                            </div>
                                        </div>
                                        */}
                                        {/* ----- Delivery Date ----- */}
                                        <div className="col-lg-12">
                                            <div className="input-blocks mb-3">
                                                <label>{t("delivery_date")}</label>
                                                <div className="input-groupicon calender-input">
                                                    
                                                    <DatePicker
                                                        value={newData?.delivery_date ? dayjs(newData?.delivery_date, dateFormat) : null}
                                                        disabled={disable}
                                                        placeholder={t("choose_date")}
                                                        onChange={(event, newDate) => {
                                                            setNewData(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    delivery_date: newDate
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        {/* ----- Due Date ----- */}
                                        <div className="col-lg-12">
                                            <div className="input-blocks mb-3">
                                                <label>{t("invoice_due_date")}</label>
                                                <div className="input-groupicon calender-input">
                                                    
                                                    <DatePicker
                                                        value={newData?.invoice_date_due ? dayjs(newData?.invoice_date_due, dateFormat) : null}
                                                        disabled={disable}
                                                        dateFormat={dateFormat}
                                                        placeholder={t("choose_date")}
                                                        onChange={(event, newDate) => {
                                                            setNewData(
                                                                prevState => ({
                                                                    ...prevState,
                                                                    invoice_date_due: newDate
                                                                })
                                                            );
                                                        }}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card col-lg-6 col-sm-12">
                            {/*----- Invoice lines-----*/}
                            <div className="table-responsive">
                                {!fetchData?.loading ? (
                                    <ScrollTable columns={columns} dataSource={tempLinesData} />
                                ) : (
                                    <Loading />
                                )}
                                
                            </div>
                            {/*----- Add Button -----*/}
                            {!disable && (
                                <Button
                                    link="#"
                                    type="add"
                                    text={t('add_product')}
                                    data_bs_toggle="modal"
                                    data_bs_target="#invoice-line-add"
                                />
                            )}
                        </div>
                    </div>
                    {/*----- Save and Cancel Btn -----*/}

                    <div className="col-lg-12">
                        <div className="btn-addproduct mb-4">
                            <button
                                type="button"
                                className="btn btn-cancel me-2"
                                onClick={
                                    () => setNewData(data)}
                                disabled={disable}
                            >
                                {t("cancel")}
                            </button>
                            {/*
                             * Disable Save btn if Invoice state is "posted"
                             * If new state is set from "Draft" to "Posted" then give a warning
                             * */}
                            <button
                                type="submit"
                                className="btn btn-submit"
                                onClick={() => {
                                    if (newData.state === "posted") {
                                        Alert(
                                            MySwal,
                                            t("question"),
                                            t("invoice_alert_save_as_posted"),
                                            t("yes"),
                                            t("no"),
                                            uploadData,
                                        );
                                        //showConfirmationAlert();
                                    } else uploadData()
                                }}
                                disabled={disable}
                            >
                                {t("save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*----- Invoice Line Edit Modal-----*/}
            <InvoiceLineEditModal lineData={currLineData} reloadFunc={null/*reloadData*/} updateFunc={updateTempFunc} />
            { /*----- Invoice Line Add Modal-----*/}
            <InvoiceLineAddModal saveTempFunc={saveTempFunc} />
            { /*----- Invoice Line Detail Modal-----*/}
            <InvoiceLineModal lineId={currLine} />
        </div>
    )
}

export default InvoiceEdit