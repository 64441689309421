export const leavesadmindata = [
  {
    id: 1,
    empname: "Mitchum Daniel\n    Database Administrator",
    empid: "POS001",
    type: "Sick Leave",
    fromdate: "02 Aug 2023",
    todate: "03 Aug 2023",
    "days/hours": "01 Day",
    shift: "Regular",
    appliedon: "02 Aug 2023",
    status: "Approved",
  },
  {
    id: 2,
    empname: "Susan Lopez\n    Curator",
    empid: "POS002",
    type: "Sick Leave",
    fromdate: "07 Aug 2023",
    todate: "07 Aug 2023",
    "days/hours": "2 hrs",
    shift: "Regular",
    appliedon: "07 Aug 2023",
    status: "Rejected",
  },
  {
    id: 3,
    empname: "Robert Grossman\n    System Administrator",
    empid: "POS003",
    type: "Sick Leave",
    fromdate: "03 Aug 2023",
    todate: "04 Aug 2023",
    "days/hours": "01 Day",
    shift: "Regular",
    appliedon: "03 Aug 2023",
    status: "Approve\n    Rejected",
  },
  {
    id: 4,
    empname: "Janet Hembre\n    Administrative Officer",
    empid: "POS004",
    type: "Maternity",
    fromdate: "05 Aug 2023",
    todate: "07 Aug 2023",
    "days/hours": "02 Days",
    shift: "Regular",
    appliedon: "05 Aug 2023",
    status: "Approve\n    Rejected",
  },
  {
    id: 5,
    empname: "Russell Belle\n    Technician",
    empid: "POS005",
    type: "Vacation",
    fromdate: "08 Aug 2023",
    todate: "10 Aug 2023",
    "days/hours": "03 Days",
    shift: "Regular",
    appliedon: "08 Aug 2023",
    status: "Approve\n    Rejected",
  },
  {
    id: 6,
    empname: "Edward K. Muniz\n    Office Support Secretary",
    empid: "POS006",
    type: "Sick Leave",
    fromdate: "03 Aug 2023",
    todate: "03 Aug 2023",
    "days/hours": "2 hrs",
    shift: "Regular",
    appliedon: "03 Aug 2023",
    status: "Approve\n    Rejected",
  },
];
