/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import UploadButton from "./UploadButton";
import { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import ExcelService from "../../../core/services/ExcelService";
import { useTableContext } from "../listView/TableContext";
import faqIcon from "../../../core/img/faq.svg";
import { Tooltip } from "@mui/material";
import { useMetaContext } from "../../../Router/MetaContext";
import Loading from "../../../InitialPage/ui/Loading";

const UploadFiles = ({ data, resModal }) => {
   const [parsePreview, setParsePreview] = useState(data);
   const {
      loadingImport,
      setLoadingImport,
      selectedFile,
      setErrorData,
      errorData,
   } = useMetaContext();
   const [emptyFields, setEmptyFields] = useState([]);
   const [skipRecord, setSkipRecord] = useState([]);
   const [newValue, setNewValue] = useState({});
   const { idFile } = useTableContext();
   const [dataMatch, setDataMatch] = useState([]);
   const [option, setOption] = useState();
   const errorOptions = [
      {
         id: 1,
         title: "Prevent Import",
      },
      {
         id: 2,
         title: "Set value as empty",
         import_set_empty_fields: [],
      },
      {
         id: 3,
         title: "Skip record",
         import_skip_records: [],
      },
      {
         id: 4,
         title: "Create new values",
         name_create_enabled_fields: {},
      },
   ];
   function mergeAndFilterArrays(arrayOfObjects, objectWithArrays) {
      const result = [];
      const objectEntries = Object.entries(objectWithArrays);

      objectEntries.forEach(([key, valueArray]) => {
         const matchingObject = arrayOfObjects.find((item) =>
            valueArray.includes(item.name)
         );
         if (matchingObject) {
            result.push({
               ...matchingObject,
               [key]: valueArray,
            });
         } else {
            result.push({
               name: valueArray[0], // Sử dụng phần tử đầu tiên của mảng làm tên
               [key]: valueArray,
            });
         }
      });

      return result;
   }
   const updateResultItem = useCallback((index, data) => {
      setDataMatch((prevResult) => {
         const newResult = [...prevResult];

         newResult[index] = {
            ...newResult[index],
            string: data.label,
            id: data.value,
            type: data.type,
         };
         setDataMatch(newResult);
         return newResult;
      });
   }, []);
   const handleChangeField = useCallback(
      (id, data) => {
         updateResultItem(id, data);
      },
      [updateResultItem]
   );
   const handleChangeOption = (id, data) => {
      setErrorData((prevItems) =>
         prevItems.map((item) =>
            item.field === id
               ? { ...item, selectedOption: data.label, id: data.value }
               : item
         )
      );
      if (data.value == 2) {
         setEmptyFields((prevFields) => {
            if (!prevFields.includes(id)) {
               return [...prevFields, id];
            }
            return prevFields;
         });
         setSkipRecord((prevFields) =>
            prevFields.filter((fieldId) => fieldId !== id)
         );
         setNewValue((prevFields) => {
            if (data.value != 4) {
               const { [id]: removedField, ...newFields } = prevFields;
               return newFields;
            }
         });
      } else if (data.value == 3) {
         setSkipRecord((prevFields) => {
            if (!prevFields.includes(id)) {
               return [...prevFields, id];
            }
            return prevFields;
         });
         setEmptyFields((prevFields) =>
            prevFields.filter((fieldId) => fieldId !== id)
         );
         setNewValue((prevFields) => {
            if (data.value != 4) {
               const { [id]: removedField, ...newFields } = prevFields;
               return newFields;
            }
         });
      } else if (data.value == 4) {
         setEmptyFields((prevFields) =>
            prevFields.filter((fieldId) => fieldId !== id)
         );
         setSkipRecord((prevFields) =>
            prevFields.filter((fieldId) => fieldId !== id)
         );
         setNewValue((prevFields) => {
            if (data.value == 4) {
               return { ...prevFields, [id]: true };
            } else {
               // eslint-disable-next-line no-unused-vars
               const { [id]: removedField, ...newFields } = prevFields;
               return newFields;
            }
         });
      } else {
         setEmptyFields((prevFields) =>
            prevFields.filter((fieldId) => fieldId !== id)
         );
         setSkipRecord((prevFields) =>
            prevFields.filter((fieldId) => fieldId !== id)
         );
         setNewValue((prevFields) => {
            if (data.value != 4) {
               const { [id]: removedField, ...newFields } = prevFields;
               return newFields;
            }
         });
      }
   };

   function extractStringValues(array, requiredLength) {
      const processedItems = array.map((item) =>
         item.string != "" && item.id ? item.id.toLowerCase() : false
      );
      const remainingItems = Array(
         Math.max(0, requiredLength - processedItems.length)
      ).fill(false);
      // Kết hợp các phần tử đã xử lý và các phần tử bổ sung
      return [...processedItems, ...remainingItems];
   }
   const handleImport = async (dryrun) => {
      setErrorData();
      if (dataMatch && data?.headers) {
         const columns = data?.headers;
         const options = data?.options;
         const fields = extractStringValues(dataMatch, columns?.length);

         await ExcelService.ExecuteImport({
            fields,
            columns,
            idFile,
            options,
            dryrun,
         })

            .then((responsive) => {
               if (!responsive?.ids) {
                  const newData = responsive?.messages?.map((item) => ({
                     ...item,
                     selectedOption: "",
                     id: "",
                  }));
                  setErrorData(newData);
               } else {
                  location.reload();
               }
            })
            .catch(() => {});
      }
   };
   useEffect(() => {
      if (data.matches && data.fields) {
         const result = mergeAndFilterArrays(data.fields, data.matches);
         setDataMatch(result);
      }
      if (data?.options) {
         setOption(data?.options);
      }
      if (data) {
         setParsePreview(data);
         setLoadingImport(false);
      }
   }, [data.matches, data.fields, data?.options]);
   return (
      <div className="flex gap-[24px]">
         <div className="flex flex-col items-center px-[32px] pl-0 w-full max-w-[400px] gap-[16px] border-r-[1px] border-solid border-[#dee2e6]">
            <div className="flex justify-between items-center w-full flex-wrap gap-[12px]">
               <button
                  onClick={() => handleImport(false)}
                  className="px-[16px] py-[8px] bg-[#06aeff] text-[#fff] rounded-[4px]"
               >
                  Import
               </button>
               <button
                  onClick={() => handleImport(true)}
                  className="px-[16px] py-[8px] bg-[#dee2e6] border-[1px] border-solid border-[#dee2e6] rounded-[4px] font-semibold text-[#333]"
               >
                  Test
               </button>
               <div className="border-[1px] border-solid border-[#eae8e8] rounded-[8px]">
                  <UploadButton resModal={resModal} />
               </div>
               <button className="px-[16px] py-[8px] bg-[#dee2e6] border-[1px] border-solid border-[#dee2e6] rounded-[4px] font-semibold text-[#333]">
                  Cancel
               </button>
            </div>
            <div className="flex flex-col gap-[16px] w-full">
               <div className="flex flex-col gap-[2px]">
                  <div className="font-bold text-[18px]">Imported file</div>
                  <div className="h-[32px] flex items-center font-medium">
                     {selectedFile && selectedFile.name}
                  </div>
               </div>
               <div className="flex flex-col gap-[6px]">
                  <div className="h-[27px] flex items-center font-semibold">
                     Sheet
                  </div>
                  <div className="rounded-[8px] w-[60%] border-[#c4c4c4] border-solid border-[1px] py-[4px] px-[16px]">
                     Contact
                  </div>
               </div>
               <div className="flex gap-[6px]">
                  <div className="">
                     <input type="checkbox" width={8} height={8} />
                  </div>
                  <div className="text-[14px] leading-[20px] font-normal">
                     Use first row as header
                  </div>
               </div>
               <div className="border-t-[1px] border-solid border-[#dee2e6] py-[16px] flex flex-col gap-[8px]">
                  <div className="font-bold text-[16px] leading-[24px]">
                     Help
                  </div>
                  <div className="flex items-center gap-[6px]">
                     <div> Import Template for Products</div>
                  </div>
                  <div className="flex items-center gap-[6px]">
                     <div>Go to Import FAQ </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="flex flex-col w-full gap-[24px]">
            {errorData?.length > 0 && (
               <div className="w-full p-[8px] bg-[#f8d7da] text-[#842029] font-bold rounded-[4px] flex flex-col gap-[8px]">
                  The file contains blocking errors (see below)
                  <div>{errorData[0]?.message}</div>
               </div>
            )}
            <div className="flex items-center border-[1px] border-solid border-[#dee2e6] w-full">
               <div className="w-[324px] p-[12px] font-semibold">
                  File Column
               </div>
               <div className="w-[324px] p-[12px] font-semibold">
                  Vitrust Field
               </div>
               <div className="w-[280px] p-[12px] font-semibold">Comments</div>
            </div>
            {loadingImport ? (
               <Loading />
            ) : (
               <div className="flex flex-col gap-[8px]">
                  {parsePreview?.headers?.map((item, index) => {
                     return (
                        <div
                           key={item.id}
                           className=" w-full border-b-[1px] border-solid border-[#dee2e6] flex flex-col gap-[16px]"
                        >
                           <div className="items-center flex">
                              <div className="w-[324px] flex flex-col gap-[8px] p-[12px] pr-[24px]">
                                 <div
                                    key={index}
                                    className="w-full line-clamp-1 font-semibold text-[16px] break-words overflow-hidden"
                                 >
                                    {item == "" ? "Unitled" : item}
                                 </div>
                                 <div className="line-clamp-1">
                                    {parsePreview?.preview[index]?.[0]}
                                 </div>
                              </div>
                              <div className="w-[324px] relative flex items-center gap-[8px] ">
                                 <Select
                                    classNamePrefix="react-select !w-full"
                                    options={data?.fields?.map((item) => {
                                       return {
                                          label: item.string,
                                          value: item.id,
                                          type: item.type,
                                       };
                                    })}
                                    value={{
                                       value: data?.matches?.[index]?.[0],
                                       label: dataMatch[index]?.string,
                                       type: dataMatch[index]?.type,
                                    }}
                                    placeholder={"To import, select a field..."}
                                    onChange={(selectedOption) =>
                                       handleChangeField(index, selectedOption)
                                    }
                                 />
                                 <button
                                    onClick={() =>
                                       handleChangeField(index, {
                                          label: "",
                                          value: "",
                                          type: "",
                                       })
                                    }
                                    className="hover:text-red-500 absolute right-[75px] font-bold leading-8 uppercase"
                                 >
                                    x
                                 </button>
                                 <Tooltip
                                    title={`Type: ${dataMatch[index]?.type}`}
                                 >
                                    <img
                                       src={faqIcon}
                                       alt="faq icon"
                                       width={24}
                                       height={24}
                                    />
                                 </Tooltip>
                              </div>
                              <div className="w-[324px] pl-[16px]">
                                 {errorData &&
                                    errorData
                                       .filter(
                                          (field) =>
                                             field?.field ==
                                             dataMatch[index]?.id
                                       )
                                       .slice(0, 1)
                                       .map((subField, subIndex) => {
                                          return (
                                             <div
                                                key={subIndex}
                                                className="flex flex-col gap-[8px]"
                                             >
                                                <div className="w-full p-[8px] bg-[#f8d7da] text-[#842029] font-bold rounded-[4px]">
                                                   No matching records found for
                                                   the following name in field{" "}
                                                   {subField?.field_name}
                                                </div>
                                                {data?.matches?.[
                                                   index
                                                ]?.[0] && (
                                                   <div className="w-full p-[8px] bg-[#f8d7da] text-[#842029] font-bold rounded-[4px]">
                                                      When a value cannot be
                                                      matched:
                                                      <Select
                                                         classNamePrefix="react-select !w-full"
                                                         options={errorOptions.map(
                                                            (item) => {
                                                               return {
                                                                  label: item.title,
                                                                  value: item.id,
                                                               };
                                                            }
                                                         )}
                                                         value={{
                                                            value:
                                                               subField?.id !=
                                                               ""
                                                                  ? subField.id
                                                                  : errorOptions[0]
                                                                       .id,
                                                            label:
                                                               subField?.selectedOption !=
                                                               ""
                                                                  ? subField.selectedOption
                                                                  : errorOptions[0]
                                                                       .title,
                                                         }}
                                                         placeholder={
                                                            "To import, select a field..."
                                                         }
                                                         onChange={(
                                                            selectedOption
                                                         ) =>
                                                            handleChangeOption(
                                                               data?.matches?.[
                                                                  index
                                                               ]?.[0],
                                                               selectedOption
                                                            )
                                                         }
                                                      />
                                                   </div>
                                                )}
                                             </div>
                                          );
                                       })}
                              </div>
                           </div>
                        </div>
                     );
                  })}
               </div>
            )}
         </div>
      </div>
   );
};
export default UploadFiles;
