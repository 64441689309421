import { CookieStorage } from "../utils/Cookies";

const useLogoutSubmit = (navigate) => {

  const onLogout = async () => {
    CookieStorage.clearToken();
    navigate("/signin");
  };
  return { onLogout };
};

export default useLogoutSubmit;
