export const departmentlistdata = [
  {
    id: 1,
    department: "UI/UX",
    img: "assets/img/users/user-01.jpg",
    hod: "Steven",
    members: "+3",
    totalmembers: "07",
    creadedon: "25 Jan 2023",
    status: "Active",
    users: [
      "assets/img/users/user-03.jpg",
      "assets/img/users/user-04.jpg",
      "assets/img/users/user-06.jpg",
      "assets/img/users/user-05.jpg",
    ],
    additionalCount: 3,
  },
  {
    id: 2,
    department: "HR",
    img: "assets/img/users/user-02.jpg",
    hod: "Susan",
    members: "+3",
    totalmembers: "05",
    creadedon: "29 Jan 2023",
    status: "Active",
  },
  {
    id: 3,
    department: "Admin",
    img: "assets/img/users/user-03.jpg",
    hod: "Robert",
    members: "+3",
    totalmembers: "06",
    creadedon: "13 Feb 2023",
    status: "Inactive",
  },
  {
    id: 4,
    department: "Technician",
    img: "assets/img/users/user-04.jpg",
    hod: "Janet",
    members: "+3",
    totalmembers: "08",
    creadedon: "27 Feb 2023",
    status: "Active",
  },
  {
    id: 5,
    department: "Support",
    img: "assets/img/users/user-05.jpg",
    hod: "Russell Belle",
    members: "+3",
    totalmembers: "04",
    creadedon: "02 Mar 2023",
    status: "Active",
  },
  {
    id: 6,
    department: "Engineering",
    img: "assets/img/users/user-06.jpg",
    hod: "Edward",
    members: "+3",
    totalmembers: "10",
    creadedon: "17 Mar 2023",
    status: "Inactive",
  },
  {
    id: 7,
    department: "Admin",
    img: "assets/img/users/user-07.jpg",
    hod: "Susan",
    members: "+3",
    totalmembers: "03",
    creadedon: "20 Mar 2023",
    status: "Active",
  },
];
