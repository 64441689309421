import UserService from "../services/UserService";
import { useForm } from "react-hook-form";
import { useEffect, useState, useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";

import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";
const useUserSubmit = () => {
   const { setIsLoading } = useContext(SidebarContext);
   const [colorPalette, setColorPalette] = useState("#ff505b");

   const [profileUpdateTrigger, setProfileUpdateTrigger] = useState(0);
   const [imageSrc, setImageSrc] = useState();
   const [imageUpload, setImageUpload] = useState(false);

   const { register, setValue, getValues, handleSubmit } = useForm();

   const handleGetColor = (color) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";

      img.onload = () => {
         const canvas = document.createElement("canvas");
         const ctx = canvas.getContext("2d");

         canvas.width = img.naturalWidth;
         canvas.height = img.naturalHeight;

         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

         try {
            const imageData = ctx.getImageData(
               0,
               0,
               canvas.width,
               canvas.height
            ).data;

            let r = 0,
               g = 0,
               b = 0;
            const pixelCount = imageData.length / 4;

            for (let i = 0; i < imageData.length; i += 4) {
               r += imageData[i];
               g += imageData[i + 1];
               b += imageData[i + 2];
            }

            r = Math.floor(r / pixelCount);
            g = Math.floor(g / pixelCount);
            b = Math.floor(b / pixelCount);

            const dominantColor = `rgb(${[r, g, b].join(",")})`;
            setColorPalette(dominantColor);
         } catch (e) {
            console.error("Failed to get color:", e);
            setColorPalette("#ff505b");
         }
      };
      img.src = color;
   };
   const onUpdateProfile = (data) => {
      setIsLoading(true);
      const userData = {
         model: "res.users",
         domain: [["login", "=", `${data?.username}`]],
         values: {
            name: data?.name,
            email: data?.email,
         },
      };
      UserService.updateProfile(userData)
         .then(() => {
            setIsLoading(false);
            setProfileUpdateTrigger((prev) => prev + 1);
         })
         .catch((err) => {
            console.log("🚀 ~ onUpdateProfile ~ err:", err);
         });
   };

   const onImageUpload = (file) => {
      setIsLoading(true);
      // const filePath = URL.createObjectURL(file)
      const formData = new FormData();
      formData.append("ufile", file);
      const queryParams = {
         model: "res.users",
         id: 2,
         field: "image_256",
      };
      UserService.setImage(formData, queryParams)
         .then(() => {
            setImageUpload(false);
            setIsLoading(false);
            setProfileUpdateTrigger((prev) => prev + 1);
         })
         .catch((err) => {
            console.log("🚀 ~ onImageUpload ~ err:", err);
         });
   };

   useEffect(() => {
      setIsLoading(true);
      UserService.profile()
         .then((res) => {
            if (res) {
               setValue("email", res?.email);
               setValue("username", res?.username);
               setValue("name", res?.name);
               setValue("picture", res?.picture);
               setValue("sub", res?.sub);
               setImageSrc(res?.picture);
               handleGetColor(res?.picture);
            }
         })
         .catch((err) => {
            console.error(err);
         })
         .finally(() => {
            setIsLoading(false);
         });
   }, [profileUpdateTrigger, setValue]);

   const onCreateUser = (data) => {
      console.log(data);
      const userData = {
         model: "res.users",
         domain: [],
         values: {
            name: data?.name,
            phone: data?.phone,
            email: data?.email,
            login: data?.email,
            groups_id: data?.permission,
         },
      };
      UserService.createUser(userData)
         .then((userId) => {
            if (imageUpload) {
               const formData = new FormData();
               formData.append("ufile", imageUpload);
               const queryParams = {
                  model: "res.users",
                  id: userId[0],
                  field: "image_256",
               };
               console.log("🚀 ~ .then ~ queryParams:", queryParams);
               UserService.setImage(formData, queryParams)
                  .then(() => {
                     setImageUpload(false);
                     alertify.set("notifier", "position", "top-right");
                     alertify.success("sucess");
                  })
                  .catch((err) => {
                     console.log("🚀 ~ onImageUpload ~ err:", err);
                  });
            }
         })
         .catch((err) => {
            console.log("🚀 ~ onImageUpload ~ err:", err);
         });
   };

   const onUpdateUser = (data) => {
      console.log(data);

      const userData = {
         model: "res.users",
         domain: [["id", "=", parseInt(data?.edit_id)]],
         values: {
            name: data?.edit_name,
            phone: data?.edit_phone,
            email: data?.edit_email,
         },
      };
      UserService.updateUser(userData)
         .then((userId) => {
            if (data?.edit_permission && data?.remove_role) {
               data.edit_permission.forEach((permissionId) => {
                  UserService.removeRoleUser(permissionId, data?.edit_id);
               });
            }
            if (data?.edit_permission && data?.add_role) {
               data.edit_permission.forEach((permissionId) => {
                  UserService.addRoleUser(permissionId, data?.edit_id);
               });
            }
            console.log(userId);
         })
         .catch((err) => {
            console.error(err);
         });
   };

   return {
      onUpdateProfile,
      onImageUpload,
      register,
      handleSubmit,
      setValue,
      getValues,
      imageSrc,
      setImageSrc,
      imageUpload,
      setImageUpload,
      colorPalette,
      onCreateUser,
      onUpdateUser,
   };
};

export default useUserSubmit;
