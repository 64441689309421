import React from "react";
import PropTypes from "prop-types";

const InvoiceDataPDF = ({list, total}) => {

  return (
    <>
      <table width="100%" className="mb-10 mt-4">
        <thead>
          <tr className="">
            <td className="font-bold">Name</td>
            <td className="font-bold text-end">Quantity</td>
            <td className="font-bold text-end">Price</td>
            <td className="font-bold text-end">Amount</td>
          </tr>
        </thead>
        {list.map(({ id, name, quantity, price }) => (
          <React.Fragment key={id}>
            <tbody>
              <tr className="h-10">
                <td>{name}</td>
                <td className="text-end">{quantity.toLocaleString()}</td>
                <td className="text-end">{price.toLocaleString()}</td>
                <td className="text-end">{(price * quantity).toLocaleString()}</td>
              </tr>
            </tbody>
          </React.Fragment>
        ))}
      </table>
          {/* flex text-end items-end justify-end text-gray-800 text-4xl font-bold */}
      <div>
        <h4 className="text-end">
          TOTAL: {total.toLocaleString()}
        </h4>
      </div>
    </>
  );
}

InvoiceDataPDF.propTypes = {
    list: PropTypes.array,
    total: PropTypes.number,
  };
export default InvoiceDataPDF;