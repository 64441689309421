import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import ProductService from "../../services/ProductService";
import useProductSubmit from "../../hooks/useProductSubmit";
const EditAttributeExtra = ({ data, loading }) => {
  const { t } = useTranslation();
  const [selectData, setSelectData] = useState();
  const [selectOptions, setSelectOptions] = useState();
  useEffect(() => {
    ProductService.getExtraCost(data?.valueAttribute).then((res) => {
      setSelectOptions(
        res?.records?.map((item) => {
          return {
            label: item.name,
            value: item.id,
            extra_price: item.price_extra,
          };
        })
      );
    });
  }, [data]);
  const handleSelectChange = (selected) => {
    console.log("🚀 ~ handleSelectChange ~ selected:", selected);
    setSelectData(selected);
  };
  const { onUpdateExtraCost } = useProductSubmit();
  const formatNumber = (number) => {
    return new Intl.NumberFormat("de-DE").format(number);
  };
  return (
    <>
      {/* Add Category */}
      <div className="modal fade" id="edit-attribute-extra">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("extra_attribute_config")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setSelectData(null)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      {t("choose_attribute_value")}
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      options={selectOptions}
                      value={
                        selectData
                          ? {
                              label: selectData?.label,
                              value: selectData?.value,
                            }
                          : null
                      }
                      placeholder={t("choose")}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label className="form-label">
                        {t("edit_extra_cost")}
                      </label>
                      <label className="form-label">
                        {t("edit_sold_cost")}
                        {formatNumber(
                          data?.listPrice +
                            (parseInt(selectData?.extra_price) || 0)
                        )}{" "}
                        VNĐ
                      </label>
                    </div>

                    <input
                      type="text"
                      className="form-control"
                      value={selectData ? selectData?.extra_price : ""}
                      onChange={(e) =>
                        setSelectData({
                          ...selectData,
                          extra_price: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      onClick={() => setSelectData(null)}
                    >
                      {t("cancel")}
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-submit"
                      onClick={(e) => {
                        onUpdateExtraCost(
                          selectData?.value,
                          selectData?.extra_price
                        ),
                          e.preventDefault(),
                          setSelectData(null);
                      }}
                      data-bs-dismiss="modal"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </>
  );
};
EditAttributeExtra.defaultProps = {
  data: [],
  loading: false,
};
EditAttributeExtra.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
export default EditAttributeExtra;
