/* eslint-disable react/prop-types */
import React from "react";
import "react-datepicker/dist/react-datepicker.css";

const NumberField = ({ name, register, savedDataDetail }) => {
   return (
      <input
         {...register(name)}
         type="number"
         defaultValue={savedDataDetail?.[name]}
         className="bg-[#fff] border-[1px] border-solid border-[#E4E4E7] p-2 rounded-lg cursor-pointer"
      />
   );
};
export default NumberField;
