/* eslint-disable react/prop-types */
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import GeneralService from "../../../core/services/GeneralService";
import useAsync from "../../../core/hooks/useAsync";

const Many2OneButton = ({ relation, name, control }) => {
   const { data: dataOfSelection } = useAsync(
      () => GeneralService.getSelectionItem({ model: relation }),
      `data_${relation}`
   );

   const options =
      dataOfSelection?.records?.map((val) => ({
         value: val.id,
         label: val.name,
      })) || [];

   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <div className="p-2 bg-gray-100 rounded-lg">
               <div className="button-list flex overflow-x-auto">
                  {options.map((option) => (
                     <button
                        key={option.value}
                        className={`flex items-center justify-center bg-white min-w-max !text-gray-800 px-4 py-2 rounded-lg m-1 cursor-pointer transition-colors duration-300 ease-in-out hover:bg-blue-100 ${
                           field.value === option.value
                              ? "bg-blue-700 text-white"
                              : ""
                        }`}
                        onClick={() => {
                           field.onChange(option.value);
                        }}
                     >
                        {option.label}
                        {/* <input
                    type="radio"
                    name={name}
                    value={option.value}
                    checked={field.value === option.value}
                    onChange={() => field.onChange(option.value)}
                    className="form-radio text-blue-600"
                  /> */}
                     </button>
                  ))}
               </div>
            </div>
         )}
      />
   );
};

export default Many2OneButton;
