import PropTypes from "prop-types";

const ClientPDF = ({ name, invoiceNo, address }) => {
   return (
      <section className="m-10">
         <div>No: {invoiceNo}</div>
         <div>Name: {name}</div>
         <div>Address: {address}</div>
      </section>
   );
};

ClientPDF.propTypes = {
   name: PropTypes.string.isRequired,
   invoiceNo: PropTypes.string,
   address: PropTypes.string,
};

export default ClientPDF;
