import React, { useEffect, useState } from "react";

import { Route, Routes, useNavigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import ThemeSettings from "../InitialPage/themeSettings";
import { pagesRoute, posRoutes, publicRoutes } from "./router.link";
import { CookieStorage } from "../core/utils/Cookies";
import HomePage from "../feature-module/pages/listView/page";
const HeaderLayout = () => {
   const data = useSelector((state) => state.toggle_header);
   const [hasToken, setHasToken] = useState(false);
   const navigate = useNavigate();
   const menu_focus = useSelector((state) => state.menu_focus);

   useEffect(() => {
      Cookies.set("i18next", "vi");

      const hasAccessToken = () => {
         const token = CookieStorage.getAccessToken();
         return !!token;
      };

      if (hasAccessToken()) {
         setHasToken(true);
      } else {
         navigate("/signin");
         setHasToken(false);
      }
   }, []);

   return (
      <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
         {/* Only Render Header if Browser has Access Token */}

         {hasToken && <Header />}
         <Sidebar />
         <Outlet key={menu_focus} />
         <ThemeSettings />
      </div>
   );
};
const AllRoutes = () => {
   const data = useSelector((state) => state.toggle_header);

   const Authpages = () => (
      <div className={data ? "header-collapse" : ""}>
         <Outlet />
         {/* <Loader /> */}
         <ThemeSettings />
      </div>
   );

   const Pospages = () => (
      <div>
         <Header />
         <Outlet />
         {/* <Loader /> */}
         <ThemeSettings />
      </div>
   );

   return (
      <div>
         <Routes>
            <Route path="/pos" element={<Pospages />}>
               {posRoutes.map((route, id) => (
                  <Route path={route.path} element={route.element} key={id} />
               ))}
            </Route>
            <Route path={"/"} element={<HeaderLayout />}>
               {publicRoutes.map((route, id) => (
                  <Route path={route.path} element={<HomePage />} key={id} />
               ))}
            </Route>

            <Route path={"/"} element={<Authpages />}>
               {pagesRoute.map((route, id) => (
                  <Route path={route.path} element={route.element} key={id} />
               ))}
            </Route>
         </Routes>
      </div>
   );
};
export default AllRoutes;
