/* eslint-disable react/prop-types */
import React, { useEffect, useId, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import AddIcon from "../../../core/img/add-icon.svg";
import CloseIcon from "../../../core/img/close-icon2.svg";

const BinaryField = ({ name, control, readonly, savedDataDetail }) => {
   const inputId = useId();
   const [selectedImage, setSelectedImage] = useState(null);
   const [initialImage, setInitialImage] = useState(
      savedDataDetail?.image_1920 || null
   );

   console.log("savedDataDetail", savedDataDetail?.image_1920);

   const handleImageChange = (e, onChange) => {
      const file = e.target.files[0];
      if (file) {
         const imageUrl = URL.createObjectURL(file);
         setSelectedImage(imageUrl);
         setInitialImage(null); // Clear initial image when a new image is selected
         onChange(file); // Update the form state with the selected file
      }
   };

   const handleRemoveImage = (onChange) => {
      setSelectedImage(null);
      setInitialImage(null); // Clear initial image when removed
      onChange(null); // Update the form state to remove the file
   };

   useEffect(() => {
      return () => {
         if (selectedImage) {
            URL.revokeObjectURL(selectedImage);
         }
      };
   }, [selectedImage]);

   useEffect(() => {
      setSelectedImage(savedDataDetail?.image_1920);
   }, [savedDataDetail]);

   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => {
            const renderImage = initialImage || selectedImage;
            console.log("renderImage", renderImage);
            return (
               <div className="flex w-fit items-center gap-4 rounded-lg shadow-md relative">
                  {renderImage ? (
                     <div className="relative">
                        <img
                           src={renderImage}
                           alt="Selected"
                           className="w-32 h-32 object-cover border-4 border-gray-300 rounded-lg"
                        />
                        <button
                           type="button"
                           onClick={() => handleRemoveImage(field.onChange)}
                           className="absolute top-0 right-0 mt-1 mr-1 bg-red-500 rounded-full p-1 hover:bg-red-600 transition-colors duration-300"
                        >
                           <img
                              src={CloseIcon}
                              alt="close-icon"
                              className="h-4 w-4"
                           />
                        </button>
                     </div>
                  ) : (
                     <label
                        htmlFor={inputId}
                        className="w-32 h-32 flex items-center justify-center border-4 border-dashed border-gray-300 rounded-lg bg-white cursor-pointer hover:bg-gray-200 transition-colors duration-300"
                     >
                        <img
                           src={AddIcon}
                           alt="add-icon"
                           className="h-12 w-12 opacity-50"
                        />
                        <input
                           id={inputId}
                           type="file"
                           readOnly={readonly}
                           onChange={(e) =>
                              handleImageChange(e, field.onChange)
                           }
                           disabled={readonly}
                           className="hidden"
                        />
                     </label>
                  )}
               </div>
            );
         }}
      />
   );
};

export default BinaryField;
