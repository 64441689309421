/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import BadgeSection from "./widget/Badge/BadgeSection";
import HiddenInputFields from "./widget/HiddenInputFields";
import Many2ManyTag from "./widget/Many2ManyTag";
import RenderWidget from "./widget/RenderWidget";
import StatusBarSection from "./widget/Statusbar/StatusBarSection";

import "react-datepicker/dist/react-datepicker.css";

import { Domain } from "../../core/domain/domain";
import { evaluateExpr } from "../../core/domain/py";
import InvoiceService from "../../core/services/InvoiceService";
import { useSearchParams } from "react-router-dom";
import { Loader } from "react-feather";

const DynamicForm = ({
   data,
   register,
   setValue,
   control,
   modelsData,
   savedDataDetail,
   formOnChange,
   hide,
}) => {
   const checkStatusBar = Array.isArray(data)
      ? data.find((item) => item?.widget === "statusbar")
      : undefined;
   const [dataForm, setDataForm] = useState(null);
   const [loading, setLoading] = useState(false);
   const [searchParams] = useSearchParams();
   const idInvoice = searchParams.get("idForm");
   const model = searchParams.get("model");
   const getInvoiceView = async (model, idInvoice) => {
      setLoading(true);
      if (idInvoice && model) {
         await InvoiceService.InvoiceView({
            model,
            idInvoice,
         }).then((responsive) => {
            setDataForm(responsive.value);
         });
      }
   };
   const handleConfirm = async (model, idInvoice) => {
      if (idInvoice && model) {
         const id = Number(idInvoice);
         await InvoiceService.ActionConfirm({ model, id }).then(
            (responsive) => {
               alert("Confirm success");
            }
         );
      }
   };
   const handleSentEmail = async (id) => {
      await InvoiceService.SendEmail({ id }).then((responsive) => {
         console.log("Data result", responsive);
         setLoading(false);
         alert("Sent email success");
      });
   };
   const handleCreateRecord = async (idInvoice, data) => {
      await InvoiceService.CreateRecord({
         data,
         idInvoice,
      }).then((responsive) => {
         handleSentEmail(responsive[0]);
      });
   };
   useEffect(() => {
      if (dataForm && idInvoice) {
         handleCreateRecord(idInvoice, dataForm);
      }
   }, [dataForm]);
   return (
      <div className={`flex flex-col`}>
         <div className="flex items-center justify-between border-b-[1px] border-solid pb-[32px]">
            {model == "account.move" && (
               <div hidden={hide}>
                  {savedDataDetail?.state == "draft" ? (
                     <button
                        type="button"
                        onClick={() => handleConfirm(model, idInvoice)}
                        className="px-[13.6px] py-[8px] rounded-[8px] bg-blue-500 text-[#fff]"
                     >
                        Confirm
                     </button>
                  ) : (
                     <button
                        type="button"
                        onClick={() => getInvoiceView(model, idInvoice)}
                        className="px-[13.6px] relative py-[8px] rounded-[8px] bg-blue-500 text-[#fff] w-[100px] flex justify-center items-center"
                     >
                        {loading ? (
                           <Loader
                              type="ekvalayzer"
                              color="#ED1C24"
                              bgColor="#ED1C24"
                              size={16}
                           />
                        ) : (
                           "Sent Email"
                        )}
                     </button>
                  )}
               </div>
            )}

            <StatusBarSection
               checkStatusBar={checkStatusBar}
               control={control}
               register={register}
               setValue={setValue}
               modelsData={modelsData}
            />
         </div>
         <div
            className={`relative pt-[20px] px-[32px] ${
               checkStatusBar ? "gap-2 " : "mt-[10px] "
            }`}
         >
            <BadgeSection savedDataDetail={savedDataDetail} />

            <HiddenInputFields />
            <div className="grid grid-cols-2 gap-2">
               {Array.isArray(data) &&
                  data
                     ?.filter((val) => val.hide !== true)
                     .map((value, index) => {
                        const {
                           type,
                           string,
                           required,
                           name,
                           readonly,
                           relation,
                           placeholder,
                        } = value.fieldValue;
                        const widget = value.widget;
                        const isPlaceHolder = value.placeholder || placeholder;
                        const isReadOnly = value.readonly || readonly;

                        if (widget === "many2many_tags") {
                           return (
                              <Many2ManyTag
                                 relation={relation}
                                 name={name}
                                 control={control}
                              />
                           );
                        }

                        // readonly logic
                        let check = false;
                        try {
                           if (
                              isReadOnly === undefined ||
                              isReadOnly === 0 ||
                              isReadOnly === false
                           ) {
                              check = false;
                           } else if (
                              isReadOnly === "1" ||
                              isReadOnly === true
                           ) {
                              check = true;
                           }
                           try {
                              if (formOnChange?.value && isReadOnly) {
                                 const domain = new Domain(isReadOnly);
                                 check = domain.contains(formOnChange?.value);
                              }
                           } catch (error) {
                              if (formOnChange?.value && isReadOnly) {
                                 check = evaluateExpr(
                                    isReadOnly,
                                    formOnChange?.value
                                 );
                              }
                           }
                        } catch (e) {
                           console.error(e);
                           check = false;
                        }

                        const inputElement = RenderWidget(type, widget, {
                           name,
                           control,
                           register,
                           setValue,
                           isReadOnly,
                           isPlaceHolder,
                           required,
                           relation,
                           savedDataDetail,
                           modelsData,
                        });

                        if (!inputElement) return null;

                        return (
                           <div
                              key={index}
                              className="grid grid-cols-2 ml-1 h-fit"
                           >
                              <div className="flex">
                                 <div className="text-[#18191B] text-[14px] font-semibold">
                                    {string}
                                 </div>
                                 {required && (
                                    <span
                                       style={{ verticalAlign: "super" }}
                                       className="text-[14px] font-normal text-[#F5222D]"
                                    >
                                       *
                                    </span>
                                 )}
                              </div>
                              {inputElement}
                           </div>
                        );
                     })}
            </div>
         </div>
      </div>
   );
};

export default DynamicForm;
