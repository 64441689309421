import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useCategorySubmit from "../../hooks/useCategorySubmit";
import { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const AddCategoryList = ({ data, loading }) => {
  const { t } = useTranslation();
  const [categoryName, setCategoryName] = useState();
  const [categoryParentId, setCategoryParentId] = useState();

  const { onSubmitModal } = useCategorySubmit();
  return (
    <>
      {/* Add Category */}
      <div className="modal fade" id="add-category-list">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("add_new_category")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      {t("add_category_name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      {t("add_category_parent_name")}
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      isLoading={loading}
                      options={data?.map((item) => {
                        return {
                          value: item?.id,
                          label: item?.complete_name,
                        };
                      })}
                      placeholder={t("choose")}
                      onChange={(selectedOption) => {
                        setCategoryParentId(selectedOption.value);
                      }}
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                    >
                      {t("cancel")}
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-submit"
                      onClick={(e) => {
                        onSubmitModal(false, categoryParentId, categoryName),
                          e.preventDefault();
                      }}
                      data-bs-dismiss="modal"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </>
  );
};
AddCategoryList.defaultProps = {
  data: [],
  loading: false,
};
AddCategoryList.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
export default AddCategoryList;
