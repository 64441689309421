/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Domain } from "../../core/domain/domain";
import { evaluateExpr } from "../../core/domain/py";
import useAsync from "../../core/hooks/useAsync";
import ModelService from "../../core/services/modelService";
import { useSearchParams } from "react-router-dom";
import getSpectification from "../../core/utils/util";
import RowTree from "./DynamicRowTree";
const DynamicTree = ({
   tabData,
   listView,
   model,
   parentData,
   setSavedDataDetail,
   getValues,
}) => {
   const [searchParams] = useSearchParams();
   const idForm = searchParams.get("idForm");
   const tabModel = listView?.models?.[model]?.[tabData?.field_name]?.relation;
   const context = listView?.models?.[model]?.[tabData?.field_name]?.context;
   const relation_field =
      listView?.models?.[model]?.[tabData?.field_name]?.relation_field;
   const specification = getSpectification(listView, tabModel);
   const [ProductLine, setProductLine] = useState([]);
   console.log("ProductLine", ProductLine);
   const getValue = getValues;
   console.log("getValue", getValue);

   const { data: tabOnChange, loading } = useAsync(
      () =>
         ModelService.onChangeForm({
            idForm: idForm,
            model: tabModel,
            object: { [relation_field]: parentData },
            specification: specification,
            context: context,
         }),
      `dataOnchange${tabModel}`
   );

   const { data: dataLine } = useAsync(
      () =>
         ModelService.getDetailDataOfForm({
            model: tabModel,
            specification: specification,
            ids: parentData?.[tabData?.field_name]
               ? parentData?.[tabData?.field_name]
               : [],
         }),
      `data_${idForm}`
   );

   useEffect(() => {
      console.log("dataLine", dataLine);
   }, [dataLine]);

   const updateProductLine = (index, data) => {
      const lines = [...ProductLine];
      lines[index] = data;
      const random_id = Math.random().toString(36).substr(2, 9);
      const listRelation = lines.map((item) => [0, random_id, item]);
      const dataBody = { ...parentData };
      dataBody[tabData?.field_name] = listRelation;

      setSavedDataDetail(dataBody);
   };

   const checkDomain = (val, onChange) => {
      try {
         if (val === undefined || val === "0" || val === "False") {
            return false;
         } else if (val === "1" || val === "True") {
            return true;
         }
         try {
            if (onChange && val) {
               const domain = new Domain(val);
               return domain.contains(onChange);
            }
         } catch (error) {
            if (onChange && val) {
               return evaluateExpr(val, onChange);
            }
            return false;
         }
         return false;
      } catch (e) {
         return false;
      }
   };

   const columns = Array.isArray(tabData?.tree)
      ? tabData?.tree?.map((field) => ({
           ...field,
           fieldValue: listView?.models?.[tabModel]?.[field.name],
           hide:
              checkDomain(field?.column_invisible, tabOnChange?.value) ||
              field.invisible
                 .map((val) => {
                    try {
                       if (
                          val === undefined ||
                          val === "0" ||
                          val === "False"
                       ) {
                          return false;
                       } else if (val === "1" || val === "True") {
                          return true;
                       }
                       try {
                          if (tabOnChange?.value && val) {
                             const domain = new Domain(val);
                             return domain.contains(tabOnChange?.value);
                          }
                       } catch (error) {
                          if (tabOnChange?.value && val) {
                             return evaluateExpr(val, tabOnChange?.value);
                          }
                          return false;
                       }
                       return false;
                    } catch (e) {
                       return false;
                    }
                 })
                 .filter(Boolean).length > 0,
        }))
      : [];

   console.log("tabOnChange", tabOnChange);

   const handleAddInput = () => {
      setProductLine([...ProductLine, { ...tabOnChange?.value }]);
   };

   const removeItem = (index) => {
      setProductLine((prevOrderLine) => {
         return prevOrderLine.filter((_, i) => i !== index);
      });
   };

   return (
      <div className="flex flex-col gap-4">
         <table className="w-full border border-gray-300">
            <thead>
               <tr className="bg-gray-200 text-left">
                  {columns
                     ?.filter((item) => !item.hide)
                     ?.map((val, i) => (
                        <th key={i} className="p-2 border border-gray-300">
                           {val?.string ? val.string : val?.fieldValue?.string}
                        </th>
                     ))}
                  <th className="p-2 border border-gray-300">Action</th>
               </tr>
            </thead>
            <tbody>
               {!loading ? (
                  <>
                     {ProductLine.map((item, index) => {
                        return (
                           <RowTree
                              key={index}
                              index={index}
                              getOnChangeData={updateProductLine}
                              fields={columns}
                              handleRemove={removeItem}
                              defaultValue={item}
                              modelsData={listView?.models?.[tabModel]}
                           />
                        );
                     })}
                  </>
               ) : (
                  <div></div>
               )}
            </tbody>
         </table>
         <button
            type="button"
            className="px-4 py-2 w-[100px] bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleAddInput}
         >
            Add
         </button>
      </div>
   );
};
export default DynamicTree;
