/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAsync from "../../core/hooks/useAsync";
import ViewServices from "../../core/services/ViewService";
import { set_menu_focus } from "../../core/redux/action";
import { useDispatch } from "react-redux";

const Sidebar = () => {
   const [openMenus, setOpenMenus] = useState({});

   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { data: listMenu } = useAsync(
      () => ViewServices.getMenuView(),
      "get_menu_view"
   );

   const toggleMenu = (itemId, level) => {
      setOpenMenus((prev) => {
         const newOpenMenus = { ...prev };
         Object.keys(newOpenMenus).forEach((key) => {
            if (
               newOpenMenus[key] &&
               key !== itemId.toString() &&
               key.startsWith(`level-${level}-`)
            ) {
               delete newOpenMenus[key];
            }
         });
         newOpenMenus[`level-${level}-${itemId}`] =
            !prev[`level-${level}-${itemId}`];
         return newOpenMenus;
      });
   };

   const handleNavigation = (item) => {
      const id = item?.action?.id?.id;
      const name = item.name?.toLowerCase();
      // dispatch(menu_focus(id));
      dispatch(set_menu_focus(id));
      navigate(`/${name}?id=${id}`);
   };

   const renderMultiLevelMenu = (items, level = 0) => {
      return items.map((item, index) => (
         <li
            style={{
               display: item.is_display ? "block" : "none",
            }}
            className={`submenu submenu-level-${level} ${
               !item.child_id || item.child_id.length === 0 ? "" : ""
            }`}
            key={index}
         >
            <button className="btn-transparent w-full">
               <Link
                  className={
                     openMenus[`level-${level}-${item.id}`] ? "subdrop" : ""
                  }
                  onClick={(e) => {
                     e.preventDefault();
                     if (item.child_id && item.child_id.length > 0) {
                        toggleMenu(item.id, level);
                     } else {
                        handleNavigation(item);
                     }
                  }}
               >
                  <span
                     className="custom-active-span"
                     style={{
                        color: openMenus[`level-${level}-${item.id}`]
                           ? "$primary"
                           : "inherit",
                     }}
                  >
                     {item.name}
                  </span>
                  {item.child_id && item.child_id.length > 0 && (
                     <span className="menu-arrow" />
                  )}
               </Link>
            </button>
            {item.child_id && item.child_id.length > 0 && (
               <ul
                  style={{
                     display:
                        openMenus[`level-${level}-${item.id}`] &&
                        item.is_display
                           ? "block"
                           : "none",
                     marginLeft: level * 15,
                  }}
                  className={`mega-menu`}
               >
                  {renderMultiLevelMenu(item.child_id, level + 1)}
               </ul>
            )}
         </li>
      ));
   };

   return (
      <div>
         <div className="sidebar" id="sidebar">
            <Scrollbars>
               <div className="sidebar-inner slimscroll">
                  <div id="sidebar-menu" className="sidebar-menu">
                     <ul>
                        {listMenu?.records?.map((mainLabel, index) => (
                           <li
                              style={{
                                 display: mainLabel.is_display
                                    ? "block"
                                    : "none",
                              }}
                              className="submenu-open"
                              key={index}
                           >
                              <div className="flex items-start gap-2 align-items-center mb-3">
                                 <img
                                    src={`${mainLabel.web_icon_data}`}
                                    width={20}
                                    height={20}
                                    alt="img"
                                 />
                                 <h6 className="submenu-hdr">
                                    {mainLabel.name}
                                 </h6>
                              </div>
                              {mainLabel.child_id &&
                                 mainLabel.child_id.length > 0 && (
                                    <ul>
                                       {renderMultiLevelMenu(
                                          mainLabel.child_id
                                       )}
                                    </ul>
                                 )}
                           </li>
                        ))}
                     </ul>
                  </div>
               </div>
            </Scrollbars>
         </div>
      </div>
   );
};

export default Sidebar;
