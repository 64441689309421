/* eslint-disable react/prop-types */
import React from "react";

const TabButtons = ({ tabsData, selectedTabIndex, setSelectedTabIndex }) => (
   <ul className="nav nav-tabs nav-justified">
      {tabsData?.map((tab, index) => (
         <li
            className="nav-item"
            key={index}
            // type="button"
            // className={`text-[14px] px-4 py-2 transition-colors duration-200 ${index === selectedTabIndex ? "active" : ""}`}
            onClick={() => setSelectedTabIndex(index)}
         >
            <a
               className={`nav-link ${
                  index === selectedTabIndex ? "active" : ""
               }`}
               data-bs-toggle="tab"
            >
               {tab.string}
            </a>
         </li>
      ))}
   </ul>
);

export default TabButtons;
