import React, { createContext, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";

const TableContext = createContext();

// eslint-disable-next-line react/prop-types
export const TableProvider = ({ children, ...props }) => {
   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
   const [fileList, setFileList] = useState([]);
   const [searchParams] = useSearchParams();
   const id = searchParams.get("id");

   const resetSelection = () => {
      setSelectedRowKeys([]);
   };

   return (
      <TableContext.Provider
         value={{
            selectedRowKeys,
            setSelectedRowKeys,
            resetSelection,
            fileList,
            setFileList,
            id,
            ...props,
         }}
      >
         {children}
      </TableContext.Provider>
   );
};
export const useTableContext = () => useContext(TableContext);
