/* eslint-disable react/prop-types */
import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const SelectDropdown = ({ name, control, modelsData, savedDataDetail }) => (
   <Controller
      name={name}
      control={control}
      render={({ field }) => {
         const options = modelsData[name]?.selection
            ? modelsData[name].selection.map((val) => ({
                 value: val[0],
                 label: val[1],
              }))
            : [];

         const defaultValue = options.find(
            (option) => option.value === savedDataDetail[name]
         );
         return (
            <Select
               {...field}
               options={options}
               classNamePrefix="custom-select"
               placeholder="Select an option"
               isSearchable={true}
               onChange={(selectedOption) =>
                  field.onChange(selectedOption.value)
               }
               value={
                  options.find((option) => option.value === field.value) ||
                  defaultValue
               }
            />
         );
      }}
   />
);

export default SelectDropdown;
