import PropTypes from "prop-types";

const HeaderPDF = ({ name, address, tel }) => {
   return (
      <header className="text-center mb-3">
         <h4 className="font-bold uppercase">{name}</h4>
         <div>{address}</div>
         <div>Tel: {tel}</div>
         <div>------------------------</div>
      </header>
   );
};

HeaderPDF.propTypes = {
   name: PropTypes.string,
   address: PropTypes.string,
   tel: PropTypes.string,
};

export default HeaderPDF;
