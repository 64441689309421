const getSpectification = (listView, model) => {
  if (listView?.models?.[model] && model) {
    const modelValues = Object.values(listView.models[model]);
    if (modelValues.length > 0) {
      const result = modelValues.reduce((acc, item) => {
        if (item && typeof item === 'object' && 'name' in item) {
          acc[item.name] = {};
        }
        return acc;
      }, {});
      return result;
    } else {
      console.warn('modelValues is empty');
    }
  } else {
    console.warn('listView.models[model] or model is undefined');
  }
};

export default getSpectification;
