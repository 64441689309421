export const settingsdata = {};
// {
//   group_discount_per_so_line: false,
//   group_product_variant: true,
//   group_stock_adv_location: false,
//   group_stock_production_lot: true,
//   group_uom: true,
//   module_delivery: false,
//   module_loyalty: false,
//   module_stock_barcode: false,
//   module_stock_landed_costs: false,
//   pos_ship_later: false,
//   sale_tax_id: false
// }
// {
//     account_default_credit_limit: 0,
//     account_fiscal_country_id: {
//       display_name: "United States",
//       id: 233
//     },
//     account_storno: false,
//     account_use_credit_limit: false,
//     active_user_count: 3,
//     alias_domain_id: false,
//     annual_inventory_day: 31,
//     annual_inventory_month: "12",
//     auth_signup_reset_password: true,
//     auth_signup_uninvited: "b2c",
//     automatic_invoice: false,
//     barcode_nomenclature_id: {
//       display_name: "Default Nomenclature",
//       id: 1
//     },
//     chart_template: "generic_coa",
//     company_count: 11,
//     company_currency_id: {
//       id: 1
//     },
//     company_id: {
//       display_name: "My Company (San Francisco)",
//       id: 1
//     },
//     company_informations: "250 Executive Park Blvd, Suite 3400\nMaryland\n94134 - San Francisco\nCalifornia (US)\nUnited States\nVAT:  US12345671",
//     company_name: "My Company (San Francisco)",
//     company_so_template_id: false,
//     country_code: "US",
//     currency_id: {
//       display_name: "USD",
//       id: 1
//     },
//     days_to_purchase: 0,
//     default_invoice_policy: "order",
//     default_picking_policy: "direct",
//     default_purchase_method: "receive",
//     deposit_default_product_id: false,
//     digest_emails: true,
//     digest_id: {
//       display_name: "Your Odoo Periodic Digest",
//       id: 1
//     },
//     display_invoice_amount_total_words: false,
//     display_name: false,
//     email_primary_color: "#af3943",
//     email_secondary_color: "#241f23",
//     external_email_server_default: false,
//     google_gmail_client_identifier: false,
//     google_gmail_client_secret: false,
//     google_translate_api_key: false,
//     group_auto_done_setting: false,
//     group_cash_rounding: false,
//     group_discount_per_so_line: false,
//     group_lot_on_delivery_slip: false,
//     group_lot_on_invoice: false,
//     group_multi_currency: false,
//     group_product_pricelist: false,
//     group_product_variant: true,
//     group_proforma_sales: false,
//     group_sale_delivery_address: false,
//     group_sale_order_template: true,
//     group_sale_pricelist: false,
//     group_send_reminder: true,
//     group_show_purchase_receipts: false,
//     group_show_sale_receipts: false,
//     group_stock_adv_location: false,
//     group_stock_lot_print_gs1: false,
//     group_stock_multi_locations: true,
//     group_stock_packaging: false,
//     group_stock_picking_wave: false,
//     group_stock_production_lot: true,
//     group_stock_reception_report: false,
//     group_stock_sign_delivery: false,
//     group_stock_storage_categories: false,
//     group_stock_tracking_lot: false,
//     group_stock_tracking_owner: false,
//     group_uom: true,
//     group_warning_account: false,
//     group_warning_purchase: false,
//     group_warning_sale: true,
//     group_warning_stock: false,
//     has_accounting_entries: true,
//     has_chart_of_accounts: true,
//     hr_employee_self_edit: false,
//     hr_presence_control_email: false,
//     hr_presence_control_email_amount: 0,
//     hr_presence_control_ip: false,
//     hr_presence_control_ip_list: false,
//     hr_presence_control_login: true,
//     incoterm_id: false,
//     invoice_is_download: true,
//     invoice_is_email: true,
//     invoice_is_snailmail: false,
//     invoice_is_ubl_cii: false,
//     invoice_terms: false,
//     is_account_peppol_eligible: false,
//     is_installed_sale: true,
//     is_kiosk_mode: false,
//     is_root_company: true,
//     language_count: 2,
//     lock_confirmed_po: false,
//     module_account_3way_match: false,
//     module_account_avatax: false,
//     module_account_batch_payment: false,
//     module_account_intrastat: false,
//     module_account_invoice_extract: false,
//     module_account_peppol: false,
//     module_account_sepa: false,
//     module_account_sepa_direct_debit: false,
//     module_account_taxcloud: false,
//     module_auth_ldap: false,
//     module_auth_oauth: false,
//     module_base_geolocalize: false,
//     module_currency_rate_live: false,
//     module_delivery: false,
//     module_delivery_bpost: false,
//     module_delivery_dhl: false,
//     module_delivery_easypost: false,
//     module_delivery_fedex: false,
//     module_delivery_sendcloud: false,
//     module_delivery_shiprocket: false,
//     module_delivery_ups: false,
//     module_delivery_usps: false,
//     module_google_gmail: true,
//     module_google_recaptcha: false,
//     module_hr_attendance: false,
//     module_hr_homeworking: false,
//     module_hr_presence: false,
//     module_hr_skills: true,
//     module_l10n_eu_oss: false,
//     module_loyalty: false,
//     module_mail_plugin: false,
//     module_microsoft_outlook: false,
//     module_partner_autocomplete: true,
//     module_pos_adyen: false,
//     module_pos_mercury: false,
//     module_pos_paytm: false,
//     module_pos_preparation_display: true,
//     module_pos_six: false,
//     module_pos_stripe: false,
//     module_product_email_template: false,
//     module_product_expiry: false,
//     module_product_images: false,
//     module_product_margin: false,
//     module_purchase_product_matrix: false,
//     module_purchase_requisition: false,
//     module_quality_control: false,
//     module_quality_control_worksheet: false,
//     module_sale_amazon: false,
//     module_sale_margin: false,
//     module_sale_product_matrix: false,
//     module_snailmail_account: true,
//     module_stock_barcode: false,
//     module_stock_dropshipping: false,
//     module_stock_landed_costs: false,
//     module_stock_picking_batch: false,
//     module_web_unsplash: true,
//     module_website_cf_turnstile: false,
//     pay_invoices_online: true,
//     po_double_validation: "one_step",
//     po_double_validation_amount: 5000,
//     po_lead: 0,
//     po_lock: "edit",
//     po_order_approval: false,
//     point_of_sale_ticket_unique_code: false,
//     point_of_sale_use_ticket_qr_code: false,
//     portal_confirmation_pay: true,
//     portal_confirmation_sign: true,
//     pos_amount_authorized_diff: 0,
//     pos_auto_validate_terminal_payment: true,
//     pos_cash_control: true,
//     pos_cash_rounding: false,
//     pos_company_has_template: true,
//     pos_config_id: {
//       display_name: "Shop (Esol Labs)",
//       id: 1
//     },
//     pos_crm_team_id: false,
//     pos_default_fiscal_position_id: false,
//     pos_down_payment_product_id: {
//       display_name: "Down Payment (POS)",
//       id: 4
//     },
//     pos_epson_printer_ip: "",
//     pos_has_active_session: true,
//     pos_iface_big_scrollbars: false,
//     pos_iface_cashdrawer: false,
//     pos_iface_customer_facing_display_background_image_256: false,
//     pos_iface_customer_facing_display_local: false,
//     pos_iface_customer_facing_display_via_proxy: false,
//     pos_iface_electronic_scale: false,
//     pos_iface_orderline_notes: false,
//     pos_iface_print_auto: false,
//     pos_iface_print_skip_screen: true,
//     pos_iface_print_via_proxy: false,
//     pos_iface_printbill: false,
//     pos_iface_scan_via_proxy: false,
//     pos_iface_splitbill: false,
//     pos_iface_start_categ_id: false,
//     pos_iface_tax_included: "total",
//     pos_iface_tipproduct: false,
//     pos_invoice_journal_id: {
//       display_name: "Customer Invoices",
//       id: 1
//     },
//     pos_is_header_or_footer: false,
//     pos_is_margins_costs_accessible_to_every_user: false,
//     pos_is_order_printer: false,
//     pos_is_posbox: false,
//     pos_journal_id: {
//       display_name: "Point of Sale",
//       id: 9
//     },
//     pos_limit_categories: false,
//     pos_manual_discount: true,
//     pos_module_pos_discount: false,
//     pos_module_pos_hr: false,
//     pos_module_pos_restaurant: false,
//     pos_module_pos_restaurant_appointment: false,
//     pos_only_round_cash_method: false,
//     pos_other_devices: false,
//     pos_payment_method_ids: [
//       [
//         4,
//         1,
//         {
//           display_name: "Cash",
//           id: 1
//         }
//       ],
//       [
//         4,
//         2,
//         {
//           display_name: "Bank",
//           id: 2
//         }
//       ],
//       [
//         4,
//         3,
//         {
//           display_name: "Customer Account",
//           id: 3
//         }
//       ]
//     ],
//     pos_picking_policy: "direct",
//     pos_picking_type_id: {
//       display_name: "Test Warehouse : PoS Orders",
//       id: 6
//     },
//     pos_pricelist_id: false,
//     pos_proxy_ip: false,
//     pos_receipt_footer: false,
//     pos_receipt_header: false,
//     pos_restrict_price_control: false,
//     pos_rounding_method: false,
//     pos_selectable_categ_ids: [
//       [
//         4,
//         1,
//         {
//           id: 1
//         }
//       ],
//       [
//         4,
//         2,
//         {
//           id: 2
//         }
//       ]
//     ],
//     pos_self_order_online_payment_method_id: false,
//     pos_self_ordering_alternative_fp_id: false,
//     pos_self_ordering_default_language_id: false,
//     pos_self_ordering_image_brand: false,
//     pos_self_ordering_image_brand_name: false,
//     pos_self_ordering_mode: "nothing",
//     pos_self_ordering_pay_after: "each",
//     pos_self_ordering_service_mode: "counter",
//     pos_self_ordering_takeaway: false,
//     pos_set_maximum_difference: false,
//     pos_set_tip_after_payment: false,
//     pos_ship_later: false,
//     pos_start_category: false,
//     pos_tax_regime_selection: false,
//     pos_tip_product_id: false,
//     pos_use_pricelist: false,
//     pos_warehouse_id: {
//       display_name: "Test Warehouse ",
//       id: 1
//     },
//     prepayment_percent: 1,
//     preview_ready: false,
//     product_pricelist_setting: "basic",
//     product_volume_volume_in_cubic_feet: "0",
//     product_weight_in_lbs: "0",
//     purchase_tax_id: {
//       display_name: "15%",
//       id: 2
//     },
//     qr_code: false,
//     quick_edit_mode: false,
//     quotation_validity_days: 30,
//     resource_calendar_id: {
//       display_name: "Standard 40 hours/week",
//       id: 1
//     },
//     rest_docs_security_group_id: false,
//     rest_docs_security_group_xmlid: false,
//     rest_oauth2_bearer_autovacuum_days: 7,
//     rest_oauth2_bearer_expires_in_seconds: 3600,
//     restrict_template_rendering: false,
//     s3_config_id: {
//       id: 1
//     },
//     sale_footer: "",
//     sale_header_name: "Header Example.pdf",
//     sale_tax_id: {
//       display_name: "15%",
//       id: 1
//     },
//     security_lead: 0,
//     sfu_server_key: false,
//     sfu_server_url: false,
//     snailmail_color: true,
//     snailmail_cover: false,
//     snailmail_duplex: false,
//     stock_move_email_validation: false,
//     stock_move_sms_validation: true,
//     stock_sms_confirmation_template_id: {
//       display_name: "Delivery: Send by SMS Text Message",
//       id: 1
//     },
//     tax_calculation_rounding_method: "round_per_line",
//     tenor_api_key: false,
//     tenor_content_filter: "low",
//     tenor_gif_limit: 8,
//     terms_type: "plain",
//     twilio_account_sid: false,
//     twilio_account_token: false,
//     unsplash_access_key: false,
//     unsplash_app_id: false,
//     use_invoice_terms: false,
//     use_po_lead: false,
//     use_security_lead: false,
//     use_twilio_rtc_servers: false,
//     user_default_rights: false,
//     write_date: false
//   }
