/* eslint-disable react/prop-types */
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import useAsync from "../../../core/hooks/useAsync";

import GeneralService from "../../../core/services/GeneralService";
const Many2ManyCheckbox = ({ relation, name, control }) => {
   const { data: dataOfSelection } = useAsync(
      () => GeneralService.getSelectionItem({ model: relation }),
      `data_${relation}`
   );

   const options =
      dataOfSelection?.records?.map((val) => ({
         value: val.id,
         label: val.name,
      })) || [];

   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <div className="flex flex-col space-y-2">
               {options.map((option) => (
                  <label
                     key={option.value}
                     className="flex items-center gap-2 p-2 border border-gray-300 rounded-lg hover:bg-gray-100"
                  >
                     <input
                        type="checkbox"
                        value={option.value}
                        checked={field.value?.includes(option.value) || false}
                        onChange={(e) => {
                           const newValue = [...(field.value || [])];
                           if (e.target.checked) {
                              newValue.push(option.value);
                           } else {
                              const index = newValue.indexOf(option.value);
                              if (index > -1) {
                                 newValue.splice(index, 1);
                              }
                           }
                           field.onChange(newValue);
                        }}
                        className="h-5 w-5 text-blue-600"
                     />
                     <span className="text-gray-700">{option.label}</span>
                  </label>
               ))}
            </div>
         )}
      />
   );
};

export default Many2ManyCheckbox;
