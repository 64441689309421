import React, { useContext } from "react";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { Link } from "react-router-dom";
import { all_routes } from "../../../Router/all_routes";
import ButtonLoading from "../../../InitialPage/ButtonLoading";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import UserService from "../../../core/services/UserService";
import alertify from "alertifyjs";
import { SidebarContext } from "../../../core/context/SidebarContext";
import { useNavigate } from "react-router-dom";
const Forgotpassword = () => {
   const route = all_routes;
   const navigate = useNavigate();
   const { register, handleSubmit } = useForm();
   const { isLoading } = useContext(SidebarContext);
   const { t } = useTranslation();
   const onSubmit = (data) => {
      UserService.forgotPassword(data)
         .then((res) => {
            console.log("🚀 ~ onSubmit ~ res:", res);
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("success_forgot_password"));
         })
         .catch((err) => {
            console.error("🚀 ~ onSubmit ~ err:", err);
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.detail || t("error_forgot_password"));
         });
   };
   return (
      <div className="main-wrapper">
         <div className="account-content">
            <div className="login-wrapper bg-img">
               <div className="login-content">
                  <form
                     onSubmit={handleSubmit(onSubmit)}
                     style={{ width: "70%" }}
                  >
                     <div className="login-userset">
                        <div
                           style={{ width: "200px !important" }}
                           className="login-logo logo-normal"
                        >
                           <ImageWithBasePath
                              src="assets/img/logo.svg"
                              alt="img"
                           />
                        </div>
                        <Link
                           to={route.dashboard}
                           className="login-logo logo-white"
                        >
                           <ImageWithBasePath
                              src="assets/img/logo-white.svg"
                              alt
                           />
                        </Link>
                        <div className="login-userheading">
                           <h3>{t("login")}</h3>
                           <h4>{t("note_login")}</h4>
                        </div>

                        <div className="form-login">
                           <label>Email</label>
                           <div className="form-addons">
                              <input
                                 {...register("email")}
                                 type="email"
                                 className="form-control"
                              />
                              <ImageWithBasePath
                                 src="assets/img/icons/mail.svg"
                                 alt="img"
                              />
                           </div>
                        </div>
                        <div className="form-login">
                           <ButtonLoading
                              loading={isLoading}
                              className={"btn btn-login"}
                              content={"Gửi"}
                           />
                        </div>
                        <div className="form-login">
                           <button
                              onClick={() => navigate("/signin")}
                              style={{
                                 color: "#232323",
                                 fontWeight: "bold",
                                 fontSize: "16px",
                                 padding: "16px 8px",
                                 borderRadius: "10px",
                                 background: "#fff",
                                 border: "1px solid #E3E3E3",
                                 width: "100%",
                              }}
                           >
                              Quay lại đăng nhập
                           </button>
                        </div>
                     </div>
                  </form>
               </div>
               <div className="login-content">
                  <ImageWithBasePath
                     src="assets/img/main-signin.jpg"
                     alt="img"
                  />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Forgotpassword;
