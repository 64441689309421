import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import ViewServices from "../../../core/services/ViewService";
import { useEffect, useState } from "react";
import ModelService from "../../../core/services/modelService";

import FromView from "../fromView/page";
import filterIcon from "../../../core/img/filter-icon.svg";
import { TableProvider } from "./TableContext";
import UploadFiles from "../upload-files/page";
// import { useMetaContext } from "../../../Router/MetaContext";
import GeneralService from "../../../core/services/GeneralService";
import useAsync from "../../../core/hooks/useAsync";
import Datatable from "../../../core/pagination/datatable";
import Loading from "../../../InitialPage/Sidebar/Loading";
import Breadcrumbs from "../../../InitialPage/ui/Breadcrumbs";

const ListView = () => {
   const [searchParams] = useSearchParams();
   const navigate = useNavigate();
   const [openModal, setOpenModal] = useState(false);
   const [dataParse, setDataParse] = useState();
   const [idFile, setIdFile] = useState();
   const [domain, setDomain] = useState();
   const [dataToolbar, setDataToolbar] = useState();
   // const { loadingImport } = useMetaContext();
   const [name, setName] = useState();
   const [resModal, setResModal] = useState();
   const locations = useLocation();
   const path = locations.pathname;
   const formattedPath =
      path.replace("/", "").charAt(0).toUpperCase() + path.slice(2);
   const segments = path.split("/");
   const lastSegment = segments.pop();
   const baseUrl = path.replace(/^\/?(new-|update-)?/, "");
   const result = lastSegment.startsWith("new-")
      ? lastSegment.substring(4)
      : lastSegment;
   const id = searchParams.get("id");
   const [specification, setSpecification] = useState();
   const [page, setPage] = useState(0);

   const { data: dataView, loading: loadingField } = useAsync(
      () => ViewServices.getFieldView({ id: id }),
      `get_fields_view_${id}`
   );
   const splitFormattedPath = formattedPath?.split("-");
   const capitalizeFirstLetter = (str) => {
      if (!str) return str; // Kiểm tra nếu chuỗi rỗng
      return str.charAt(0).toUpperCase() + str.slice(1);
   };
   const handleDataParseChange = (newData) => {
      setDataParse(newData);
   };
   const handleIdFileChange = (newId) => {
      if (idFile) {
         setIdFile(idFile);
      } else if (newId != null) {
         setIdFile(newId);
      } else {
         setIdFile(null);
      }
   };
   const showCapitalizedPart = splitFormattedPath[1]
      ? capitalizeFirstLetter(splitFormattedPath[1])
      : "";
   const [fields, setFields] = useState([]);
   const [isShow, setIsShow] = useState(false);
   const { data: viewID, loading: loadingView } = useAsync(
      () => GeneralService.getViewId({ id: id }),
      `view_${id}`
   );
   const dataModel = dataView?.models?.[viewID[0]?.res_model];

   const model = viewID && viewID[0]?.res_model;
   const sort = "";
   const {
      data,
      setData,
      loading: loadingData,
   } = useAsync(
      () =>
         ModelService.getAll({ resModal, specification, domain, page, sort }),
      `data_table_${id}`
   );
   const dataFilter = fields
      ?.filter((item) => item.optional && !item.column_invisible)
      ?.map((field) => {
         if (!field.string) {
            const newField = {
               ...field,
               string: dataModel[field?.name]?.string,
            };
            return newField;
         }
         return field;
      });

   const convertStringToArray = (str) => {
      str = str?.trim()?.slice(1, -1);
      const regex = /\('([^']*)',\s*'([^']*)',\s*(True|False)\)/;
      const match = str.match(regex);
      if (match) {
         const [, field, operator, value] = match;
         return [[field, operator, value === "True" ? true : false]];
      }

      return null; // Trả về null nếu không match
   };
   const fetchData = async (model, domains) => {
      // const domain = domains ? [domains[0]] : [];

      await ModelService.getAll({
         model,
         specification,
         domain: domains,
         page,
         sort,
      }).then((responsive) => {
         setData(responsive);
      });
   };
   const handleGetColumns = () => {
      let cols = fields
         ?.filter(
            (item) =>
               (item.optional == "show" || !item.optional) &&
               !item.column_invisible
         )
         ?.map((field) => {
            return {
               dataIndex: field?.name,
               title: dataModel[field?.name]?.string,
            };
         });
      if (dataFilter.length > 0) {
         cols.push({
            dataIndex: "filter",
            title: (
               <div className="w-[25px]">
                  <button onClick={() => setOpenModal(!openModal)}>
                     <img
                        src={filterIcon}
                        alt="filter icon"
                        width={16}
                        height={16}
                     />
                  </button>
                  {openModal && (
                     <div className="absolute rounded-[8px] shadow-md bg-[#fff] left-[-150px] px-[24px] py-[16px] z-[2] flex flex-col gap-[16px] w-[250px] h-[600px] overflow-y-scroll">
                        {dataFilter?.map((item) => {
                           return (
                              <label
                                 key={item.name}
                                 htmlFor="payment-status"
                                 className="flex items-center gap-[8px]"
                                 onClick={() => handleClick(item)}
                              >
                                 <input
                                    type="checkbox"
                                    id="payment-status"
                                    checked={item.optional != "hide"}
                                 />
                                 {item.string}
                              </label>
                           );
                        })}
                     </div>
                  )}
               </div>
            ),
         });
      }
      return cols;
   };

   const handleAddNew = () => {
      navigate(`/new-${path.replace("/", "")}?id=${id}&model=${model}`);
   };

   const handleUpdate = (recordId) => {
      navigate(`/update-${result}?id=${id}&idForm=${recordId}&model=${model}`);
   };

   const handleBack = () => {
      navigate(`/${baseUrl}?id=${id}&model=${model}`);
      setIsShow(false);
   };

   const handleClick = (data) => {
      if (data.optional == "show") {
         setFields((prevItems) =>
            prevItems.map((item) =>
               item.name === data.name ? { ...item, optional: "hide" } : item
            )
         );
      } else {
         setFields((prevItems) =>
            prevItems.map((item) =>
               item.name === data.name ? { ...item, optional: "show" } : item
            )
         );
      }
   };
   useEffect(() => {
      if (
         dataView?.views?.tree?.fields?.length > 0 &&
         dataView?.views?.tree?.toolbar
      ) {
         setFields(dataView?.views?.tree?.fields);
         setDataToolbar(dataView?.views?.tree?.toolbar);
         const result = dataView?.views?.tree?.fields?.reduce((acc, item) => {
            acc[item.name] = {};
            return acc;
         }, {});

         setSpecification(result);
      }
   }, [dataView]);

   useEffect(() => {
      if (specification && viewID[0].res_model != "") {
         const domains =
            viewID[0]?.domain && convertStringToArray(viewID[0]?.domain);
         const model = viewID && viewID[0]?.res_model;
         setName(viewID[0].name);
         setResModal(viewID[0]?.res_model);
         fetchData(model, domain ? domain : domains ? [domains[0]] : []);
         // setDomain(viewID[0]?.domain);
      }
   }, [specification, viewID, page]);

   useEffect(() => {
      if (path.includes("new-") || path.includes("update-")) {
         setIsShow(true);
      } else {
         setIsShow(false);
      }
   }, [path]);

   return (
      <>
         {!loadingData && !loadingField && !loadingView ? (
            <TableProvider
               dataParse={dataParse}
               setDataParse={handleDataParseChange}
               idFile={idFile}
               setIdFile={handleIdFileChange}
            >
               <div className="page-wrapper">
                  {dataParse ? (
                     <div className="content">
                        <UploadFiles data={dataParse} resModal={resModal} />
                     </div>
                  ) : (
                     <div className="content">
                        <Breadcrumbs
                           titleContent={name}
                           maintitle={`${decodeURIComponent(
                              splitFormattedPath[0]
                           )} ${decodeURIComponent(
                              capitalizeFirstLetter(showCapitalizedPart)
                           )} List`}
                           resModal={resModal}
                           subtitle={`Manage Your ${decodeURIComponent(
                              splitFormattedPath[0]
                           )} ${decodeURIComponent(
                              capitalizeFirstLetter(showCapitalizedPart)
                           )}`}
                           addButton={`${
                              isShow
                                 ? `Back to ${baseUrl}`
                                 : `Add New ${decodeURIComponent(
                                      splitFormattedPath[0]
                                   )} ${decodeURIComponent(
                                      capitalizeFirstLetter(showCapitalizedPart)
                                   )}`
                           }`}
                           onClick={isShow ? handleBack : handleAddNew}
                           dataToolbar={dataToolbar}
                           setPage={setPage}
                           setData={fetchData}
                           setDomain={setDomain}
                           data={data}
                           isShow={isShow}
                        />

                        {isShow ? (
                           <FromView
                              listView={dataView}
                              dataViewById={viewID}
                           />
                        ) : (
                           <>
                              {loadingData ? (
                                 <Loading />
                              ) : (
                                 <div className="card">
                                    <div className="card-body">
                                       <div
                                          className={`w-full overflow-hidden relative ${
                                             dataFilter.length > 0 &&
                                             "mr-[60px]"
                                          }`}
                                       >
                                          <div className="w-full overflow-x-scroll flex items-center">
                                             <Datatable
                                                className="table datanew"
                                                columns={handleGetColumns()}
                                                dataSource={data?.records}
                                                loading={loadingData}
                                                onClick={handleUpdate}
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </>
                        )}
                     </div>
                  )}
               </div>
            </TableProvider>
         ) : (
            <Loading />
         )}
      </>
   );
};
export default ListView;
