// // import qs from "qs";
// import requests from "./httpService";
// // import Cookies from "js-cookie";
// import {
//     callPath,
//     updatePath,
//     searchReadPath,
//     createPath,
//   //uploadFilePath,
//     //delelePath
// } from "./endpoint";

// const InvoiceService = {

//     // Get Invoice list
//     getAll: async () => {
//         const jsonData = {
//             params: {
//                 model: "account.move",
//                 domain: "[['move_type', '=', 'out_invoice']]",  // Only outvoice is display
//                 order: "create_date desc",  // Date descending oredr
//                 fields: "['name','invoice_partner_display_name','invoice_date_due','amount_total_signed','amount_residual_signed','payment_state','state']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get Invoice detail with id
//     get: async (id) => {
//         const jsonData = {
//             params: {
//                 model: "account.move",
//                 domain: `[['id', '=', ${id}]]`,
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get Invoice Line list
//     getLinesAll: async (ids) => {
//         const jsonData = {
//             params: {
//                 model: "account.move.line",
//                 domain: `[('move_id','=',${ids})]`,//`[('id','in',[${ids}])]`,
//                 fields: "['product_id', 'name', 'quantity', 'tax_ids', 'discount', 'price_total', 'price_unit', 'move_id']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get Invoice Line
//     getLine: async (id) => {
//         const jsonData = {
//             params: {
//                 model: "account.move.line",
//                 domain: `[('id','=',${id})]`,//`[('id','in',[${ids}])]`,
//                 fields: `["product_id", "name", "quantity", "product_uom_id", "price_unit", "discount", "tax_ids", "price_subtotal", "price_total"]`
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     getProductTemplateIds: async (ids) => {
//         const jsonData = {
//             params: {
//                 model: "product.product",
//                 domain: `[('id','in',[${ids}])]`,//`[('id','in',[${ids}])]`,
//                 fields: `["product_tmpl_id"]`
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get Invoice Line list
//     getPaymentTermAll: async () => {
//         const jsonData = {
//             params: {
//                 model: "account.payment.term",
//                 fields: "['name']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get Invoice Line list
//     getCustomerAll: async () => {
//         const jsonData = {
//             params: {
//                 model: "res.partner",
//                 fields: "['name']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get Invoice Line list
//     getProductAll: async () => {
//         const jsonData = {
//             params: {
//                 model: "product.product",
//                 fields: "['name']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get UOM list
//     getUomAll: async () => {
//         const jsonData = {
//             params: {
//                 model: "uom.uom",
//                 fields: "['name']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Get UOM list
//     getTaxAll: async () => {
//         const jsonData = {
//             params: {
//                 model: "account.tax",
//                 fields: "['name']"
//             }
//         };
//         return requests.get(searchReadPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Update an invoice
//     update: async (id, newData) => {
//         const jsonData = {
//             model: "account.move",
//             domain: `[["id", "=", ${id}]]`,
//             values: {
//                 move_type: 'out_invoice',
//                 partner_id: newData.partner_id[0],
//                 payment_reference: newData.payment_reference,
//                 invoice_date: newData.invoice_date,
//                 delivery_date: newData.delivery_date,
//                 invoice_date_due: newData.invoice_date_due,
//                 invoice_payment_term_id: newData.invoice_payment_term_id[0],
//                 state: newData.state
//             }
//         };
//         //console.log("New Data: ",newData);
//         return requests.post(updatePath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Update an invoice
//     updateLine : async (id, newData) => {
//         const jsonData = {
//             model: "account.move.line",
//             domain: `[["id", "=", ${id}]]`,
//             values: {
//                 product_id: newData.product_id[0],
//                 quantity: Number(newData.quantity),
//                 product_uom_id: newData.product_uom_id[0],
//                 price_unit: newData.price_unit,
//                 discount: Number(newData.discount),
//                 tax_ids: newData.tax_ids
//             }
//         };
//         //console.log("New Data: ",newData);
//         return requests.post(updatePath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Create an Invoice
//     create: async (newData) => {
//         const jsonData = {
//             model: "account.move",
//             values: {

//                 partner_id: (newData.partner_id ? newData.partner_id[0] : false),
//                 payment_reference: (newData.payment_reference ? newData.payment_reference : false),
//                 invoice_date: (newData.invoice_date ? newData.invoice_date : false),
//                 delivery_date: (newData.delivery_date ? newData.delivery_date : false),
//                 invoice_date_due: (newData.invoice_date_due ? newData.invoice_date_due : false),
//                 invoice_payment_term_id: (newData.invoice_payment_term_id ? newData.invoice_payment_term_id[0] : false),
//                 state: newData.state,
//                 move_type: "out_invoice",
//                 journal_id: 1

//             }
//         };
//         return requests.post(createPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Create new Line
//     createLine: async (moveId, newData) => {
//         const jsonData = {
//             model: "account.move.line",
//             values: {
//                 move_id: moveId,
//                 account_id: 2,
//                 product_id: (newData.product_id ? newData.product_id[0] : false),
//                 quantity: (newData.quantity ? Number(newData.quantity) : false),
//                 product_uom_id: (newData.product_uom_id ? newData.product_uom_id[0] : false),
//                 price_unit: (newData.price_unit ? newData.price_unit : false),
//                 discount: (newData.discount ? Number(newData.discount) : false ),
//                 tax_ids: (newData.tax_ids ? newData.tax_ids : false)
//             }
//         };
//         return requests.post(createPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Delete and invoices
//     delete: async (ids) => {
//         const jsonData = {
//             model: "account.move",
//             method: "unlink",
//             ids: `[${ids}]`    // Id list
//         };
//         return requests.post(callPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     },

//     // Delete and invoices
//     deleteLine: async (ids) => {
//         const jsonData = {
//             model: "account.move.line",
//             method: "unlink",
//             ids: `[${ids}]`    // Id list
//         };
//         return requests.post(callPath, jsonData, {
//             headers: {
//                 "Content-Type": "application/json",
//             },
//         });
//     }
// };

// export default InvoiceService;

import requests from "./httpService";
import { callPath, createPath } from "./endpoint";

const InvoiceService = {
   InvoiceView: async ({ idInvoice, model }) => {
      const id = Number(idInvoice);
      const jsonData = {
         model: "account.move.send",
         method: "onchange",
         with_context: {
            active_model: model,
            active_id: id,
            active_ids: [id],
            default_mail_template_id: 28,
         },
         args: [
            {},
            [],
            {
               company_id: {
                  fields: {},
               },
               move_ids: {
                  fields: {},
               },
               mode: {},
               enable_download: {},
               enable_send_mail: {},
               send_mail_readonly: {},
               send_mail_warning_message: {},
               display_mail_composer: {},
               mail_lang: {},
               show_ubl_company_warning: {},
               ubl_partner_warning: {},
               send_by_post_warning_message: {},
               checkbox_download: {},
               checkbox_send_mail: {},
               enable_send_by_post: {},
               send_by_post_readonly: {},
               checkbox_send_by_post: {},
               send_by_post_cost: {},
               enable_ubl_cii_xml: {},
               checkbox_ubl_cii_xml: {},
               checkbox_ubl_cii_label: {},
               mail_partner_ids: {
                  fields: {
                     display_name: {},
                     email: {},
                  },
                  context: {
                     show_email: true,
                     form_view_ref: "base.view_partner_simple_form",
                  },
               },
               mail_subject: {},
               mail_body: {},
               mail_attachments_widget: {},
               mail_template_id: {
                  fields: {
                     display_name: {},
                  },
                  context: {
                     default_model: "account.move",
                  },
               },
            },
         ],
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   CreateRecord: async ({ idInvoice, data }) => {
      const id = Number(idInvoice);
      const jsonData = {
         model: "account.move.send",
         values: {
            move_ids: [[4, id]],
            mode: data?.mode,
            checkbox_download: data?.checkbox_download,
            checkbox_send_by_post: data?.checkbox_send_by_post,
            checkbox_ubl_cii_xml: data?.checkbox_ubl_cii_xml,
            mail_partner_ids: data?.mail_partner_ids,
            mail_subject: data?.mail_subject,
            mail_body: data?.mail_body,
            mail_attachments_widget: data?.mail_attachments_widget,
            mail_template_id: data?.mail_template_id?.id,
         },
      };
      return requests.post(createPath, jsonData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   SendEmail: async ({ id }) => {
      const jsonData = {
         model: "account.move.send",
         method: "action_send_and_print",
         ids: [id],
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   ActionConfirm: async ({ id, model }) => {
      const jsonData = {
         model: model,

         method: "action_post",

         ids: [id],

         kwargs: {},
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
};
export default InvoiceService;
