// src/hooks/useFetchData.js
import { useState, useEffect } from "react";
import ModelService from "../services/modelService";

const useFetchData = (idForm, model, specification) => {
   const [detailData, setDetailData] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      const fetchData = async () => {
         setLoading(true);
         const response = await ModelService.getDetailDataOfForm({
            idForm: Number(idForm),
            model: model,
            specification: specification,
         });
         setDetailData(response);
         setLoading(false);
      };

      if (specification && model && idForm) {
         fetchData();
      }
   }, [idForm, model, specification]);

   return { detailData, loading, setDetailData };
};

export default useFetchData;
