import React from "react";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import { base_path } from "./environment.jsx";
import "../src/style/css/feather.css";
import "../src/style/css/line-awesome.min.css";
import "../src/style/scss/main.scss";
import "../src/style/icons/fontawesome/css/fontawesome.min.css";
import "../src/style/icons/fontawesome/css/all.min.css";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./core/redux/store.jsx";
import AllRoutes from "./Router/router.jsx";
import "./i18n.js";
import { SidebarProvider } from "./core/context/SidebarContext.jsx";
import "./input.css";
const rootElement = document.getElementById("root");

if (rootElement) {
   const root = createRoot(rootElement);
   root.render(
      <SidebarProvider>
         <Provider store={store}>
            <BrowserRouter basename={base_path}>
               <AllRoutes />
            </BrowserRouter>
         </Provider>
      </SidebarProvider>
   );
} else {
   console.error("Element with id 'root' not found.");
}
