import { Link } from "react-router-dom";
import useAttributeSubmit from "../../hooks/useAttributeSubmit";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Table, Button } from "antd";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import PropTypes from "prop-types";
import Panel from "rc-color-picker/lib/Panel";
import Dropdown from "antd/lib/dropdown";
import "rc-color-picker/assets/index.css";

const EditableContext = React.createContext(null);
const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingInlineEnd: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const AddVariantAttribute = () => {
  const { t } = useTranslation();
  const [attributeName, setAttributeName] = useState();
  const [displayType, setDisplayType] = useState("radio");
  const [variantsCreation, setVariantsCreation] = useState("always");
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(2);

  const defaultColumns = [
    {
      title: t("name"),
      dataIndex: "name",
      width: "30%",
      editable: true,
    },
    ...(displayType === "color"
      ? [
          {
            title: t("color"),
            dataIndex: "html_color",
            render: (_, record) => (
              <Dropdown
                overlayStyle={{ zIndex: "10000" }}
                trigger={["click"]}
                overlay={
                  <Panel
                    color={record.html_color}
                    enableAlpha={false}
                    onChange={(color) => handleColorChange(record.key, color.color)}
                  />
                }
              >
                <Button style={{ background: record.html_color }}> </Button>
              </Dropdown>
            ),
          },
        ]
      : []),
    {
      title: t("is_custom_value"),
      dataIndex: "is_custom",
      render: (_, record) => (
        <>
          <input
            type="checkbox"
            id={"isCustomeValue" + record.key}
            className="check"
            onChange={(e) => handleCheckboxChange(record.key, e.target.checked)}
          />
          <label
            htmlFor={"isCustomeValue" + record.key}
            className="checktoggle mb-0 me-1"
          />
        </>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Link className="d-block w-100 confirm-text p-2 text-end" to="#">
            <Trash2
              className="feather-trash-2"
              onClick={() => handleDelete(record.key)}
            />
          </Link>
        ) : null,
    },
  ];

  const handleAdd = () => {
    const newData = {
      key: count,
      name: "Nhập vào giá trị thuộc tính",
      html_color: "",
      is_custom: false,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleCheckboxChange = (key, checked) => {
    const newData = dataSource.map((item) =>
      item.key === key ? { ...item, is_custom: checked } : item
    );
    setDataSource(newData);
  };

  const handleColorChange = (key, color) => {
    const newData = dataSource.map((item) =>
      item.key === key ? { ...item, html_color: color } : item
    );
    setDataSource(newData);
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const { onSubmitModal } = useAttributeSubmit();

  const displayTypeArray = [
    { id: "radio", name: "attribute_radio", defaultChecked: true },
    { id: "pills", name: "attribute_pills", defaultChecked: false },
    { id: "select", name: "attribute_select", defaultChecked: false },
    { id: "color", name: "attribute_color", defaultChecked: false },
    { id: "multi", name: "attribute_multi", defaultChecked: false },
  ];
  const variantsCreationArray = [
    { id: "always", name: "attribute_always", defaultChecked: true },
    { id: "dynamic", name: "attribute_dynamic", defaultChecked: false },
    { id: "no_variant", name: "no_variant", defaultChecked: false },
  ];

  const resetData = () => {
    setAttributeName("");
    setDisplayType("radio");
    setVariantsCreation("always");
    setDataSource([]);
  };

  return (
    <>
      {/* Add Category */}
      <div className="modal fade" id="add-variant-attribute">
        <div className="modal-dialog modal-lg modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("add_new_attribute")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => resetData()}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      {t("add_attribute_name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={attributeName}
                      onChange={(e) => setAttributeName(e.target.value)}
                    />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    className="mb-3"
                  >
                    <div>
                      <label className="form-label">
                        {t("attribute_display_type")}
                      </label>
                      {displayTypeArray.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="displayType1"
                              id="displayType1"
                              checked={displayType === item.id}
                              onChange={() => setDisplayType(item.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="displayType1"
                            >
                              {t(item.name)}
                            </label>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                    <div style={{ marginRight: "20%" }}>
                      <label className="form-label">
                        {t("attribute_variants_creation_mode")}
                      </label>
                      {variantsCreationArray.map((item, index) => (
                        <React.Fragment key={index}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="variantsCreation"
                              id="flexRadioDefault2"
                              checked={variantsCreation === item.id}
                              onChange={() => setVariantsCreation(item.id)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault2"
                            >
                              {t(item.name)}
                            </label>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>

                  <div>
                    <Table
                      components={components}
                      rowClassName={() => "editable-row"}
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      size="small"
                      pagination={{
                        pageSize: 5,
                        total: dataSource.length,
                        showSizeChanger: false,
                      }}
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      onClick={() => resetData()}
                    >
                      {t("cancel")}
                    </Link>
                    <button onClick={handleAdd} className="btn btn-submit me-2">
                      {t("add_attribute_value")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-submit"
                      onClick={(e) => {
                        onSubmitModal(
                          null,
                          attributeName,
                          displayType,
                          variantsCreation,
                          dataSource
                        );
                        resetData();

                        e.preventDefault();
                      }}
                      data-bs-dismiss="modal"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </>
  );
};

EditableRow.propTypes = {
  index: PropTypes.number,
};
EditableCell.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool,
  children: PropTypes.node,
  dataIndex: PropTypes.string,
  record: PropTypes.object,
  handleSave: PropTypes.func,
};
export default AddVariantAttribute;
