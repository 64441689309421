import React, {  useEffect  , useState } from "react";
import { Tabs } from "antd";
import PropTypes from 'prop-types';
import useMultipleAsync from "../../core/hooks/useMultipleAsync";
import POSService from "../../core/services/POSService";
import ConfirmModal from "../components/modal/ConfirmModal";
import { useTranslation } from "react-i18next";


const initialItems = [
  {
    label: "Invoice 1",
    key: "1",
  },
];
const CustomTab = ({ setProductList,setOrderDetail, setLoadingOrderDetail, posId}) => {
  const { t } = useTranslation();

  CustomTab.propTypes = {
    setProductList: PropTypes.func.isRequired,
    setOrderDetail: PropTypes.func.isRequired,
    setLoadingOrderDetail: PropTypes.func.isRequired,
    posId:PropTypes.string.isRequired,
  };

  const { data, loading } = useMultipleAsync([
    ()=>POSService.getOrderBySession(posId),
  ]);

  const [activeKey, setActiveKey] = useState(initialItems[0].key);
  const [items, setItems] = useState(initialItems);
  const [openModal, setOpenModal] = useState(false)
  const [keyDelete, setKeyDelete] = useState(0)

  useEffect(()=>{
    console.log("order: ", data)
    if (data.length > 0 && data[0]?.records){
      const temp = data[0].records.map(invoice => ({
        key: invoice.id,
        label: `${t("invoice")} ${invoice.id}`
      }));
      setActiveKey(temp[0]?.key);
      onChange(temp[0]?.key)
      
      setItems(temp)
    }
  },[loading])

  const onChange = async (newActiveKey) => {
    setActiveKey(newActiveKey);
    setLoadingOrderDetail(true)
    const orderDetail = await POSService.getOrderDetail(newActiveKey)
    if (orderDetail.length > 0 && orderDetail[0]?.lines && orderDetail[0].lines?.length >= 0){
      const { lines, ...rest } = orderDetail[0];
      setProductList(lines);
      setOrderDetail(rest)
      console.log("detail: ", orderDetail)
      }
    setLoadingOrderDetail(false)
  };

  const add = async () => {
    const bodyNewOrder = {
      session_id:1,
      amount_tax:0,
      amount_total:0,
      amount_paid:0,
      amount_return:0,
    }
    const res = await POSService.newOrder(bodyNewOrder)
    const newActiveKey = res[0]
    const newPanes = [...items];
    await onChange(newActiveKey)
    newPanes.push({
      label: `Invoice ${newActiveKey}`,
      key: newActiveKey,
    });
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const remove = async (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    await POSService.deleteOrder(targetKey)
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };
  const onEdit = (targetKey, action) => {
    if (action === "add") {
      add();
    } else {
      setOpenModal(true)
      setKeyDelete(targetKey)
      // remove(targetKey);
    }
  };

  const confirmModal = () =>{
    remove(keyDelete)
    setOpenModal(false)
  }
  return (
    <>
      <Tabs
        type="editable-card"
        onChange={onChange}
        activeKey={activeKey}
        onEdit={onEdit}
        items={items}
      />

      <ConfirmModal
        title={"Are you sure delete this invoice ?"}
        content={"Are you sure delete this invoice ?"}
        onOkFunc={confirmModal}
        onCancelFunc={() => setOpenModal(false)}
        open={openModal}
        textCancel={"Cancel"}
        textOk={"Delete"}
      />
    </>
  );
};
export default CustomTab;
