import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import useBrandSubmit from "../../hooks/useBrandSubmit";
import { useTranslation } from "react-i18next";

const EditBrand = ({ data }) => {
  const { t } = useTranslation();
  const [brandName, setBrandName] = useState();
  const { onSubmitModal } = useBrandSubmit();
  useEffect(() => {
    setBrandName(data?.name);
  }, [data]);
  return (
    <div>
      {/* Edit Brand */}
      <div className="modal fade" id="edit-brand-list">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("edit_brand_title")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body new-employee-field">
                  <form>
                    <div className="mb-3">
                      <label className="form-label">
                        {t("add_brand_name")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={data?.name}
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        {t("cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-submit"
                        onClick={(e) => {
                          onSubmitModal(data?.id, brandName), e.preventDefault();
                        }}
                        data-bs-dismiss="modal"
                      >
                        {t("submit")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Brand */}
    </div>
  );
};
EditBrand.defaultProps = {
  data: [],
};
EditBrand.propTypes = {
  data: PropTypes.array,
};
export default EditBrand;
