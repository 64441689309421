import requests from "./httpService";
import { createPath, searchReadPath, callPath, updatePath } from "./endpoint";

const BrandService = {
  getAll: async () => {
    const jsonData = {
      model: "product.brand",
      fields: [],
      domain: [],
    };
    return requests.post(searchReadPath, jsonData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  addOne: async (body) => {
    return requests.post(createPath, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  editOne: async (body) => {
    return requests.post(updatePath, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  deleteOne: async (body) => {
    return requests.post(callPath, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default BrandService;
