import React from "react";
import PropTypes from "prop-types";

const TaxPDF = ({ tax, amount, base, total }) => {
  return (
    <table width="100%" className="mb-10">
      <thead>
        <tr className="p-1">
          <th className="font-bold text-end">Tax</th>
          <th className="font-bold text-end">Amount</th>
          <th className="font-bold text-end">Base</th>
          <th className="font-bold text-end">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr className="h-10">
          <td className="text-end">{tax}%</td>
          <td className="text-end">{amount}</td>
          <td className="text-end">{base}</td>
          <td className="text-end">{total}</td>
        </tr>
      </tbody>
    </table>
  );
};

TaxPDF.propTypes = {
  tax: PropTypes.string,
  amount: PropTypes.number,
  base: PropTypes.number,
  total: PropTypes.number,
};
export default TaxPDF;
