export function formatPrice(price, currency) {
  let formattedPrice = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: currency
  }).format(price);

  if (currency === 'VND') {
    formattedPrice = formattedPrice.replace('₫', 'VND');
  }

  return formattedPrice;
}

export function formatQuantity(quantity) {
 return new Intl.NumberFormat('vi-VN').format(quantity);
}

export const truncateString = (str, maxLength = 20) => {
 if (str.length > maxLength) {
   return str.slice(0, maxLength) + "...";
 }
 return str;
};