/* eslint-disable react/prop-types */
import React from "react";

const InputField = ({
   type,
   name,
   register,
   readonly,
   placeHolder,
   required,
}) => {
   return (
      <input
         {...register(name)}
         type={type}
         readOnly={readonly}
         placeholder={placeHolder}
         required={required}
         className="bg-[#fff] border-[1px] border-solid border-[#E4E4E7] p-2 rounded-lg h-fit"
      />
   );
};

export default InputField;
