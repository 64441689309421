import PropTypes from "prop-types";
import React from "react";

const badgeTypes = {
  paid: {
    text: "Paid",
    color: "bg-green-500",
  },
  "not-paid": {
    text: "Not Paid",
    color: "bg-red-500",
  },
};

const Badge = ({ type }) => {
  const { text, color } = badgeTypes[type] || badgeTypes.paid;

  return (
    <div className="absolute -top-1 -left-1 w-40 h-40 overflow-hidden bg-transparent">
      <div
        className={`absolute z-[11] uppercase text-center transform -rotate-45 w-64 h-12 ${color} text-white font-bold flex items-center justify-center shadow-md border rounded-lg left-[-5rem] top-4`}
      >
        {text}
      </div>
    </div>
  );
};

Badge.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  type: PropTypes.oneOf(Object.keys(badgeTypes)),
};

Badge.defaultProps = {
  type: "paid",
};

export default Badge;
