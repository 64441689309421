import PropTypes from "prop-types";

const PaymentMethodPDF = ({list}) => {
    return (
        <>
            {list.map((record)=>(
                <div key={record.id} className="d-flex align-items-center justify-content-between">
                    <p>{record.name}</p>
                    <p>{record.price.toLocaleString()}</p>
                </div>
            ))}
        </>
    )
}

PaymentMethodPDF.propTypes = {
    list: PropTypes.array
  };

export default PaymentMethodPDF;