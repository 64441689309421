/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import ExcelService from "../../../core/services/ExcelService";
import { useTableContext } from "../listView/TableContext";
import { useMetaContext } from "../../../Router/MetaContext";

const UploadButton = ({ resModal }) => {
   const { setLoadingImport, selectedFile, setSelectedFile, setErrorData } =
      useMetaContext();
   const { setDataParse, idFile, setIdFile } = useTableContext();
   const handleFileChange = (event) => {
      const file = event.target.files[0];
      setIdFile(null);
      if (file) {
         const isExcelFile = (file) => {
            const acceptedTypes = [
               "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
               "application/vnd.ms-excel", // .xls
               "application/vnd.oasis.opendocument.spreadsheet", // .ods
               "application/vnd.apple.numbers", // .numbers (for Mac)
               "text/csv", // .csv (often considered as spreadsheet)
            ];
            return (
               acceptedTypes.includes(file.type) ||
               /\.(xlsx|xls|csv|ods|numbers)$/i.test(file.name)
            );
         };
         if (isExcelFile(file)) {
            setErrorData();
            setSelectedFile(file);
            setLoadingImport(true);
         } else {
            alert("Bạn chỉ có thể tải lên file XLSX!");
            event.target.value = null; // Reset file input
         }
      }
   };
   const FetchDataUpload = async (modal, files, id) => {
      if (id != null) {
         await ExcelService.uploadIdFile({ modal, files, id }).then(
            (responsive) => {
               setIdFile(responsive);
            }
         );
      } else {
         await ExcelService.uploadFile({ modal, files }).then((responsive) => {
            setIdFile(responsive);
         });
      }
   };
   const FetchDataPreview = async (id) => {
      await ExcelService.ParsePreview({ id }).then((responsive) => {
         setDataParse(responsive);
      });
   };
   useEffect(() => {
      if (resModal && selectedFile) {
         const formData = new FormData();
         formData.append("ufile", selectedFile, selectedFile.name);
         FetchDataUpload(resModal, formData, idFile);
      }
   }, [resModal, selectedFile]);
   useEffect(() => {
      if (idFile) {
         FetchDataPreview(idFile);
      }
   }, [idFile, selectedFile]);

   return (
      <div>
         <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="fileInput"
         />
         <label
            htmlFor="fileInput"
            className="px-[16px] text-left py-[8px] bg-transparent hover:bg-[#d3d1d1] w-full rounded-[8px] text-[#333] text-[14px] font-semibold leading-[24px] cursor-pointer"
         >
            Upload File
         </label>
      </div>
   );
};

export default UploadButton;
