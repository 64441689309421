import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { useState } from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import Select from "react-select";
// import ProductService from "../../services/ProductService";
import useProductSubmit from "../../hooks/useProductSubmit";
const DeleteAttributeProduct = ({ data }) => {
  const { t } = useTranslation();
  const [selectData, setSelectData] = useState(null);
  const [selectOptions, setSelectOptions] = useState();
  useEffect(() => {
    setSelectOptions(
      data?.data?.value_ids?.map((item) => {
        return {
          label: item.display_name,
          value: item.id,
        };
      })
    );
  }, [data]);
  const handleSelectChange = (selected) => {
    setSelectData(selected);
  };
  const { onDeleteAttributeValue, onDeleteAttribute } = useProductSubmit();

  return (
    <>
      <div className="modal fade" id="delete-attribute-product">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("delete_attribute")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setSelectData(null)}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      {t("choose_attribute_value")}
                    </label>
                    <Select
                      classNamePrefix="react-select"
                      options={selectOptions}
                      value={
                        selectData
                          ? {
                              label: selectData?.label,
                              value: selectData?.value,
                            }
                          : null
                      }
                      placeholder={t("choose")}
                      onChange={handleSelectChange}
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                      onClick={() => setSelectData(null)}
                    >
                      {t("cancel")}
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-submit me-2"
                      onClick={(e) => {
                        onDeleteAttribute(data?.data?.id),
                          e.preventDefault(),
                          setSelectData(null);
                      }}
                      data-bs-dismiss="modal"
                    >
                      {t("delete_all_product")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-submit"
                      disabled={!selectData}
                      onClick={(e) => {
                        onDeleteAttributeValue(
                          data?.id,
                          data?.data?.id,
                          data?.data?.attribute_id?.id,
                          selectData?.value,
                        ),
                          e.preventDefault(),
                          setSelectData(null);
                      }}
                      data-bs-dismiss="modal"
                    >
                      {t("delete_button")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
DeleteAttributeProduct.defaultProps = {
  data: [],
};
DeleteAttributeProduct.propTypes = {
  data: PropTypes.array,
};
export default DeleteAttributeProduct;
