export const all_routes = {
   dashboard: "/admin-dashboard",
   productlist: "/products",
   receiptlist: "/receipts",
   deliverylist: "/deliveries",
   internallist: "/internal",
   manufacturinglist: "/manufacturings",
   addproduct: "/add-product",
   addreceipt: "/add-receipt",
   receiptdetail: "/receipt-detail/:id",
   receiptedit: "/receipt-edit/:id",
   salesdashboard: "/dashboards",
   brandlist: "/product brands",
   units: "/units",
   variantyattributes: "/product variants",
   warranty: "/warranty",
   barcode: "/barcode",
   alerts: "/ui-alerts",
   accordion: "/ui-accordion",
   avatar: "/ui-avatar",
   badges: "/ui-badges",
   borders: "/ui-borders",
   buttons: "ui-buttons",
   buttonsgroup: "/ui-buttons-group",
   breadcrumb: "/ui-breadcrumb",
   cards: "/ui-cards",
   dropdowns: "/ui-dropdowns",
   colors: "/ui-colors",
   carousel: "/ui-carousel",
   navtabs: "/ui-nav-tabs",
   grid: "/ui-grid",
   images: "/ui-images",
   lightbox: "/ui-lightbox",
   media: "/ui-media",
   modals: "/ui-modals",
   offcanvas: "/ui-offcanvas",
   pagination: "/ui-pagination",
   typography: "/ui-typography",
   tooltip: "/ui-tooltips",
   draganddrop: "/ui-drag-drop",
   departmentgrid: "/department-grid",
   departmentlist: "/department-list",
   designation: "/designation",
   shift: "/shift",
   attendanceemployee: "/attendance-employee",
   clipboard: "/ui-clipboard",
   popover: "/ui-popovers",
   tablebasic: "/tables-basic",
   datatable: "/data-tables",
   basicinput: "/form-basic-inputs",
   checkboxradio: "/form-checkbox-radios",
   inputgroup: "/form-input-groups",
   gridgutters: "/form-grid-gutters",
   formselect: "/form-select",
   fileupload: "/form-fileupload",
   formmask: "/form-mask",
   formhorizontal: "/form-horizontal",
   formvertical: "/form-vertical",
   floatinglabel: "/form-floating-labels",
   formvalidation: "/form-validation",
   select2: "/form-select2",

   toasts: "/ui-toasts",
   video: "/ui-video",
   sweetalerts: "/ui-sweetalerts",
   spinner: "/ui-spinner",
   progress: "/ui-progress",
   placeholder: "/ui-placeholders",
   rating: "/ui-rating",
   texteditor: "/ui-text-editor",
   counter: "/ui-counter",
   scrollbar: "/ui-scrollbar",
   stickynote: "/ui-stickynote",
   timeline: "/ui-timeline",
   apexchart: "/chart-apex",
   chartjs: "/chart-js",
   rangeslider: "/ui-rangeslider",
   fontawesome: "/icon-fontawesome",
   feathericon: "/icon-feather",
   ionicicons: "/icon-ionic",
   materialicons: "/icon-material",
   pe7icons: "/icon-pe7",
   simpleline: "/icon-simpleline",
   themifyicons: "/icon-themify",
   iconweather: "/icon-weather",
   typicons: "/icon-typicon",
   flagicons: "/icon-flag",
   ribbon: "/ui-ribbon",

   chat: "/chat",
   videocall: "/video-call",
   audiocall: "/audio-call",
   email: "/email",
   callhistory: "/call-history",
   todo: "/todo",
   wizard: "/form-wizard",
   expiredproduct: "/expired-products",
   lowstock: "/low-stocks",
   categorylist: "/category-list",
   subcategories: "/sub-categories",
   productdetails: "/product-details/:id",
   variantdetails: "/variant-details/:id",
   editproduct: "/edit-product/:id",
   expenselist: "/expense-list",
   expensecategory: "/expense-category",
   calendar: "/calendar",
   variantattributes: "/variant-attributes",
   qrcode: "/qrcode",
   purchaselist: "/purchase-list",
   purchaseorderreport: "/purchase-order-report",
   purchasereturn: "/purchase-returns",
   appearance: "/appearance",
   socialauthendication: "/social-authentication",
   languagesettings: "/language-settings",
   invoicesettings: "/invoice-settings",
   printersettings: "/printer-settings",
   possettings: "/pos-settings",
   customfields: "/custom-fields",
   emailsettings: "/email-settings",
   smssettings: "/sms-gateway",
   otpsettings: "/otp-settings",
   gdbrsettings: "/gdpr-settings",
   paymentgateway: "/payment-gateway-settings",
   banksettingslist: "/bank-settings-list",
   banksettingsgrid: "/bank-settings-grid",
   taxrates: "/tax-rates",
   currencysettings: "/currency-settings",
   storagesettings: "/storage-settings",
   banipaddress: "/ban-ip-address",
   generalsettings: "/general-settings",
   securitysettings: "/security-settings",
   notification: "/notification",
   connectedapps: "/connected-apps",
   systemsettings: "/system-settings",
   companysettings: "/company-settings",
   localizationsettings: "/localization-settings",
   prefixes: "/prefixes",
   preference: "/preference",
   saleslist: "/orders",
   salescreate: "/sales-order",

   salesdetail: "/sales-detail/:id",
   pointofsalessettings: "/point-of-sales-settings",
   transationsettings: "/transaction-settings",
   paymentmethodsettings: "/payment-method-settings",
   productsettings: "/product-settings",
   /* ----- Invoice ----- */
   invoicereport: "/invoice-report",
   invoicedetail: "/invoice-detail/:id",
   invoiceedit: "/invoice-edit/:id",
   invoiceadd: "/invoice-add",

   /* ----- Search ----- */
   globalsearch: "/global-search/:filter/:searchStr",

   /* ----- Deliveries ----- */
   deliveryreport: "/delivery-report",
   deliverydetail: "/delivery-detail/:id",
   deliveryedit: "/delivery-edit/:id",
   deliveryadd: "/delivery-add",

   /* ----- internal ----- */
   internaldetail: "/internal-detail/:id",
   internaledit: "/internal-edit/:id",
   internaladd: "/internal-add",

   salesreturn: "/sales-returns",
   quotationlist: "/quotation-list",
   pos: "/pos/:posId",
   possession: "/pos-session",
   tableDesign: "/table-designer",
   attendanceadmin: "attendance-admin",
   payslip: "/payslip",
   holidays: "/holidays",
   customers: "/customers",
   suppliers: "/vendors",
   store: "/companies",
   managestock: "/manage-stocks",
   stockadjustment: "/stock-adjustment",
   stocktransfer: "/stock-transfer",
   salesreport: "/sales-report",
   purchasereport: "/purchase-report",
   inventoryreport: "/inventory-report",
   supplierreport: "/supplier-report",
   customerreport: "/customer-report",
   expensereport: "/expense-report",
   incomereport: "/income-report",
   taxreport: "/tax-report",
   profitloss: "/profit-loss-report",
   notes: "/notes",
   filemanager: "/file-manager",
   profile: "/profile",
   signin: "/signin",
   signintwo: "/signin-2",
   signinthree: "/signin-3",
   register: "/register",
   registerTwo: "/register-2",
   registerThree: "/register-3",
   forgotPassword: "/forgot-password",
   forgotPasswordTwo: "/forgot-password-2",
   forgotPasswordThree: "/forgot-password-3",
   resetpassword: "/reset-password",
   resetpasswordTwo: "/reset-password-2",
   resetpasswordThree: "/reset-password-3",
   emailverification: "/email-verification",
   emailverificationTwo: "/email-verification-2",
   emailverificationThree: "/email-verification-3",
   twostepverification: "/two-step-verification",
   twostepverificationTwo: "/two-step-verification-2",
   twostepverificationThree: "/two-step-verification-3",
   lockscreen: "/lock-screen",
   error404: "/error-404",
   error500: "/error-500",
   blankpage: "/blank-page",
   comingsoon: "/coming-soon",
   undermaintenance: "/under-maintenance",
   users: "/users",
   rolespermission: "/roles-permissions",
   permissions: "/permissions",
   editpermissions: "/permissions-edit/:id",
   deleteaccount: "/delete-account",
   employeegrid: "/employees-grid",
   employees: "/employees",
   addemployee: "/add-employee",
   editemployee: "/edit-employee",
   leavesadmin: "leaves-admin",
   leavesemployee: "/leaves-employee",
   leavestype: "/leave-types",
   wareHouses: "/warehouse",
   coupons: "/coupons",
   payrollList: "/payroll-list",
   treeViews: ":feature",
};
