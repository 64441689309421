export const invoicereportdata = [
  {
    id: 1,
    invoiceno: "INV001",
    customer: "Thomas",
    duedate: "19 Jan 2023",
    amount: "$1000",
    paid: "$1000",
    amountdue: "$0.00",
    status: "Paid",
  },
  {
    id: 2,
    invoiceno: "INV002",
    customer: "Rose",
    duedate: "25 Jan 2023",
    amount: "$1500",
    paid: "$0.00",
    amountdue: "$1500",
    status: "Unpaid",
  },
  {
    id: 3,
    invoiceno: "INV003",
    customer: "Benjamin",
    duedate: "05 Feb 2023",
    amount: "$1800",
    paid: "$1800",
    amountdue: "$0.00",
    status: "Paid",
  },
  {
    id: 4,
    invoiceno: "INV004",
    customer: "Kaitlin",
    duedate: "15 Feb 2023",
    amount: "$2000",
    paid: "$1000",
    amountdue: "$1000",
    status: "Overdue",
  },
  {
    id: 5,
    invoiceno: "INV005",
    customer: "Lilly",
    duedate: "18 Mar 2023",
    amount: "$800",
    paid: "$800",
    amountdue: "$0.00",
    status: "Paid",
  },
  {
    id: 6,
    invoiceno: "INV006",
    customer: "Freda",
    duedate: "24 Mar 2023",
    amount: "$750",
    paid: "$0.00",
    amountdue: "$750",
    status: "Unpaid",
  },
  {
    id: 7,
    invoiceno: "INV007",
    customer: "Alwin",
    duedate: "12 Apr 2023",
    amount: "$1300",
    paid: "$1300",
    amountdue: "$0.00",
    status: "Paid",
  },
  {
    id: 8,
    invoiceno: "INV008",
    customer: "Maybelle",
    duedate: "24 Apr 2023",
    amount: "$1100",
    paid: "$1100",
    amountdue: "$0.00",
    status: "Paid",
  },
  {
    id: 9,
    invoiceno: "INV009",
    customer: "Ellen",
    duedate: "03 May 2023",
    amount: "$2300",
    paid: "$2300",
    amountdue: "$0.00",
    status: "Paid",
  },
  {
    id: 10,
    invoiceno: "INV010",
    customer: "Grace",
    duedate: "29 May 2023",
    amount: "$1700",
    paid: "$1700",
    amountdue: "$0.00",
    status: "Paid",
  },
];
