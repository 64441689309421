import { Button } from "antd";
import React, { useEffect, useState } from "react";
import POSService from "../../core/services/POSService";
import { formatPrice } from "../../core/utils/formatter";
import { MoreVertical } from "react-feather";
import { useNavigate } from "react-router-dom";
import alertify from "alertifyjs";

function PosSession() {
  const [listPOS, setListPOS] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const [posListData] = await Promise.all([POSService.getListPOS()]);
      if (
        posListData?.length > 0 &&
        posListData?.records &&
        posListData?.records?.length > 0
      ) {
        setListPOS(posListData.records);
      }
      console.log("posListData: ", posListData);
      console.log("listPOS: ", listPOS);
     
    };

    fetchData();
  }, []);
  const navigate = useNavigate();

  const handleNavigatePOS = (posId) =>{
    navigate(`/pos/${posId}`);
  }

  const handleCreateNewSession = () => {
    alertify.success("Tạo phiên mới");
  }
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="tabs_container">
            <div className="tab_content active" data-tab="all">
              <div className="scrollable-container">
                <div
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                  className="row"
                >
                  {listPOS?.length > 0 &&
                    listPOS.map((pos) => (
                      <div
                        key={pos.id}
                        className="col-sm-4 col-md-6 col-lg-6 col-xl-6"
                      >
                        <div
                          className="product-info default-cover card"
                          style={{ padding: "16px" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              marginBottom: "16px",
                            }}
                          >
                            <div>
                              <div
                                style={{ fontWeight: "bold", fontSize: "20px" }}
                              >
                                {pos?.name}
                              </div>
                              {/* <div>To Close</div> */}
                            </div>

                            <div style={{ cursor: "pointer" }}>
                              <MoreVertical />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <Button 
                              onClick={() => pos.pos_session_state ? handleNavigatePOS(pos.id): handleCreateNewSession()}
                              type="primary">
                                {pos.pos_session_state
                                  ? "Tiếp tục bán"
                                  : "Phiên mới"}
                              </Button>
                            </div>

                            <div style={{display:"flex", width:"40%", justifyContent:"space-between", alignItems:"center"}}>
                              <div>
                                <div>Đóng</div>
                                <div>Số dư</div>
                              </div>

                              <div>
                                <div>{pos?.last_session_closing_date}</div>
                                <div>
                                  {formatPrice(
                                    pos?.number_of_rescue_session,
                                    pos.currency_id.display_name
                                  )}
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PosSession;
