// import qs from "qs";
import requests from "./httpService";
// import Cookies from "js-cookie";
import {
   authTokenPath,
   profilePath,
   updatePath,
   uploadFilePath,
   callPath,
   createPath,
   resetPasswordPath,
} from "./endpoint";
import qs from "qs";

const UserService = {
   login: async (body) => {
      const encodedData = qs.stringify({
         username: body.email,
         password: body.password,
         grant_type: process.env.REACT_APP_GRANT_TYPE,
         client_id: process.env.REACT_APP_CLIENT_ID,
         client_secret: process.env.REACT_APP_CLIENT_SECRET,
      });
      return requests.post(authTokenPath, encodedData, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },
   profile: async () => {
      return requests.get(profilePath, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   updateProfile: async (body) => {
      return requests.post(updatePath, body, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
   setImage: async (body, queryParams) => {
      const query = new URLSearchParams(queryParams).toString();
      return requests.post(uploadFilePath + "?" + query, body, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },

   getAll: async () => {
      const jsonData = {
         model: "res.users",
         method: "web_search_read",
         kwargs: {
            domain: [],
            specification: {
               name: {},
               email_formatted: {},
               id: {},
               login: {},
               lang: {},
               login_date: {},
               company_id: { fields: { display_name: {} } },
               phone: {},
               email: {},
               groups_id: { fields: { name: {}, display_name: {} } },
               image_256: {},
               complete_name: {},
               state: {},
               im_status: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   createUser: async (body) => {
      return requests.post(createPath, body, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },

   updateUser: async (body) => {
      return requests.post(updatePath, body, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },

   archiveUser: async (userId) => {
      const jsonData = {
         model: "res.users",
         method: "action_archive",
         ids: userId,
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   removeRoleUser: async (roleId, userId) => {
      const jsonData = {
         model: "res.groups",
         method: "web_save",
         ids: [roleId],
         kwargs: {
            // 3 : remove
            vals: { users: [[3, userId]] },
            specification: {},
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   addRoleUser: async (roleId, userId) => {
      const jsonData = {
         model: "res.groups",
         method: "web_save",
         ids: [roleId],
         kwargs: {
            // 4 : add
            vals: { users: [[4, userId]] },
            specification: {},
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   forgotPassword: async (body) => {
      const encodedData = {
         login: body.email,
      };
      return requests.post(resetPasswordPath, encodedData, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },
};

export default UserService;
