import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
   RefreshCcw,
   RotateCw,
   ShoppingCart,
} from "feather-icons-react/build/IconComponents";
import {
   // ArrowLeft,
   Check,
   CheckCircle,
   Edit,
   MoreVertical,
   Trash2,
   UserPlus,
} from "react-feather";
// import AsyncSelect from "react-select/async";
import ReactLoading from "react-loading";
import Select from "react-select";
import { Accordion, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomTab from "./tab";
import QuantityProduct from "./quantityProduct";
import POSService from "../../core/services/POSService";
import { formatPrice } from "../../core/utils/formatter";
import { useTranslation } from "react-i18next";
import alertify from "alertifyjs";
import RestaurantTable from "./restaurant";
// import { Button } from "antd";

// const orderListTemp = [
//   {
//     id: "PT0005",
//     full_product_name: "Red Nike Laser",
//     price_subtotal_incl: 2000,
//     qty: 2,
//     product_img: "assets/img/products/pos-product-16.png",
//   },
//   {
//     id: "PT0006",
//     full_product_name: "Iphone 14 64GB",
//     price_subtotal_incl: 5000,
//     qty: 4,
//     product_img: "assets/img/products/pos-product-01.png",
//   },
// ];

const Pos = () => {
   const [orderList, setOrderList] = useState([]);
   const { t } = useTranslation();
   const { posId } = useParams();
   const navigate = useNavigate();

   if (!posId) {
      navigate(`/pos-session`);
   }

   const gst = [
      { value: "5", label: "VAT 5%" },
      { value: "8", label: "VAT 8%" },
      { value: "10", label: "VAT 10%" },
      { value: "15", label: "VAT 15%" },
      { value: "20", label: "VAT 20%" },
      { value: "25", label: "VAT 25%" },
      { value: "30", label: "VAT 30%" },
   ];

   const shipping = [
      { value: "15", label: "15" },
      { value: "20", label: "20" },
      { value: "25", label: "25" },
      { value: "30", label: "30" },
   ];

   const discount = [
      { value: "10", label: "10%" },
      { value: "15", label: "15%" },
      { value: "20", label: "20%" },
      { value: "25", label: "25%" },
      { value: "30", label: "30%" },
   ];

   const tax = [
      { value: "exclusive", label: "Exclusive" },
      { value: "inclusive", label: "Inclusive" },
   ];
   const discounttype = [
      { value: "percentage", label: "Percentage" },
      { value: "earlyPaymentDiscounts", label: "Early payment discounts" },
   ];
   const units = [
      { value: "kilogram", label: "Kilogram" },
      { value: "grams", label: "Grams" },
   ];

   const renderTooltip = (props) => (
      <Tooltip id="pdf-tooltip" {...props}>
         Pdf
      </Tooltip>
   );
   const renderExcelTooltip = (props) => (
      <Tooltip id="excel-tooltip" {...props}>
         Excel
      </Tooltip>
   );
   const renderPrinterTooltip = (props) => (
      <Tooltip id="printer-tooltip" {...props}>
         Printer
      </Tooltip>
   );

   const settings = {
      dots: false,
      autoplay: false,
      slidesToShow: 5,
      margin: 0,
      speed: 500,
      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 5,
            },
         },
         {
            breakpoint: 800,
            settings: {
               slidesToShow: 5,
            },
         },
         {
            breakpoint: 776,
            settings: {
               slidesToShow: 2,
            },
         },
         {
            breakpoint: 567,
            settings: {
               slidesToShow: 1,
            },
         },
      ],
   };
   const MySwal = withReactContent(Swal);

   const showConfirmationAlert = () => {
      MySwal.fire({
         title: "Are you sure?",
         text: "You won't be able to revert this!",
         showCancelButton: true,
         confirmButtonColor: "#00ff00",
         confirmButtonText: "Yes, delete it!",
         cancelButtonColor: "#ff0000",
         cancelButtonText: "Cancel",
      }).then((result) => {
         if (result.isConfirmed) {
            // POSService.deleteProductInOrder()
            MySwal.fire({
               title: "Deleted!",
               text: "Your file has been deleted.",
               className: "btn btn-success",
               confirmButtonText: "OK",
               customClass: {
                  confirmButton: "btn btn-success",
               },
            });
         } else {
            MySwal.close();
         }
      });
   };

   const showConfirmationAlertDeleteProductOrder = (posID) => {
      MySwal.fire({
         title: "Are you sure?",
         text: "You won't be able to revert this!",
         showCancelButton: true,
         confirmButtonColor: "#00ff00",
         confirmButtonText: "Yes, delete it!",
         cancelButtonColor: "#ff0000",
         cancelButtonText: "Cancel",
      }).then(async (result) => {
         if (result.isConfirmed) {
            const res = await POSService.deleteProductInOrder(posID);
            setOrderList((prev) => prev.filter((item) => item.id !== posID));
            console.log("res : ", res);

            MySwal.fire({
               title: "Deleted!",
               text: "Your file has been deleted.",
               className: "btn btn-success",
               confirmButtonText: "OK",
               customClass: {
                  confirmButton: "btn btn-success",
               },
            });
         } else {
            MySwal.close();
         }
      });
   };

   const [productList, setProductList] = useState([]);
   const [orderDetail, setOrderDetail] = useState({});
   const [categories, setCategories] = useState([]);
   const [cateFilter, setCateFilter] = useState(0);
   const [loadingOrderDetail, setLoadingOrderDetail] = useState(true);
   const [loadingAddProduct, setLoadingAddProduct] = useState(-1);
   const [loadingProduct, setLoadingProduct] = useState(true);

   useEffect(() => {
      const fetchData = async () => {
         const [categoriesData, productsData] = await Promise.all([
            POSService.getCategories(),
            POSService.getProducts(cateFilter),
         ]);

         if (categoriesData?.length > 0) {
            setCategories(categoriesData);
         }

         if (productsData?.records) {
            setProductList(productsData.records);
         }
         setLoadingProduct(false);
      };

      fetchData();
   }, [cateFilter]);

   const handleSelectCate = (cateID) => {
      setLoadingProduct(true);
      setCateFilter(cateID);
   };

   const [options, setOptions] = useState([]);
   const [selectedOption, setSelectedOption] = useState(null);

   const loadOptionsCustomers = async (inputValue = "") => {
      try {
         const result = await POSService.getCustomers(inputValue);
         const a = result.map((customer) => ({
            value: customer.id,
            label: customer.name,
         }));
         return a;
      } catch (error) {
         console.error("Error loading customers:", error);
      }
   };

   useEffect(() => {
      const fetchOptions = async () => {
         try {
            const fetchedOptions = await loadOptionsCustomers();
            setOptions(fetchedOptions);

            if (orderDetail?.partner_id?.id) {
               const defaultValue = fetchedOptions.find(
                  (option) => option.value === orderDetail.partner_id.id
               );
               if (defaultValue) {
                  // setSelectedOption([defaultValue.value,defaultValue.label]);
               }
            }
         } catch (error) {
            console.error("Error fetching options:", error);
         }
      };

      fetchOptions();
   }, [orderDetail]);

   const handleChange = async (event) => {
      const selectedValue = event.target.value;
      const selectedOptionObj = options.find(
         (option) => option.value === parseInt(selectedValue)
      );

      console.log("selectedOptionObj: ", selectedOptionObj);
      if (selectedOptionObj) {
         setSelectedOption(selectedOptionObj);
         if (selectedOptionObj.value !== "") {
            await updateOrder(parseInt(selectedOptionObj.value));
         }
      }
   };

   const updateOrder = async (partnerId) => {
      try {
         await POSService.updateOrder(orderDetail.id, {
            partner_id: partnerId,
         });
         alertify.success("Cập nhật khách hàng thành công");
      } catch (error) {
         console.error("Error updating order:", error);
         alertify.error(error?.message || "Cập nhật khách hàng thất bại");
      }
   };

   const [showAddProduct, setShowAddProduct] = useState(false);
   const [productAmountChange, setProductAmountChange] = useState(1);
   const [productAdd, setProductAdd] = useState(null);

   const handleAmountChange = (e) => {
      const value = e.target.value;

      if (
         value !== "" &&
         Number.isInteger(Number(value)) &&
         Number(value) > 0
      ) {
         setProductAmountChange(Number(value));
      } else {
         console.log("Giá trị không hợp lệ");
      }
   };

   const handleCloseAddProduct = () => {
      setProductAdd(null);
      setLoadingAddProduct(-1);
      setShowAddProduct(false);
   };

   const handleAddProductFinal = async () => {
      try {
         // setLoadingAddProduct(productAdd.id);
         await POSService.updateProductInOrder([
            {
               order_id: orderDetail.id,
               product_id: productAdd.id,
               qty: 1,
               full_product_name: productAdd.name,
               price_subtotal_incl: productAdd.list_price,
               price_subtotal: productAdd.list_price,
            },
         ]);
         const orderDetailData = await POSService.getOrderDetail(
            orderDetail.id
         );
         if (
            orderDetailData.length > 0 &&
            orderDetailData[0]?.lines &&
            orderDetailData[0].lines?.length >= 0
         ) {
            const { lines, ...rest } = orderDetailData[0];
            setOrderList(lines);
            setOrderDetail(rest);
            alertify.success(t("add_success"));
         }
      } catch (err) {
         alertify.error(err?.message || "Thêm sản phẩm thất bại");
      } finally {
         setLoadingAddProduct(-1);
         setShowAddProduct(false);
         setProductAmountChange(1);
      }
   };
   const handleAddProduct = async (product) => {
      alertify.set("notifier", "position", "top-right");
      setShowAddProduct(true);
      setLoadingAddProduct(product.id);
      setProductAdd(product);
   };

   const [paymentMethod, setPaymentMethod] = useState([]);
   const [methodSelect, setMethodSelect] = useState(null);
   const [showCompletePayment, setShowCompletePayment] = useState(false);
   const [showChooseMethod, setShowChooseMethod] = useState(false);

   const [showPrintReceipt, setShowPrintReceipt] = useState(false);

   const handleOpenPrintReceipt = () => setShowPrintReceipt(true);
   const handleClosePrintReceipt = () => setShowPrintReceipt(false);

   const handleCloseCompletePayment = () => setShowCompletePayment(false);

   const handleCloseChooseMethod = () => setShowChooseMethod(false);

   const handleChooseMethod = (method) => {
      setMethodSelect(method);
   };

   // const handleOpenChooseMethod = () => {
   //   setShowChooseMethod(true);
   // };

   const handlePayment = async () => {
      setShowChooseMethod(true);
      const paymentMethod = await POSService.getPaymentMethod(1);
      setPaymentMethod(paymentMethod);
      console.log("payment method : ", paymentMethod);
   };

   const handleCompletePayment = async () => {
      if (!methodSelect) {
         alertify.error("Hãy chọn phương thức thanh toán");
         return;
      }
      if (methodSelect.id != 1) {
         alertify.error("Phương thức thanh toán chưa được hỗ trợ");
         return;
      }

      setShowCompletePayment(true);
      try {
         const total = orderList.reduce(
            (sum, item) => sum + item.price_subtotal,
            0
         );
         const data = {
            session_id: 1,
            payment_method_id: 1,
            pos_order_id: orderDetail.id,
            amount: total,
            is_change: true,
         };
         await POSService.makePayment(data);
         await POSService.updateOrder(orderDetail.id, {
            state: "paid",
         });
         alertify.success("Thanh toán thành công");
      } catch (err) {
         console.log(err);
         alertify.error(err?.message || "Thanh toán thất bại");
      } finally {
         setShowChooseMethod(false);
      }
   };

   const clearAllProductsOrder = async () => {
      setLoadingOrderDetail(true);
      try {
         const productIds = orderList.map((order) => order.id).join(",");
         console.log("productIds: ", productIds);
         await POSService.deleteProductInOrder(productIds);
         setOrderList([]);
         alertify.success("Xóa tất cả thành công");
      } catch (error) {
         alertify.error("Xóa tất cả thất bại");
      } finally {
         setLoadingOrderDetail(false);
      }
   };

   return (
      <div>
         <div className="page-wrapper pos-pg-wrapper ms-0">
            <div className="content pos-design p-0">
               {/* <div className="btn-row col-md-2 col-lg-1 align-items-center ">
            <Button onClick={()=>navigate("/pos-session")} type="primary" icon={<ArrowLeft />} >Trở về</Button>
          </div> */}
               <div className="btn-row d-sm-flex align-items-center justify-content-between">
                  <div className="col-md-12 col-lg-8 align-items-center ">
                     <CustomTab
                        key={productList?.id}
                        setProductList={setOrderList}
                        setOrderDetail={setOrderDetail}
                        setLoadingOrderDetail={setLoadingOrderDetail}
                        posId={posId}
                     />
                  </div>
                  <div className="col-md-12 col-lg-4 align-items-center justify-content-center">
                     <div className="btn-row  px-0 d-sm-flex align-items-center justify-content-center">
                        <Link
                           to="#"
                           className="btn btn-secondary mb-xs-3"
                           data-bs-toggle="modal"
                           data-bs-target="#orders"
                        >
                           <span className="me-1 d-flex align-items-center">
                              <ShoppingCart className="feather-16" />
                           </span>
                           {t("view_orders")}
                        </Link>
                        <Link to="#" className="btn btn-info">
                           <span className="me-1 d-flex align-items-center">
                              <RotateCw className="feather-16" />
                           </span>
                           {t("reset")}
                        </Link>
                        <Link
                           to="#"
                           className="btn btn-primary"
                           data-bs-toggle="modal"
                           data-bs-target="#recents"
                        >
                           <span className="me-1 d-flex align-items-center">
                              <RefreshCcw className="feather-16" />
                           </span>
                           {t("transaction")}
                        </Link>
                     </div>
                  </div>
               </div>
               <div className="row align-items-start pos-wrapper">
                  <div className="col-md-12 col-lg-8">
                     {productList.length == -1 ? (
                        <>
                           <RestaurantTable />
                        </>
                     ) : (
                        <div className="pos-categories tabs_wrapper">
                           {/* <div className="accordion-header" id="headingOne">
                    <div
                      className="accordion-button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseCate"
                      aria-controls="collapseCate"
                    >
                      <p>
                        <h5>{t("category")}</h5>
                        <p>{t("category_below")}</p>
                      </p>
                    </div>
                  </div> */}
                           <Accordion>
                              <Accordion.Item eventKey="0">
                                 <Accordion.Header>
                                    <p>
                                       <h5>{t("category")}</h5>
                                       <p>{t("category_below")}</p>
                                    </p>
                                 </Accordion.Header>
                                 <Accordion.Body
                                    style={{ padding: "32px", height: "120px" }}
                                 >
                                    <Slider
                                       {...settings}
                                       className="tabs owl-carousel pos-category"
                                    >
                                       {categories.map((category) => (
                                          <div
                                             key={category.id}
                                             id="all"
                                             className={`pos-slick-item ${
                                                cateFilter === category.id
                                                   ? "selected"
                                                   : ""
                                             }`}
                                             onClick={() =>
                                                handleSelectCate(category.id)
                                             }
                                             style={{
                                                padding: "10px",
                                                margin: "5px",
                                                border:
                                                   cateFilter === category.id
                                                      ? "2px solid yellow"
                                                      : "2px solid transparent",
                                                borderRadius: "8px",
                                                boxShadow:
                                                   cateFilter === category.id
                                                      ? "0 0 10px rgba(255, 255, 0, 0.6)"
                                                      : "none",
                                                transition:
                                                   "border 0.3s, box-shadow 0.3s",
                                             }}
                                          >
                                             <h6 style={{ margin: 0 }}>
                                                {" "}
                                                {/* Remove margin for tighter spacing */}
                                                <Link to="#">
                                                   {category.name}
                                                </Link>
                                             </h6>
                                             {/* <span>80 Items</span> */}
                                          </div>
                                       ))}
                                    </Slider>
                                 </Accordion.Body>
                              </Accordion.Item>
                           </Accordion>

                           <div
                              className="pos-products"
                              style={{ marginTop: "8px" }}
                           >
                              <div className="d-flex align-items-center justify-content-between">
                                 <h5 className="mb-3">{t("Products")}</h5>
                              </div>
                              <div className="tabs_container">
                                 <div
                                    className="tab_content active"
                                    data-tab="all"
                                 >
                                    <div className="scrollable-container">
                                       <div
                                          style={{
                                             width: "100%",
                                             justifyContent: "flex-start",
                                          }}
                                          className="row"
                                       >
                                          {/* use product api */}
                                          {loadingProduct ? (
                                             <div
                                                style={{
                                                   display: "flex",
                                                   width: "100%",
                                                   height: "100%",
                                                   justifyContent: "center",
                                                   alignItems: "center",
                                                }}
                                             >
                                                <ReactLoading
                                                   type="spin"
                                                   color="black"
                                                />
                                             </div>
                                          ) : (
                                             <>
                                                {productList.length > 0 ? (
                                                   productList.map(
                                                      (product) => (
                                                         <div
                                                            key={product.id}
                                                            className="col-sm-2 col-md-6 col-lg-3 col-xl-3"
                                                         >
                                                            <div
                                                               onClick={() =>
                                                                  handleAddProduct(
                                                                     product
                                                                  )
                                                               }
                                                               className="product-info default-cover card"
                                                            >
                                                               {loadingAddProduct ==
                                                               product.id ? (
                                                                  <div
                                                                     style={{
                                                                        width: "100%",
                                                                        height:
                                                                           "100%",
                                                                        display:
                                                                           "flex",
                                                                        justifyContent:
                                                                           "center",
                                                                        alignItems:
                                                                           "center",
                                                                     }}
                                                                  >
                                                                     <ReactLoading
                                                                        width={
                                                                           "54%"
                                                                        }
                                                                        height={
                                                                           "100%"
                                                                        }
                                                                        type="spin"
                                                                        color="black"
                                                                     />
                                                                  </div>
                                                               ) : (
                                                                  <>
                                                                     <Link
                                                                        to="#"
                                                                        className="img-bg"
                                                                     >
                                                                        <img
                                                                           style={{
                                                                              width: "100%",
                                                                              height:
                                                                                 "100%",
                                                                              objectFit:
                                                                                 "contain",
                                                                           }}
                                                                           src={
                                                                              product?.image_128
                                                                                 ? `${product?.image_128}`
                                                                                 : "https://zippo.vn/wp-content/uploads/2022/02/no-image.jpeg"
                                                                           }
                                                                           alt="Products"
                                                                        />
                                                                        <span>
                                                                           <Check className="feather-16" />
                                                                        </span>
                                                                     </Link>
                                                                  </>
                                                               )}
                                                               <h6 className="cat-name">
                                                                  <Link to="#">
                                                                     {
                                                                        product
                                                                           ?.categ_id
                                                                           ?.display_name
                                                                     }
                                                                  </Link>
                                                               </h6>
                                                               <h6 className="product-name">
                                                                  <Link to="#">
                                                                     {
                                                                        product?.name
                                                                     }
                                                                  </Link>
                                                               </h6>
                                                               <div className="d-flex align-items-center justify-content-between price">
                                                                  <span>
                                                                     {
                                                                        product.qty_available
                                                                     }{" "}
                                                                     PCS
                                                                  </span>
                                                                  <p>
                                                                     {product?.list_price?.toLocaleString()}{" "}
                                                                     VND
                                                                  </p>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      )
                                                   )
                                                ) : (
                                                   <div
                                                      style={{
                                                         display: "flex",
                                                         width: "100%",
                                                         height: "100%",
                                                         justifyContent:
                                                            "center",
                                                         alignItems: "center",
                                                      }}
                                                   >
                                                      Không có sản phẩm
                                                   </div>
                                                )}
                                             </>
                                          )}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
                  <div className="col-md-12 col-lg-4 ps-0">
                     <aside className="product-order-list">
                        <div className="head d-flex align-items-center justify-content-between w-100">
                           <div className="">
                              <h5>{t("order_list")}</h5>
                              <span>{t("transaction")} ID : #65565</span>
                           </div>
                           <div className="">
                              <Link className="confirm-text" to="#">
                                 <Trash2 className="feather-16 text-danger me-1" />
                              </Link>
                              <Link to="#" className="text-default">
                                 <MoreVertical className="feather-16" />
                              </Link>
                           </div>
                        </div>
                        <div
                           style={{ display: "none" }}
                           className="customer-info block-section"
                        >
                           <h6>{t("detail_customer")}</h6>
                           <div className="input-block d-flex align-items-center">
                              <div className="flex-grow-1">
                                 <select
                                    value={
                                       selectedOption
                                          ? selectedOption?.value
                                          : ""
                                    }
                                    onChange={handleChange}
                                 >
                                    <option value="">Select an option</option>
                                    {options.map((option) => (
                                       <option
                                          key={option?.value}
                                          value={option?.value}
                                       >
                                          {option?.label}
                                       </option>
                                    ))}
                                 </select>
                              </div>
                              <Link
                                 to="#"
                                 className="btn btn-primary btn-icon"
                                 data-bs-toggle="modal"
                                 data-bs-target="#create"
                              >
                                 <UserPlus className="feather-16" />
                              </Link>
                           </div>
                        </div>
                        <div className="product-added block-section">
                           {loadingOrderDetail ? (
                              <div
                                 style={{
                                    display: "flex",
                                    justifyContent: "center",
                                 }}
                              >
                                 <ReactLoading type="spin" color="black" />
                              </div>
                           ) : (
                              <>
                                 <div className="head-text d-flex align-items-center justify-content-between">
                                    <h6 className="d-flex align-items-center mb-0">
                                       {t("product_added")}
                                       <span className="count">
                                          {orderList.length}
                                       </span>
                                    </h6>
                                    <Link
                                       to="#"
                                       className="d-flex align-items-center text-danger"
                                       onClick={clearAllProductsOrder}
                                    >
                                       <span className="me-1">
                                          <i
                                             data-feather="x"
                                             className="feather-16"
                                          />
                                       </span>
                                       Clear all
                                    </Link>
                                 </div>
                                 <div className="product-wrap">
                                    {orderList.length > 0 &&
                                       orderList.map((product) => (
                                          <div
                                             key={product.id}
                                             className="product-list d-flex align-items-center justify-content-between"
                                             // style={{flexDirection:"column"}}
                                          >
                                             <div
                                                className="d-flex align-items-center product-info"
                                                data-bs-toggle="modal"
                                                data-bs-target="#products"
                                             >
                                                <Link to="#" className="img-bg">
                                                   <ImageWithBasePath
                                                      src={
                                                         product?.product_id
                                                            ?.image_128
                                                            ? `${product?.product_id?.image_128}`
                                                            : "https://zippo.vn/wp-content/uploads/2022/02/no-image.jpeg"
                                                      }
                                                      alt="Products"
                                                   />
                                                </Link>
                                                <div className="info">
                                                   <span>ID {product.id}</span>
                                                   <h6>
                                                      <Link to="#">
                                                         {
                                                            product.full_product_name
                                                         }
                                                      </Link>
                                                   </h6>
                                                   <p>
                                                      {formatPrice(
                                                         product.price_subtotal_incl,
                                                         product?.currency_id
                                                            ?.display_name ||
                                                            "VND"
                                                      )}
                                                   </p>
                                                </div>
                                             </div>
                                             <div className="d-flex flex-column align-items-center justify-content-between gap-3">
                                                <QuantityProduct
                                                   key={product.id}
                                                   product={product}
                                                   order_detail={orderDetail}
                                                />
                                                <div className="d-flex align-items-center action">
                                                   <Link
                                                      className="btn-icon edit-icon me-2"
                                                      to="#"
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#edit-product"
                                                   >
                                                      <Edit className="feather-14" />
                                                   </Link>
                                                   <Link
                                                      onClick={() =>
                                                         showConfirmationAlertDeleteProductOrder(
                                                            product.id
                                                         )
                                                      }
                                                      className="btn-icon delete-icon confirm-text"
                                                      to="#"
                                                   >
                                                      <Trash2 className="feather-14" />
                                                   </Link>
                                                </div>
                                             </div>
                                          </div>
                                       ))}
                                    <Accordion>
                                       <Accordion.Header>
                                          <div
                                             style={{ width: "92%" }}
                                             className="selling-info"
                                          >
                                             <div className="row">
                                                <div className="col-12 col-sm-4">
                                                   <div className="input-block">
                                                      <label>Thuế</label>
                                                      <Select
                                                         classNamePrefix="react-select"
                                                         options={gst}
                                                         placeholder="GST 5%"
                                                      />
                                                   </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                   <div className="input-block">
                                                      <label>
                                                         Phí giao hàng
                                                      </label>
                                                      <Select
                                                         classNamePrefix="react-select"
                                                         options={shipping}
                                                         placeholder="15"
                                                      />
                                                   </div>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                   <div className="input-block">
                                                      <label>Giảm giá</label>
                                                      <Select
                                                         classNamePrefix="react-select"
                                                         options={discount}
                                                         placeholder="10%"
                                                      />
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </Accordion.Header>
                                       <Accordion.Body>
                                          <div
                                             style={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                             }}
                                          >
                                             <div
                                                style={{ width: "100%" }}
                                                className="block-section"
                                             >
                                                <div className="order-total">
                                                   <table className="table table-responsive table-borderless">
                                                      <tbody>
                                                         <tr>
                                                            <td>Tạm Tính</td>
                                                            <td className="text-end">
                                                               $60,454
                                                            </td>
                                                         </tr>
                                                         <tr>
                                                            <td>
                                                               Thuế (VAT 8%)
                                                            </td>
                                                            <td className="text-end">
                                                               $40.21
                                                            </td>
                                                         </tr>
                                                         <tr>
                                                            <td>
                                                               Phí giao hàng
                                                            </td>
                                                            <td className="text-end">
                                                               $40.21
                                                            </td>
                                                         </tr>
                                                         <tr>
                                                            <td className="danger">
                                                               Giảm giá (10%)
                                                            </td>
                                                            <td className="danger text-end">
                                                               $15.21
                                                            </td>
                                                         </tr>
                                                         <tr>
                                                            <td>Tổng</td>
                                                            <td className="text-end">
                                                               $64,024.5
                                                            </td>
                                                         </tr>
                                                      </tbody>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>
                                       </Accordion.Body>
                                    </Accordion>
                                 </div>
                              </>
                           )}
                        </div>

                        {/* move */}
                        <div>
                           {/* <div className="block-section payment-method">
                    <h6>{t("payment_method")}</h6>
                    <div className="row d-flex align-items-center justify-content-center methods">
                      <div className="col-md-6 col-lg-4 item">
                        <div className="default-cover">
                          <Link to="#">
                            <ImageWithBasePath
                              src="assets/img/icons/cash-pay.svg"
                              alt="Payment Method"
                            />
                            <span>Cash</span>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 item">
                        <div className="default-cover">
                          <Link to="#">
                            <ImageWithBasePath
                              src="assets/img/icons/credit-card.svg"
                              alt="Payment Method"
                            />
                            <span>Debit Card</span>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 item">
                        <div className="default-cover">
                          <Link to="#">
                            <ImageWithBasePath
                              src="assets/img/icons/qr-scan.svg"
                              alt="Payment Method"
                            />
                            <span>Scan</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                           <div className="d-grid btn-block">
                              <Link className="btn btn-secondary" to="#">
                                 Tạm tính :{" "}
                                 {orderList?.length > 0 &&
                                 orderDetail?.currency_id?.display_name
                                    ? formatPrice(
                                         orderList?.reduce(
                                            (sum, item) =>
                                               sum + item.price_subtotal,
                                            0
                                         ),
                                         orderDetail?.currency_id?.display_name
                                      )
                                    : "0"}
                              </Link>
                           </div>
                           <div className="btn-row d-sm-flex align-items-center justify-content-between">
                              {/* <Link
                      to="#"
                      className="btn btn-info btn-icon flex-fill"
                      data-bs-toggle="modal"
                      data-bs-target="#hold-order"
                    >
                      <span className="me-1 d-flex align-items-center">
                        <i data-feather="pause" className="feather-16" />
                      </span>
                      Hold
                    </Link>
                    <Link to="#" className="btn btn-danger btn-icon flex-fill">
                      <span className="me-1 d-flex align-items-center">
                        <i data-feather="trash-2" className="feather-16" />
                      </span>
                      Void
                    </Link> */}
                              <Link
                                 to="#"
                                 className="btn btn-success btn-icon flex-fill"
                                 // data-bs-toggle="modal"
                                 // data-bs-target="#payment-method-modal"
                                 onClick={() => handlePayment()}
                              >
                                 <span className="me-1 d-flex align-items-center">
                                    <i
                                       data-feather="credit-card"
                                       className="feather-16"
                                    />
                                 </span>
                                 Thanh Toán
                              </Link>
                           </div>
                        </div>
                        {/* fixed */}
                     </aside>
                  </div>
               </div>
            </div>
         </div>
         {/* payment method modal */}
         <Modal
            show={showChooseMethod}
            onHide={handleCloseChooseMethod}
            centered
            id="payment-method-modal"
            aria-labelledby="payment-method-modal"
         >
            <Modal.Body>
               <div
                  style={{
                     display: "flex",
                     gap: "20px",
                     flexDirection: "column",
                  }}
               >
                  <div
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderBottom: "1px solid #f3f6f9",
                     }}
                  >
                     <div style={{ width: "100%" }} className="block-section">
                        <div className="order-total">
                           <table className="table table-responsive table-borderless">
                              <tbody>
                                 <tr>
                                    <td>Tạm tính</td>
                                    <td className="text-end">$60,454</td>
                                 </tr>
                                 <tr>
                                    <td>Thuế (VAT 8%)</td>
                                    <td className="text-end">$40.21</td>
                                 </tr>
                                 <tr>
                                    <td>Phí giao hàng</td>
                                    <td className="text-end">$40.21</td>
                                 </tr>
                                 <tr>
                                    <td className="danger">Giảm giá (10%)</td>
                                    <td className="danger text-end">$15.21</td>
                                 </tr>
                                 <tr>
                                    <td>Tổng</td>
                                    <td className="text-end">$64,024.5</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>

                  <div className="payment-method-custom block-section payment-method">
                     <h6 className="text-center">{t("payment_method")}</h6>
                     <div className="row d-flex align-items-center justify-content-center methods">
                        {paymentMethod?.length > 0 &&
                           paymentMethod.map((method) => (
                              <div
                                 key={method.id}
                                 className="col-md-6 col-lg-4 item"
                              >
                                 <div
                                    onClick={() => handleChooseMethod(method)}
                                    className={`default-cover default-cover-custom ${
                                       methodSelect &&
                                       methodSelect?.id === method.id &&
                                       "active"
                                    }`}
                                 >
                                    <Link to="#">
                                       {/* <ImageWithBasePath
                                src="assets/img/icons/cash-pay.svg"
                                alt="Payment Method"
                              /> */}
                                       <span>{method?.name}</span>
                                    </Link>
                                 </div>
                              </div>
                           ))}
                     </div>
                  </div>
                  <div className="modal-footer d-sm-flex justify-content-between">
                     <Link
                        onClick={() => handleCompletePayment()}
                        to="#"
                        className="btn btn-secondary flex-fill"
                     >
                        Complete Payment
                     </Link>
                  </div>
               </div>
            </Modal.Body>
         </Modal>

         {/* add product amount */}
         <Modal
            show={showAddProduct}
            onHide={handleCloseAddProduct}
            centered
            id="addproduct-pos"
            aria-labelledby="addproduct-pos"
         >
            <Modal.Body>
               <div
                  style={{
                     display: "flex",
                     gap: "20px",
                     flexDirection: "column",
                  }}
               >
                  <div className="payment-method-custom block-section payment-method">
                     <h6 className="text-center">Thêm số lượng sản phẩm</h6>
                     <div className="row d-flex align-items-center justify-content-center methods">
                        <div className="search-input">
                           <input
                              type="number"
                              placeholder="Thêm số lượng sản phẩm"
                              className="form-control form-control-sm "
                              step="1"
                              value={productAmountChange}
                              onChange={handleAmountChange}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="modal-footer d-sm-flex justify-content-between">
                     <Link
                        onClick={() => handleAddProductFinal()}
                        to="#"
                        className="btn btn-secondary flex-fill"
                     >
                        Thêm số lượng sản phẩm
                     </Link>
                  </div>
               </div>
            </Modal.Body>
         </Modal>
         {/* Payment Completed */}
         <Modal
            show={showCompletePayment}
            onHide={handleCloseCompletePayment}
            centered
            id="payment-completed"
            aria-labelledby="payment-completed"
         >
            <Modal.Body className="text-center">
               <form>
                  <div className="icon-head">
                     <Link to="#">
                        <CheckCircle className="feather-40" />
                     </Link>
                  </div>
                  <h4>Payment Completed</h4>
                  <p className="mb-0">
                     Do you want to Print Receipt for the Completed Order
                  </p>
                  <div className="modal-footer d-sm-flex justify-content-between">
                     <button
                        type="button"
                        className="btn btn-primary flex-fill me-1"
                        onClick={handleOpenPrintReceipt}
                     >
                        Print Receipt
                     </button>
                     <Link to="#" className="btn btn-secondary flex-fill">
                        Next Order
                     </Link>
                  </div>
               </form>
            </Modal.Body>
         </Modal>
         {/* /Payment Completed */}
         {/* Print Receipt */}
         <Modal
            show={showPrintReceipt}
            onHide={handleClosePrintReceipt}
            centered
            id="print-receipt"
            aria-labelledby="print-receipt"
         >
            <Modal.Body>
               <button
                  type="button"
                  className="close p-0"
                  onClick={handleClosePrintReceipt}
                  aria-label="Close"
               >
                  <span aria-hidden="true">×</span>
               </button>
               <div className="icon-head text-center">
                  <Link to="#">
                     <ImageWithBasePath
                        src="assets/img/logo.svg"
                        width={100}
                        height={30}
                        alt="Receipt Logo"
                     />
                  </Link>
               </div>
               <div className="text-center info text-center">
                  <h6>Atom Solutions.</h6>
                  <p className="mb-0">Phone Number: 1900 3353</p>
                  <p className="mb-0">
                     Email:{" "}
                     <Link to="mailto:hotro247@atomsolution.vn">
                        hotro247@atomsolution.vn
                     </Link>
                  </p>
               </div>
               <div className="tax-invoice">
                  <h6 className="text-center">Tax Invoice</h6>
                  <div className="row">
                     <div className="col-sm-12 col-md-6">
                        <div className="invoice-user-name">
                           <span>Name: </span>
                           <span>{selectedOption}</span>
                        </div>
                        <div className="invoice-user-name">
                           <span>Invoice No: </span>
                           <span>CS132453</span>
                        </div>
                     </div>
                     <div className="col-sm-12 col-md-6">
                        <div className="invoice-user-name">
                           <span>Customer Id: </span>
                           <span>#LL93784</span>
                        </div>
                        <div className="invoice-user-name">
                           <span>Date: </span>
                           <span>01.07.2022</span>
                        </div>
                     </div>
                  </div>
               </div>
               <table className="table-borderless w-100 table-fit">
                  <thead>
                     <tr>
                        <th># Item</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th className="text-end">Total</th>
                     </tr>
                  </thead>
                  <tbody>
                     {orderList.length > 0 &&
                        orderList?.map((order) => (
                           <tr key={order.id}>
                              <td>1. {order?.full_product_name}</td>
                              <td>
                                 {formatPrice(
                                    order.price_subtotal_incl,
                                    order?.currency_id?.display_name || "VND"
                                 )}
                              </td>
                              <td>{order?.qty}</td>
                              <td className="text-end">
                                 {formatPrice(
                                    order.price_subtotal_incl * order?.qty,
                                    order?.currency_id?.display_name || "VND"
                                 )}
                              </td>
                           </tr>
                        ))}

                     <tr>
                        <td colSpan={4}>
                           <table className="table-borderless w-100 table-fit">
                              <tbody>
                                 {/* <tr>
                            <td>Sub Total :</td>
                            <td className="text-end">$700.00</td>
                          </tr>
                          <tr>
                            <td>Discount :</td>
                            <td className="text-end">-$50.00</td>
                          </tr>
                          <tr>
                            <td>Shipping :</td>
                            <td className="text-end">0.00</td>
                          </tr>
                          <tr>
                            <td>Tax (5%) :</td>
                            <td className="text-end">$5.00</td>
                          </tr>
                          <tr>
                            <td>Total Bill :</td>
                            <td className="text-end">$655.00</td>
                          </tr>
                          <tr>
                            <td>Due :</td>
                            <td className="text-end">$0.00</td>
                          </tr> */}
                                 <tr>
                                    <td>Total Payable :</td>
                                    <td className="text-end">
                                       {formatPrice(
                                          orderList.reduce(
                                             (sum, item) =>
                                                sum + item.price_subtotal,
                                             0
                                          ),
                                          orderList[0]?.currency_id
                                             ?.display_name || "VND"
                                       )}
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </td>
                     </tr>
                  </tbody>
               </table>
               <div className="text-center invoice-bar">
                  <p>
                     **VAT against this challan is payable through central
                     registration. Thank you for your business!
                  </p>
                  <Link to="#">
                     <ImageWithBasePath
                        src="assets/img/barcode/barcode-03.jpg"
                        alt="Barcode"
                     />
                  </Link>
                  <p>Sale 31</p>
                  <p>Thank You For Shopping With Us. Please Come Again</p>
                  <Link to="#" className="btn btn-primary">
                     Print Receipt
                  </Link>
               </div>
            </Modal.Body>
         </Modal>
         {/* /Print Receipt */}
         {/* Products */}
         <div
            className="modal fade modal-default pos-modal"
            id="products"
            aria-labelledby="products"
         >
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content">
                  <div className="modal-header p-4 d-flex align-items-center justify-content-between">
                     <div className="d-flex align-items-center">
                        <h5 className="me-4">{t("Products")}</h5>
                        <span className="badge bg-info d-inline-block mb-0">
                           Order ID : #666614
                        </span>
                     </div>
                     <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     >
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body p-4">
                     <form>
                        <div className="product-wrap">
                           {orderList.map((product) => (
                              <div
                                 key={product.id}
                                 className="product-list d-flex align-items-center justify-content-between"
                              >
                                 <div className="d-flex align-items-center flex-fill">
                                    <Link to="#" className="img-bg me-2">
                                       <ImageWithBasePath
                                          src={
                                             product?.product_id?.image_128
                                                ? `${product?.product_id?.image_128}`
                                                : "https://www.mobismea.com/upload/iblock/2a0/2f5hleoupzrnz9o3b8elnbv82hxfh4ld/No%20Product%20Image%20Available.png"
                                          }
                                          alt="Products"
                                       />
                                    </Link>
                                    <div className="info d-flex align-items-center justify-content-between flex-fill">
                                       <div>
                                          <span>ID {product.id}</span>
                                          <h6>
                                             <Link to="#">
                                                {product.full_product_name}
                                             </Link>
                                          </h6>
                                       </div>
                                       <p>
                                          {formatPrice(
                                             product.price_subtotal_incl,
                                             product?.currency_id
                                                ?.display_name || "VND"
                                          )}
                                       </p>
                                    </div>
                                 </div>
                              </div>
                           ))}
                        </div>
                        <div className="modal-footer d-sm-flex justify-content-end">
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Cancel
                           </button>
                           <Link to="#" className="btn btn-primary">
                              Submit
                           </Link>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         {/* /Products */}
         <div
            className="modal fade"
            id="create"
            tabIndex={-1}
            aria-labelledby="create"
            aria-hidden="true"
         >
            <div
               className="modal-dialog modal-lg modal-dialog-centered"
               role="document"
            >
               <div className="modal-content">
                  <div className="modal-header">
                     <h5 className="modal-title">Create</h5>
                     <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     >
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body">
                     <form>
                        <div className="row">
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks">
                                 <label>Customer Name</label>
                                 <input type="text" className="form-control" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks">
                                 <label>Email</label>
                                 <input type="email" className="form-control" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks">
                                 <label>Phone</label>
                                 <input type="text" className="form-control" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks">
                                 <label>Country</label>
                                 <input type="text" className="form-control" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks">
                                 <label>City</label>
                                 <input type="text" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks">
                                 <label>Address</label>
                                 <input type="text" />
                              </div>
                           </div>
                        </div>
                        <div className="modal-footer d-sm-flex justify-content-end">
                           <button
                              type="button"
                              className="btn btn-cancel"
                              data-bs-dismiss="modal"
                           >
                              Cancel
                           </button>
                           <Link to="#" className="btn btn-submit me-2">
                              Submit
                           </Link>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         {/* Hold */}
         <div
            className="modal fade modal-default pos-modal"
            id="hold-order"
            aria-labelledby="hold-order"
         >
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content">
                  <div className="modal-header p-4">
                     <h5>Hold order</h5>
                     <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     >
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body p-4">
                     <form>
                        <h2 className="text-center p-4">4500.00</h2>
                        <div className="input-block">
                           <label>Order Reference</label>
                           <input
                              className="form-control"
                              type="text"
                              defaultValue=""
                              placeholder=""
                           />
                        </div>
                        <p>
                           The current order will be set on hold. You can
                           retreive this order from the pending order button.
                           Providing a reference to it might help you to
                           identify the order more quickly.
                        </p>
                        <div className="modal-footer d-sm-flex justify-content-end">
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Cancel
                           </button>
                           <Link to="#" className="btn btn-primary">
                              Confirm
                           </Link>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         {/* /Hold */}
         {/* Edit Product */}
         <div
            className="modal fade modal-default pos-modal"
            id="edit-product"
            aria-labelledby="edit-product"
         >
            <div className="modal-dialog modal-dialog-centered">
               <div className="modal-content">
                  <div className="modal-header p-4">
                     <h5>Red Nike Laser</h5>
                     <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     >
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body p-4">
                     <form>
                        <div className="row">
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks add-product">
                                 <label>
                                    Product Name <span>*</span>
                                 </label>
                                 <input type="text" placeholder={45} />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks add-product">
                                 <label>
                                    Tax Type <span>*</span>
                                 </label>
                                 <Select
                                    classNamePrefix="react-select"
                                    options={tax}
                                    placeholder="Select Option"
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks add-product">
                                 <label>
                                    Tax <span>*</span>
                                 </label>
                                 <input type="text" placeholder="% 15" />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks add-product">
                                 <label>
                                    Discount Type <span>*</span>
                                 </label>
                                 <Select
                                    classNamePrefix="react-select"
                                    options={discounttype}
                                    placeholder="Select Option"
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks add-product">
                                 <label>
                                    Discount <span>*</span>
                                 </label>
                                 <input type="text" placeholder={15} />
                              </div>
                           </div>
                           <div className="col-lg-6 col-sm-12 col-12">
                              <div className="input-blocks add-product">
                                 <label>
                                    Sale Unit <span>*</span>
                                 </label>
                                 <Select
                                    classNamePrefix="react-select"
                                    options={units}
                                    placeholder="Select Option"
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="modal-footer d-sm-flex justify-content-end">
                           <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                           >
                              Cancel
                           </button>
                           <Link to="#" className="btn btn-primary">
                              Submit
                           </Link>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
         {/* /Edit Product */}
         {/* Recent Transactions */}
         <div
            className="modal fade pos-modal"
            id="recents"
            tabIndex={-1}
            aria-hidden="true"
         >
            <div
               className="modal-dialog modal-lg modal-dialog-centered"
               role="document"
            >
               <div className="modal-content">
                  <div className="modal-header p-4">
                     <h5 className="modal-title">Recent Transactions</h5>
                     <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     >
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body p-4">
                     <div className="tabs-sets">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                           <li className="nav-item" role="presentation">
                              <button
                                 className="nav-link active"
                                 id="purchase-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#purchase"
                                 type="button"
                                 aria-controls="purchase"
                                 aria-selected="true"
                                 role="tab"
                              >
                                 Purchase
                              </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button
                                 className="nav-link"
                                 id="payment-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#payment"
                                 type="button"
                                 aria-controls="payment"
                                 aria-selected="false"
                                 role="tab"
                              >
                                 Payment
                              </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button
                                 className="nav-link"
                                 id="return-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#return"
                                 type="button"
                                 aria-controls="return"
                                 aria-selected="false"
                                 role="tab"
                              >
                                 Return
                              </button>
                           </li>
                        </ul>
                        <div className="tab-content">
                           <div
                              className="tab-pane fade show active"
                              id="purchase"
                              role="tabpanel"
                              aria-labelledby="purchase-tab"
                           >
                              <div className="table-top">
                                 <div className="search-set">
                                    <div className="search-input">
                                       <input
                                          type="text"
                                          placeholder="Search"
                                          className="form-control form-control-sm formsearch"
                                       />
                                       <Link to className="btn btn-searchset">
                                          <i
                                             data-feather="search"
                                             className="feather-search"
                                          />
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="wordset">
                                    <ul>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderTooltip}
                                          >
                                             <Link>
                                                <ImageWithBasePath
                                                   src="assets/img/icons/pdf.svg"
                                                   alt="img"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderExcelTooltip}
                                          >
                                             <Link
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                             >
                                                <ImageWithBasePath
                                                   src="assets/img/icons/excel.svg"
                                                   alt="img"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderPrinterTooltip}
                                          >
                                             <Link
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                             >
                                                <i
                                                   data-feather="printer"
                                                   className="feather-printer"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="table-responsive">
                                 <table className="table datanew">
                                    <thead>
                                       <tr>
                                          <th>Date</th>
                                          <th>Reference</th>
                                          <th>Customer</th>
                                          <th>Amount </th>
                                          <th className="no-sort">Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0101</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0102</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0103</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0104</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0105</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0106</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0107</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div
                              className="tab-pane fade"
                              id="payment"
                              role="tabpanel"
                           >
                              <div className="table-top">
                                 <div className="search-set">
                                    <div className="search-input">
                                       <input
                                          type="text"
                                          placeholder="Search"
                                          className="form-control form-control-sm formsearch"
                                       />
                                       <Link to className="btn btn-searchset">
                                          <i
                                             data-feather="search"
                                             className="feather-search"
                                          />
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="wordset">
                                    <ul>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderTooltip}
                                          >
                                             <Link>
                                                <ImageWithBasePath
                                                   src="assets/img/icons/pdf.svg"
                                                   alt="img"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderExcelTooltip}
                                          >
                                             <Link
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                             >
                                                <ImageWithBasePath
                                                   src="assets/img/icons/excel.svg"
                                                   alt="img"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderPrinterTooltip}
                                          >
                                             <Link
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                             >
                                                <i
                                                   data-feather="printer"
                                                   className="feather-printer"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="table-responsive">
                                 <table className="table datanew">
                                    <thead>
                                       <tr>
                                          <th>Date</th>
                                          <th>Reference</th>
                                          <th>Customer</th>
                                          <th>Amount </th>
                                          <th className="no-sort">Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0101</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0102</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0103</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0104</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0105</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0106</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0107</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                           <div
                              className="tab-pane fade"
                              id="return"
                              role="tabpanel"
                           >
                              <div className="table-top">
                                 <div className="search-set">
                                    <div className="search-input">
                                       <input
                                          type="text"
                                          placeholder="Search"
                                          className="form-control form-control-sm formsearch"
                                       />
                                       <Link to className="btn btn-searchset">
                                          <i
                                             data-feather="search"
                                             className="feather-search"
                                          />
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="wordset">
                                    <ul>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderTooltip}
                                          >
                                             <Link>
                                                <ImageWithBasePath
                                                   src="assets/img/icons/pdf.svg"
                                                   alt="img"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderExcelTooltip}
                                          >
                                             <Link
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                             >
                                                <ImageWithBasePath
                                                   src="assets/img/icons/excel.svg"
                                                   alt="img"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                       <li>
                                          <OverlayTrigger
                                             placement="top"
                                             overlay={renderPrinterTooltip}
                                          >
                                             <Link
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                             >
                                                <i
                                                   data-feather="printer"
                                                   className="feather-printer"
                                                />
                                             </Link>
                                          </OverlayTrigger>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                              <div className="table-responsive">
                                 <table className="table datanew">
                                    <thead>
                                       <tr>
                                          <th>Date</th>
                                          <th>Reference</th>
                                          <th>Customer</th>
                                          <th>Amount </th>
                                          <th className="no-sort">Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0101</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0102</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0103</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0104</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0105</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0106</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                       <tr>
                                          <td>19 Jan 2023</td>
                                          <td>INV/SL0107</td>
                                          <td>Walk-in Customer</td>
                                          <td>$1500.00</td>
                                          <td className="action-table-data">
                                             <div className="edit-delete-action">
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="eye"
                                                      className="feather-eye"
                                                   />
                                                </Link>
                                                <Link
                                                   className="me-2 p-2"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="edit"
                                                      className="feather-edit"
                                                   />
                                                </Link>
                                                <Link
                                                   onClick={
                                                      showConfirmationAlert
                                                   }
                                                   className="p-2 confirm-text"
                                                   to="#"
                                                >
                                                   <i
                                                      data-feather="trash-2"
                                                      className="feather-trash-2"
                                                   />
                                                </Link>
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* /Recent Transactions */}

         {/* Recent Transactions */}
         <div
            className="modal fade pos-modal"
            id="orders"
            tabIndex={-1}
            aria-hidden="true"
         >
            <div
               className="modal-dialog modal-md modal-dialog-centered"
               role="document"
            >
               <div className="modal-content">
                  <div className="modal-header p-4">
                     <h5 className="modal-title">Orders</h5>
                     <button
                        type="button"
                        className="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                     >
                        <span aria-hidden="true">×</span>
                     </button>
                  </div>
                  <div className="modal-body p-4">
                     <div className="tabs-sets">
                        <ul className="nav nav-tabs" id="myTabs" role="tablist">
                           <li className="nav-item" role="presentation">
                              <button
                                 className="nav-link active"
                                 id="onhold-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#onhold"
                                 type="button"
                                 aria-controls="onhold"
                                 aria-selected="true"
                                 role="tab"
                              >
                                 Onhold
                              </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button
                                 className="nav-link"
                                 id="unpaid-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#unpaid"
                                 type="button"
                                 aria-controls="unpaid"
                                 aria-selected="false"
                                 role="tab"
                              >
                                 Unpaid
                              </button>
                           </li>
                           <li className="nav-item" role="presentation">
                              <button
                                 className="nav-link"
                                 id="paid-tab"
                                 data-bs-toggle="tab"
                                 data-bs-target="#paid"
                                 type="button"
                                 aria-controls="paid"
                                 aria-selected="false"
                                 role="tab"
                              >
                                 Paid
                              </button>
                           </li>
                        </ul>
                        <div className="tab-content">
                           <div
                              className="tab-pane fade show active"
                              id="onhold"
                              role="tabpanel"
                              aria-labelledby="onhold-tab"
                           >
                              <div className="table-top">
                                 <div className="search-set w-100 search-order">
                                    <div className="search-input w-100">
                                       <input
                                          type="text"
                                          placeholder="Search"
                                          className="form-control form-control-sm formsearch w-100"
                                       />
                                       <Link to className="btn btn-searchset">
                                          <i
                                             data-feather="search"
                                             className="feather-search"
                                          />
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                              <div className="order-body">
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-secondary d-inline-block mb-4">
                                       Order ID : #666659
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Botsford
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $900
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      29-08-2023 13:39:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-sm-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          {t("Products")}
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-secondary d-inline-block mb-4">
                                       Order ID : #666660
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Smith
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $15000
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      30-08-2023 15:59:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          {t("Products")}
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="default-cover p-4">
                                    <span className="badge bg-secondary d-inline-block mb-4">
                                       Order ID : #666661
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      John David
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $2000
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      01-09-2023 13:15:00
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4 mb-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div
                              className="tab-pane fade"
                              id="unpaid"
                              role="tabpanel"
                           >
                              <div className="table-top">
                                 <div className="search-set w-100 search-order">
                                    <div className="search-input w-100">
                                       <input
                                          type="text"
                                          placeholder="Search"
                                          className="form-control form-control-sm formsearch w-100"
                                       />
                                       <Link to className="btn btn-searchset">
                                          <i
                                             data-feather="search"
                                             className="feather-search"
                                          />
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                              <div className="order-body">
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-info d-inline-block mb-4">
                                       Order ID : #666662
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Anastasia
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $2500
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      10-09-2023 17:15:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-info d-inline-block mb-4">
                                       Order ID : #666663
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Lucia
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $1500
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      11-09-2023 14:50:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-info d-inline-block mb-4">
                                       Order ID : #666664
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Diego
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $30000
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      12-09-2023 17:22:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4 mb-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div
                              className="tab-pane fade"
                              id="paid"
                              role="tabpanel"
                           >
                              <div className="table-top">
                                 <div className="search-set w-100 search-order">
                                    <div className="search-input w-100">
                                       <input
                                          type="text"
                                          placeholder="Search"
                                          className="form-control form-control-sm formsearch w-100"
                                       />
                                       <Link to className="btn btn-searchset">
                                          <i
                                             data-feather="search"
                                             className="feather-search"
                                          />
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                              <div className="order-body">
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-primary d-inline-block mb-4">
                                       Order ID : #666665
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Hugo
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $5000
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      13-09-2023 19:39:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-primary d-inline-block mb-4">
                                       Order ID : #666666
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      Antonio
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $7000
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      15-09-2023 18:39:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                                 <div className="default-cover p-4 mb-4">
                                    <span className="badge bg-primary d-inline-block mb-4">
                                       Order ID : #666667
                                    </span>
                                    <div className="row">
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr className="mb-3">
                                                   <td>Cashier</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      admin
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Customer</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      MacQuoid
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                       <div className="col-sm-12 col-md-6 record mb-3">
                                          <table>
                                             <tbody>
                                                <tr>
                                                   <td>Total</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      $7050
                                                   </td>
                                                </tr>
                                                <tr>
                                                   <td>Date</td>
                                                   <td className="colon">:</td>
                                                   <td className="text">
                                                      17-09-2023 19:39:11
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                    <p className="p-4 mb-4">
                                       Customer need to recheck the product once
                                    </p>
                                    <div className="btn-row d-flex align-items-center justify-content-between">
                                       <Link
                                          to="#"
                                          className="btn btn-info btn-icon flex-fill"
                                       >
                                          Open
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-danger btn-icon flex-fill"
                                       >
                                          Products
                                       </Link>
                                       <Link
                                          to="#"
                                          className="btn btn-success btn-icon flex-fill"
                                       >
                                          Print
                                       </Link>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {/* /Recent Transactions */}
      </div>
   );
};

export default Pos;
