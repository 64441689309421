const FooterPDF = () => {

  return (
      <footer className="footer text-center pt-5">
      <div>-----------------------------</div>
        <div>Cảm ơn quý khách</div>
        <div>Chúc quý khách có một ngày tốt đẹp</div>
        <div>Hẹn gặp lại</div>
      </footer>
  );
}

export default FooterPDF;