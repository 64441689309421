import ProductService from "../services/ProductService";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import alertify from "alertifyjs";
import { useTranslation } from "react-i18next";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
const useProductSubmit = (id, product_id) => {
   const { t } = useTranslation();
   let navigate = useNavigate();
   const [imageSrc, setImageSrc] = useState(false);
   const [imageUpload, setImageUpload] = useState(false);
   const [attributeValues, setAttributeValues] = useState([]);
   const [attributeLines, setAttributeLines] = useState([]);
   const [attributeRes, setAttributeRes] = useState([]);
   const [description, setDescription] = useState();
   const [selectValueOptions, setSelectValueOptions] = useState([]);
   const [selectUnits, setSelectUnits] = useState();
   const [loadingRes, setLoadingRes] = useState(false);
   const {
      register,
      setValue,
      getValues,
      handleSubmit,
      control,
      formState: { errors },
   } = useForm();

   const { isUpdate, setIsUpdate, setIsLoading } = useContext(SidebarContext);
   const generateDefaultCode = () => {
      return Math.random().toString(36).substring(2, 8).toLocaleUpperCase();
   };

   const handleAttributeChange = (index, selectedOption) => {
      const updatedAttributeArray = [...attributeLines];
      updatedAttributeArray[index] = [
         0,
         0,
         {
            attribute_id: selectedOption?.value,
            value_ids: [[6, 0, []]],
         },
      ];
      setAttributeLines(updatedAttributeArray);
   };

   const handleValueIdsChange = (index, selectedOptions) => {
      setSelectValueOptions(selectedOptions);
      const updatedAttributeArray = [...attributeLines];
      updatedAttributeArray[index][2].value_ids[0][2] = selectedOptions.map(
         (option) => option.value
      );
      setAttributeLines(updatedAttributeArray);
   };

   const onSubmit = (data) => {
      if (attributeLines.some((line) => line.length === 0)) {
         alertify.set("notifier", "position", "top-right");
         alertify.error(
            "Có thuộc tính nào đó chưa được chọn giá trị, hãy xóa hoặc chọn giá trị cho nó"
         );
         setIsLoading(false);
         return;
      }
      if (id && product_id) {
         setIsLoading(true);
         const productData = {
            domain: [["id", "=", id]],
            model: "product.template",
            values: {
               name: data?.name,
               sale_ok: data?.sale_ok,
               type: data?.type,
               list_price: parseInt(data?.list_price),
               categ_id: data?.categ_id || "",
               property_stock_inventory: data?.property_stock_inventory || "",
               standard_price: parseInt(data?.standard_price),
               default_code: data?.default_code || generateDefaultCode(),
               barcode: data?.barcode,
               brand_id: data?.brand_id || "",
               description: data?.description,
               description_pickingout: data?.description_pickingout,
               attribute_line_ids: attributeLines[0] ? attributeLines : [],
               uom_id: data?.uom_id,
               uom_po_id: data?.uom_po_id,
            },
         };
         ProductService.updateOne(productData)
            .then((res) => {
               if (data?.qty_available) {
                  const productData = {
                     model: "stock.change.product.qty",
                     values: {
                        product_tmpl_id: parseInt(res[0]),
                        product_id: product_id,
                        new_quantity: parseInt(data?.qty_available),
                     },
                  };
                  ProductService.createOnHandQty(productData)
                     .then((res) => {
                        const confirmData = {
                           model: "stock.change.product.qty",
                           method: "change_product_qty",
                           ids: res[0],
                        };
                        ProductService.confirmOnHandQty(confirmData)
                           .then(() => {})
                           .catch((err) => {
                              alertify.set("notifier", "position", "top-right");
                              alertify.error(
                                 err?.message || "Something went wrong"
                              );
                           });
                     })
                     .catch((err) => {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(err?.message || "Something went wrong");
                     });
               }
               if (imageUpload) {
                  const formData = new FormData();
                  formData.append("ufile", imageUpload);
                  const queryParams = {
                     model: "product.template",
                     id: res[0],
                     field: "image_256",
                  };
                  ProductService.setImage(formData, queryParams)
                     .then(() => {
                        setImageUpload(false);
                     })
                     .catch((err) => {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(err?.message || "Something went wrong");
                        console.log("🚀 ~ onImageUpload ~ err:", err);
                     });
               }
               alertify.set("notifier", "position", "top-right");
               alertify.success(t("product_success_update"));
               setIsLoading(false);
               navigate("/product-list");
               setIsUpdate(true);
            })
            .catch((err) => {
               alertify.set("notifier", "position", "top-right");
               alertify.error(err?.message || "Something went wrong");
               setIsLoading(false);
            });
      } else {
         setIsLoading(true);

         const productData = {
            model: "product.template",
            values: {
               name: data?.name,
               sale_ok: data?.sale_ok,
               type: data?.type,
               list_price: data?.list_price ? parseInt(data?.list_price) : 0,
               categ_id: data?.categ_id || "",
               property_stock_inventory: data?.property_stock_inventory || "",
               standard_price: data?.standard_price
                  ? parseInt(data?.standard_price)
                  : 0,
               default_code: data?.default_code || generateDefaultCode(),
               barcode: data?.barcode,
               brand_id: data?.brand_id || "",
               qty_available: data?.qty_available,
               description: data?.description,
               description_pickingout: data?.description_pickingout,
               attribute_line_ids: attributeLines,
               uom_id: data?.uom_id,
               uom_po_id: data?.uom_po_id,
            },
         };
         console.log("🚀 ~ onSubmit ~ productData:", productData);

         ProductService.addOne(productData)
            .then((res) => {
               const idCreate = res[0];
               if (data?.qty_available) {
                  ProductService.getProductsVariant(res[0])
                     .then((res) => {
                        const productData = {
                           model: "stock.change.product.qty",
                           values: {
                              product_tmpl_id: parseInt(idCreate),
                              product_id: res?.records[0]?.id,
                              new_quantity: parseInt(data?.qty_available),
                           },
                        };
                        ProductService.createOnHandQty(productData)
                           .then((res) => {
                              const confirmData = {
                                 model: "stock.change.product.qty",
                                 method: "change_product_qty",
                                 ids: res[0],
                              };
                              ProductService.confirmOnHandQty(confirmData)
                                 .then(() => {})
                                 .catch((err) => {
                                    alertify.set(
                                       "notifier",
                                       "position",
                                       "top-right"
                                    );
                                    alertify.error(
                                       err?.message || "Something went wrong"
                                    );
                                 });
                           })
                           .catch((err) => {
                              alertify.set("notifier", "position", "top-right");
                              alertify.error(
                                 err?.message || "Something went wrong"
                              );
                           });
                     })
                     .catch((err) => {
                        alertify.set("notifier", "position", "top-right");
                        alertify.error(err?.message || "Something went wrong");
                     });
               }
               if (imageUpload) {
                  const formData = new FormData();
                  formData.append("ufile", imageUpload);
                  const queryParams = {
                     model: "product.template",
                     id: res[0],
                     field: "image_256",
                  };
                  ProductService.setImage(formData, queryParams)
                     .then(() => {
                        setImageUpload(false);
                     })
                     .catch((err) => {
                        console.log("🚀 ~ onImageUpload ~ err:", err);
                     });
               }
               alertify.set("notifier", "position", "top-right");
               alertify.success(t("product_success_add"));
               setIsUpdate(true);
               setIsLoading(false);
               navigate("/product-list");
            })
            .catch((err) => {
               alertify.set("notifier", "position", "top-right");
               alertify.error(err?.message || "Something went wrong");
               setIsLoading(false);
            });
      }
   };

   const handleGetAttributeValues = (id) => {
      const attributeData = {
         model: "product.attribute.value",
         fields: ["id", "name"],
         limit: 10,
         offset: 0,
         domain: [["attribute_id", "=", id]],
      };
      ProductService.getAttributesValues(attributeData)
         .then((res) => {
            setAttributeValues(res);
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.message || "Something went wrong");
         });
   };

   const onUpdatePriority = (id, priority) => {
      const productData = {
         model: "product.template",
         domain: [["id", "=", `${id}`]],
         values: {
            priority: priority,
         },
      };
      ProductService.updateOne(productData)
         .then(() => {
            console.log("Priority updated");
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.message || "Something went wrong");
         });
   };

   const onUpdateExtraCost = (id, extraCost) => {
      const productData = {
         model: "product.template.attribute.value",
         domain: [["id", "=", `${id}`]],
         values: {
            price_extra: extraCost,
         },
      };
      ProductService.updateExtraPrice(productData)
         .then(() => {
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("product_success_update"));
            setIsUpdate(true);
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.message || "Something went wrong");
         });
   };

   const onDelete = (id) => {
      const productData = {
         model: "product.template",
         ids: id,
         method: "action_archive",
      };
      ProductService.delete(productData)
         .then(() => {
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("product_success_delete"));
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.detail || "Something went wrong");
         });
   };

   const onSubmitValueModal = (id, index, name) => {
      const attributeValueData = {
         model: "product.attribute.value",
         values: { attribute_id: id, name: name },
      };
      ProductService.addValueOne(attributeValueData)
         .then((res) => {
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("add_success"));
            setAttributeValues([
               ...attributeValues,
               { name: name, id: res[0] },
            ]);
            handleValueIdsChange(index, [
               ...selectValueOptions,
               { value: res[0], label: name },
            ]);
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.message || "Something went wrong");
            console.log("🚀 ~ onImageUpload ~ err:", err);
         });
   };

   const onDeleteAttributeValue = (
      product_tmpl_id,
      attribute_line_id,
      attribute_id,
      value_id
   ) => {
      const attributeValueData = {
         model: "product.template",
         method: "update",
         ids: [product_tmpl_id],
         kwargs: {
            values: {
               attribute_line_ids: [
                  [
                     1,
                     attribute_line_id,
                     {
                        attribute_id: attribute_id,
                        value_ids: [[3, value_id, 0]],
                     },
                  ],
               ],
            },
         },
      };
      ProductService.deleteAttributeValue(attributeValueData)
         .then(() => {
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("delete_success"));
            setIsUpdate(true);
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.message || "Something went wrong");
            console.log("🚀 ~ onImageUpload ~ err:", err);
         });
   };

   const onDeleteAttribute = (id) => {
      const attributeData = {
         model: "product.template.attribute.line",
         ids: id,
         method: "unlink",
      };
      ProductService.deleteAttribute(attributeData)
         .then(() => {
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("delete_success"));
            setIsUpdate(true);
         })
         .catch((err) => {
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.message || "Something went wrong");
            console.log("🚀 ~ onImageUpload ~ err:", err);
         });
   };

   useEffect(() => {
      setIsLoading(true);
      setLoadingRes(true);
      if (id) {
         ProductService.getById(id)
            .then((res) => {
               setValue("name", res?.records[0]?.name);
               setValue("sale_ok", res?.records[0]?.sale_ok);
               setValue("qty_available", res?.records[0]?.qty_available);
               setValue("list_price", res?.records[0]?.list_price);
               setValue(
                  "categ_id",
                  res?.records[0]?.categ_id && res?.records[0]?.categ_id
               );
               setValue(
                  "property_stock_inventory",
                  res?.records[0]?.property_stock_inventory &&
                     res?.records[0]?.property_stock_inventory
               );
               setValue("standard_price", res?.records[0]?.standard_price);
               setValue(
                  "default_code",
                  res?.records[0]?.default_code
                     ? res?.records[0]?.default_code
                     : ""
               );
               setValue(
                  "barcode",
                  res?.records[0]?.barcode ? res?.records[0]?.barcode : ""
               );
               setValue(
                  "brand_id",
                  res?.records[0]?.brand_id && res?.records[0]?.brand_id
               );
               setValue("weight", res?.records[0]?.weight);
               setValue(
                  "description",
                  res?.records[0]?.description
                     ? res?.records[0]?.description
                     : ""
               );
               setValue("qty_available", res?.records[0]?.qty_available);
               setValue(
                  "description_pickingout",
                  res?.records[0]?.description_pickingout
                     ? res?.records[0]?.description_pickingout
                     : ""
               );
               setImageSrc(
                  res?.records[0]?.image_256
                     ? res?.records[0]?.image_256
                     : false
               );
               setDescription(
                  res?.records[0]?.description
                     ? res?.records[0]?.description
                     : ""
               );
               setAttributeRes(res?.records[0]?.attribute_line_ids || null);
               setValue("type", res?.records[0]?.type);
               setSelectUnits(res?.records[0]?.uom_id);
               setValue("uom_id", res?.records[0]?.uom_id);
               setValue("uom_po_id", res?.records[0]?.uom_po_id);
            })
            .catch((err) => {
               console.error(err);
            })
            .finally(() => {
               setLoadingRes(false);
               setIsLoading(false);
            });
      } else {
         setIsLoading(false);
      }
   }, [setValue, isUpdate]);
   return {
      onSubmit,
      onUpdatePriority,
      register,
      handleSubmit,
      setValue,
      getValues,
      imageSrc,
      setImageSrc,
      imageUpload,
      setImageUpload,
      handleGetAttributeValues,
      attributeValues,
      setAttributeValues,
      onDelete,
      attributeLines,
      setAttributeLines,
      description,
      setDescription,
      attributeRes,
      generateDefaultCode,
      errors,
      control,
      onUpdateExtraCost,
      onSubmitValueModal,
      handleAttributeChange,
      handleValueIdsChange,
      onDeleteAttributeValue,
      loadingRes,
      onDeleteAttribute,
      selectUnits,
      setSelectUnits,
   };
};

export default useProductSubmit;
