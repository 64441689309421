import React, { useId, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { IoDocumentOutline } from "react-icons/io5";

const FileUploadDownload = () => {
  const inputId = useId();
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileDownload = () => {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="">
      <label
        htmlFor={inputId}
        className="flex py-2 px-3 items-center justify-center border-2 border-gray-300 rounded-lg bg-white cursor-pointer hover:bg-gray-200 transition-colors duration-300"
      >
        {file ? (
          <div className="flex items-center gap-2">
            <IoDocumentOutline className="text-green-500" size={20} />
            <span className="text-lg cursor-pointer ">{file.name}</span>
          </div>
        ) : (
          <span className="text-lg cursor-pointer ">Upload File</span>
        )}
        <input id={inputId} type="file" onChange={handleFileChange} className="hidden" />
      </label>
      {file && (
        <div className="flex items-center w-full justify-end">
          <button
            onClick={handleFileDownload}
            className="mt-2 p-2 w-full bg-blue-500 text-white rounded-lg"
          >
            Download File
          </button>
        </div>
      )}
    </div>
  );
};

export default FileUploadDownload;
