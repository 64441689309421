/* eslint-disable react/prop-types */
import React from "react";
import Badge from "./Badge";

const BadgeSection = ({ savedDataDetail }) => {
   return savedDataDetail && savedDataDetail?.payment_state === "paid" ? (
      <Badge />
   ) : null;
};

export default BadgeSection;
