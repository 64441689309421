import React from "react";
import PropTypes from "prop-types";

import Loader from "react-js-loader";

const Loading = ({ marginLeft = "0px" }) => {
   return (
      <div style={{ marginTop: "100px", marginLeft: marginLeft }}>
         <Loader
            type="ekvalayzer"
            color="#fb5159"
            bgColor="#fb5159"
            size={100}
         />
      </div>
   );
};

Loading.propTypes = {
   marginLeft: PropTypes.string,
};

export default Loading;
