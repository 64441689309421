import requests from "./httpService";
import { callPath} from "./endpoint";

const PaymentMethodService = {
  getAll: async () => {
    const jsonData = {
      model: "pos.payment.method",
      method: "web_search_read",
      ids: [],
      kwargs: {
        specification: {
          type: {},
          sequence: {},
          name: {},
          write_date: {},
          create_date: {},
          split_transactions: {},
          journal_id: {
            fields: {
              display_name: {}
            }
          },
          config_ids: {
            fields: {
              display_name: {}
            }
          },
          is_online_payment: {},
          has_an_online_payment_provider: {}
        },
        offset: 0,
        order: "sequence ASC, id ASC",
        limit: 80,
        count_limit: 10001,
        domain: []
      }
    };
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  create: async (body) => {

    return requests.post(callPath, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  updateOne: async (body) => {

    return requests.post(callPath, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },

  delete: async (id) => {
    const jsonData = {
      model: "pos.payment.method",
      method: "unlink",
      ids: [
        id
      ]
    }
    return requests.post(callPath, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
};

export default PaymentMethodService;
