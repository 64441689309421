export const productlistdata = [
  {
    id: 1,
    product: "Lenovo 3rd Generation",
    productImage: "assets/img/products/stock-img-01.png",
    sku: "PT001",
    category: "Laptop",
    brand: "Lenovo",
    price: "$12500.00",
    unit: "Pc",
    qty: "100",
    createdby: "Arroon",
    img: "assets/img/users/user-30.jpg",
  },
  {
    id: 2,
    product: "Bold V3.2",
    productImage: "assets/img/products/stock-img-06.png",
    sku: "PT002",
    category: "Electronics",
    brand: "Bolt",
    price: "$1600.00",
    unit: "Pc",
    qty: "140",
    createdby: "Kenneth",
    img: "assets/img/users/user-13.jpg",
  },
  {
    id: 3,
    product: "Nike Jordan",
    productImage: "assets/img/products/stock-img-02.png",
    sku: "PT003",
    category: "Shoe",
    brand: "Nike",
    price: "$6000.00",
    unit: "Pc",
    qty: "780",
    createdby: "Gooch",
    img: "assets/img/users/user-11.jpg",
  },
  {
    id: 4,
    product: "Apple Series 5 Watch",
    productImage: "assets/img/products/stock-img-03.png",
    sku: "PT004",
    category: "Electronics",
    brand: "Apple",
    price: "$25000.00",
    unit: "Pc",
    qty: "450",
    createdby: "Nathan",
    img: "assets/img/users/user-03.jpg ",
  },
  {
    id: 5,
    product: "Amazon Echo Dot",
    productImage: "assets/img/products/stock-img-04.png",
    sku: "PT005",
    category: "Speaker",
    brand: "Amazon",
    price: "$1600.00",
    unit: "Pc",
    qty: "477",
    createdby: "Alice",
    img: "assets/img/users/user-02.jpg ",
  },
  {
    id: 6,
    product: "Lobar Handy",
    productImage: "assets/img/products/stock-img-05.png",
    sku: "PT006",
    category: "Furnitures",
    brand: "Woodmart",
    price: "$4521.00",
    unit: "Kg",
    qty: "145",
    createdby: "Robb",
    img: "assets/img/users/user-5.jpg ",
  },
  {
    id: 7,
    product: "Red Premium Handy",
    productImage: "assets/img/products/expire-product-01.png",
    sku: "PT007",
    category: "Bags",
    brand: "Versace",
    price: "$2024.00",
    unit: "Kg",
    qty: "747",
    createdby: "Steven",
    img: "assets/img/users/user-08.jpg ",
  },
  {
    id: 8,
    product: "Iphone 14 Pro",
    productImage: "assets/img/products/expire-product-02.png",
    sku: "PT008",
    category: "Phone",
    brand: "Iphone",
    price: "$1698.00",
    unit: "Pc",
    qty: "897",
    createdby: "Gravely",
    img: "assets/img/users/user-04.jpg ",
  },
  {
    id: 9,
    product: "Black Slim 200",
    productImage: "assets/img/products/expire-product-03.png",
    sku: "PT009",
    category: "Chairs",
    brand: "Bently",
    price: "$6794.00",
    unit: "Pc",
    qty: "741",
    createdby: "Kevin",
    img: "assets/img/users/user-01.jpg ",
  },
  {
    id: 10,
    product: "Woodcraft Sandal",
    productImage: "assets/img/products/expire-product-04.png",
    sku: "PT010",
    category: "Bags",
    brand: "Woodcraft",
    price: "$4547.00",
    unit: "Kg",
    qty: "148",
    createdby: "Grillo",
    img: "assets/img/users/user-10.jpg ",
  },
];
