export const quotationlistdata = [
  {
    id: 1,
    productname: "Lenovo 3rd Generation",
    reference: "PT001",
    customername: "walk-in-customer",
    status: "Sent",
    grandtotal: "$550",
  },
  {
    id: 2,
    productname: "Bold V3.2",
    reference: "PT002",
    customername: "walk-in-customer",
    status: "Sent",
    grandtotal: "$430",
  },
  {
    id: 3,
    productname: "Nike Jordan",
    reference: "PT003",
    customername: "walk-in-customer",
    status: "Ordered",
    grandtotal: "$260",
  },
  {
    id: 4,
    productname: "Apple Series 5 Watch",
    reference: "PT004",
    customername: "John Smith",
    status: "Pending",
    grandtotal: "$470",
  },
  {
    id: 5,
    productname: "Amazon Echo Dot",
    reference: "PT005",
    customername: "Harley Stanley",
    status: "Pending",
    grandtotal: "$380",
  },
  {
    id: 6,
    productname: "Lobar Handy",
    reference: "PT006",
    customername: "Egbert Caldwell",
    status: "Sent",
    grandtotal: "$190",
  },
  {
    id: 7,
    productname: "Red Premium Handy",
    reference: "PT007",
    customername: "walk-in-customer",
    status: "Pending",
    grandtotal: "$540",
  },
  {
    id: 8,
    productname: "Iphone 14 Pro",
    reference: "PT008",
    customername: "Benjamin",
    status: "Ordered",
    grandtotal: "$610",
  },
  {
    id: 9,
    productname: "Black Slim 200",
    reference: "PT009",
    customername: "walk-in-customer",
    status: "Pending",
    grandtotal: "$220",
  },
  {
    id: 10,
    productname: "Woodcraft Sandal",
    reference: "PT010",
    customername: "Nydia Fitzgerald",
    status: "Sent",
    grandtotal: "$460",
  },
  {
    id: 11,
    productname: "Lobar Handy",
    reference: "PT011",
    customername: "Thomas",
    status: "Pending",
    grandtotal: "$250",
  },
  {
    id: 12,
    productname: "Iphone 15 Pro",
    reference: "PT012",
    customername: "Benjamin",
    status: "Sent",
    grandtotal: "$550",
  },
];
