import React from 'react';
import PropTypes from 'prop-types';
import { image_path } from '../../environment';

const ImageWithBasePath = (props) => {
  // Check if the src is a Base64-encoded image or an HTTPS URL
  const isBase64 = props?.src?.startsWith('data:image');
  const isHttps = props?.src?.startsWith('https');
  const fullSrc = isBase64 || isHttps ? props.src : `${image_path}${props?.src}`;
  const altText = String(props?.alt);

  return (

    <img
      className={props?.className}
      src={fullSrc}
      height={props?.height}
      alt={altText}
      width={props?.width}
      id={props?.id}
    />
  );
};

ImageWithBasePath.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  id: PropTypes.string,
};

export default ImageWithBasePath;
