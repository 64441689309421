// import qs from "qs";
import requests from "./httpService";
// import Cookies from "js-cookie";
import { callPath } from "./endpoint";

const NotificationService = {
   getAll: async (userId) => {
      console.log(userId);
      const jsonData = {
         model: "mail.activity",
         method: "web_search_read",
         kwargs: {
            domain: [["user_id", "=", userId]],
            specification: {
               res_model_id: {},
               res_model: {},
               res_id: {},
               res_name: {},
               activity_type_id: {},
               activity_category: {},
               activity_decoration: {},
               icon: {},
               summary: {},
               note: {},
               date_deadline: {},
               date_done: {},
               automated: {},
               attachment_ids: {},
               user_id: {},
               request_partner_id: {},
               state: {},
               recommended_activity_type_id: {},
               previous_activity_type_id: {},
               has_recommended_activities: {},
               mail_template_ids: {},
               chaining_type: {},
               can_write: {},
               active: {},
               id: {},
               display_name: {},
               create_uid: {},
               create_date: {},
               write_uid: {},
               write_date: {},
               calendar_event_id: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   archiveNotification: async (notificationId) => {
      const jsonData = {
         model: "mail.activity",
         method: "action_archive",
         ids: notificationId,
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   markAsDoneNotification: async (notificationId) => {
      const jsonData = {
         model: "mail.activity",
         method: "action_done",
         ids: notificationId,
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
};

export default NotificationService;
