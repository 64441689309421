export const callhistorydata = [
  {
    id: "1",
    checkbox: true,
    username: "Arroon",
    phone_number: "+1 25182 94528",
    call_type: "Incoming Call",
    duration: "00.25",
    date_time: "19 Jan 2023 - 01:16 PM",
    image_url: "assets/img/users/user-01.jpg",
  },
  {
    id: "2",
    checkbox: true,
    username: "Rose",
    phone_number: "+1 93016 81932",
    call_type: "Missed Call",
    duration: "00.00",
    date_time: "24 Jan 2023 - 02:50 PM",
    image_url: "assets/img/users/user-02.jpg",
  },
  {
    id: "3",
    checkbox: true,
    username: "Benjamin",
    phone_number: "+1 25182 94528",
    call_type: "Incoming Call",
    duration: "00.15",
    date_time: "03 Feb 2023 - 10:37 AM",
    image_url: "assets/img/users/user-03.jpg",
  },
  {
    id: "4",
    checkbox: true,
    username: "Kaitlin",
    phone_number: "+1 70328 96042",
    call_type: "Missed Call",
    duration: "00.00",
    date_time: "17 Feb 2023 - 11:25 AM",
    image_url: "assets/img/users/user-04.jpg",
  },
  {
    id: "5",
    checkbox: true,
    username: "Lilly",
    phone_number: "+1 83610 45175",
    call_type: "Outgoing Call",
    duration: "00.45",
    date_time: "14 Mar 2023 - 09:12 AM",
    image_url: "assets/img/users/user-05.jpg",
  },
  {
    id: "6",
    checkbox: true,
    username: "Freda",
    phone_number: "+1 46217 84294",
    call_type: "Incoming Call",
    duration: "00.30",
    date_time: "27 Mar 2023 - 04:32 PM",
    image_url: "assets/img/users/user-08.jpg",
  },
  {
    id: "7",
    checkbox: true,
    username: "Alwin",
    phone_number: "+1 62573 84301",
    call_type: "Outgoing Call",
    duration: "01.17",
    date_time: "13 Apr 2023 - 02:46 PM",
    image_url: "assets/img/users/user-06.jpg",
  },
  {
    id: "8",
    checkbox: true,
    username: "Maybelle",
    phone_number: "+1 14693 63826",
    call_type: "Missed Call",
    duration: "00.00",
    date_time: "11 Apr 2023 - 10:29 AM",
    image_url: "assets/img/users/user-07.jpg",
  },
  {
    id: "9",
    checkbox: true,
    username: "Ellen",
    phone_number: "+1 83710 43827",
    call_type: "Incoming Call",
    duration: "00.50",
    date_time: "14 May 2023 - 03:06 PM",
    image_url: "assets/img/users/user-10.jpg",
  },
  {
    id: "10",
    checkbox: true,
    username: "Grace",
    phone_number: "+1 42695 07322",
    call_type: "Missed Call",
    duration: "00.00",
    date_time: "29 May 2023 - 11:23 AM",
    image_url: "assets/img/users/user-12.jpg",
  },
];
