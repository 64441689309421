/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { Search, Settings, User } from "react-feather";
import { all_routes } from "../../Router/all_routes";
import { useContext } from "react";
import { SidebarContext } from "../../core/context/SidebarContext";
import useLogoutSubmit from "../../core/hooks/useLogoutSubmit";
import useUserSubmit from "../../core/hooks/useUserSubmit";
import StoreService from "../../core/services/StoreService";
import { useSelector, useDispatch } from "react-redux";
import useAsync from "../../core/hooks/useAsync";
import { useTranslation } from "react-i18next"; // For translation
import SearchBar from "./searchBar";
import { set_context_company_data } from "../../core/redux/action";
import { useForm } from "react-hook-form";
import NotificationService from "../../core/services/NotificationService";

const Header = () => {
   const dispatch = useDispatch();
   const context = useSelector((state) => state.context_company);
   const { t } = useTranslation(); // Translation
   let navigate = useNavigate();
   const { lang, handleLanguageChange, isUpdate, setIsUpdate } =
      useContext(SidebarContext);
   const { onLogout } = useLogoutSubmit(navigate);
   const { getValues, imageSrc } = useUserSubmit();

   const { handleSubmit } = useForm();
   const route = all_routes;
   const [toggle, SetToggle] = useState(false);
   const [isFullscreen, setIsFullscreen] = useState(false);
   const isElementVisible = (element) => {
      return element.offsetWidth > 0 || element.offsetHeight > 0;
   };

   useEffect(() => {
      const handleMouseover = (e) => {
         e.stopPropagation();

         const body = document.body;
         const toggleBtn = document.getElementById("toggle_btn");

         if (
            body.classList.contains("mini-sidebar") &&
            isElementVisible(toggleBtn)
         ) {
            e.preventDefault();
         }
      };

      document.addEventListener("mouseover", handleMouseover);

      return () => {
         document.removeEventListener("mouseover", handleMouseover);
      };
   }, []);
   useEffect(() => {
      const handleFullscreenChange = () => {
         setIsFullscreen(
            document.fullscreenElement ||
               document.mozFullScreenElement ||
               document.webkitFullscreenElement ||
               document.msFullscreenElement
         );
      };

      document.addEventListener("fullscreenchange", handleFullscreenChange);
      document.addEventListener("mozfullscreenchange", handleFullscreenChange);
      document.addEventListener(
         "webkitfullscreenchange",
         handleFullscreenChange
      );
      document.addEventListener("msfullscreenchange", handleFullscreenChange);

      return () => {
         document.removeEventListener(
            "fullscreenchange",
            handleFullscreenChange
         );
         document.removeEventListener(
            "mozfullscreenchange",
            handleFullscreenChange
         );
         document.removeEventListener(
            "webkitfullscreenchange",
            handleFullscreenChange
         );
         document.removeEventListener(
            "msfullscreenchange",
            handleFullscreenChange
         );
      };
   }, []);
   const handlesidebar = (e) => {
      e.preventDefault();
      document.body.classList.toggle("mini-sidebar");
      SetToggle((current) => !current);
   };
   const expandMenu = () => {
      document.body.classList.remove("expand-menu");
   };
   const expandMenuOpen = () => {
      document.body.classList.add("expand-menu");
   };
   const sidebarOverlay = () => {
      document?.querySelector(".main-wrapper")?.classList?.toggle("slide-nav");
      document?.querySelector(".sidebar-overlay")?.classList?.toggle("opened");
      document?.querySelector("html")?.classList?.toggle("menu-opened");
   };

   let pathname = location.pathname;

   const exclusionArray = [
      "/reactjs/template/dream-pos/index-three",
      "/reactjs/template/dream-pos/index-one",
   ];
   if (exclusionArray.indexOf(window.location.pathname) >= 0) {
      return "";
   }

   const toggleFullscreen = (elem) => {
      elem = elem || document.documentElement;
      if (
         !document.fullscreenElement &&
         !document.mozFullScreenElement &&
         !document.webkitFullscreenElement &&
         !document.msFullscreenElement
      ) {
         if (elem.requestFullscreen) {
            elem.requestFullscreen();
         } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
         } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
         } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
         }
      } else {
         if (document.exitFullscreen) {
            document.exitFullscreen();
         } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
         } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
         } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
         }
      }
   };

   // Store function
   const [stores, setStores] = useState([]);

   const [currentStore, setCurrentStore] = useState({});

   const [selectedStoreList, setSelectedStoreList] = useState([]);

   const { data } = useAsync(
      () => StoreService.getAllowedStore(getValues("sub")),
      "allowed-store-list"
   );
   const handleSwitchCompany = async (store) => {
      setSelectedStoreList((selectedStoreList) => {
         const storeExists = selectedStoreList.some(
            (selectedStore) => selectedStore.id === store.id
         );
         if (storeExists) {
            // Remove the store if it exists
            return selectedStoreList.filter(
               (selectedStore) => selectedStore.id !== store.id
            );
         } else {
            // Add the store if it doesn't exist
            return [...selectedStoreList, store];
         }
      }, setIsUpdate(!isUpdate));
   };

   const handleSelectAllStore = async () => {
      const allStores = data?.records || [];
      const storedCompanyIds = context || [];
      const newCompanyIds = allStores.map((item) => item.id);

      console.log(storedCompanyIds);
      console.log(newCompanyIds);
      // Check if all new company ids are already stored
      const AllInContext = newCompanyIds.every((id) =>
         storedCompanyIds.includes(id)
      );

      if (AllInContext) {
         dispatch(
            set_context_company_data(selectedStoreList.map((item) => item.id))
         );
      } else {
         dispatch(set_context_company_data(newCompanyIds));
      }

      setIsUpdate(!isUpdate);
   };

   const fetchCurrentCompany = async () => {
      await StoreService.getUserCompany().then((res) => {
         setCurrentStore(res.records[0]?.company_id);
         setStores(res.records[0]?.company_ids);
         const checkbox = document.getElementById(
            `checkbox-${res.records[0]?.company_id.id}`
         );
         if (checkbox) {
            checkbox.checked = true;
         }
         if (selectedStoreList.length === 0) {
            setSelectedStoreList([res.records[0]?.company_id]);
            dispatch(set_context_company_data([res.records[0]?.company_id.id]));
         }
         if (selectedStoreList) {
            dispatch(
               set_context_company_data(
                  selectedStoreList.map((item) => item.id)
               )
            );
         }
      });
   };

   useEffect(() => {
      fetchCurrentCompany();
   }, [data, selectedStoreList]);
   //  store function

   // notification funciton
   const [notification, setNotification] = useState([]);
   const [userId, setUserId] = useState();

   const getNotification = async () => {
      if (userId) {
         await NotificationService.getAll(userId).then((res) => {
            setNotification(res.records);
            console.log(res);
         });
      }
   };

   const convertPassiveDate = (date) => {
      const dateObj = new Date(date);
      const currentDate = new Date();
      const diffTime = currentDate - dateObj;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) {
         return t("today");
      } else if (diffDays === 1) {
         return t("yesterday");
      } else if (diffDays < 0) {
         return "";
      } else {
         return diffDays + " " + t("days_ago");
      }
   };

   const linkToNotification = (resModel, resId) => {
      if (resModel == "sale.order") {
         return `/sales-detail/${resId}`;
      } else if (resModel == "product.template") {
         return `/product-details/${resId}`;
      } else if (resModel == "pos.session") {
         return `/pos/${resId}`;
      }
      return "/";
   };
   useEffect(() => {
      const id = getValues("sub");
      setUserId(id);
   });
   useEffect(() => {
      getNotification();
      console.log(notification);
   }, [userId]);

   return (
      <>
         <div className="header">
            {/* Logo */}
            <div
               className={`header-left ${toggle ? "" : "active"}`}
               onMouseLeave={expandMenu}
               onMouseOver={expandMenuOpen}
            >
               <Link to="/dashboard" className="logo logo-normal">
                  <ImageWithBasePath src="assets/img/logo.svg" alt="img" />
               </Link>
               <Link to="/dashboard" className="logo logo-white">
                  <ImageWithBasePath
                     src="assets/img/logo-white.svg"
                     alt="img"
                  />
               </Link>
               <Link to="/dashboard" className="logo-small">
                  <ImageWithBasePath
                     src="assets/img/logo-small.svg"
                     alt="img"
                  />
               </Link>
               <Link
                  id="toggle_btn"
                  to="#"
                  style={{
                     display:
                        pathname.includes("tasks") || pathname.includes("pos")
                           ? "none"
                           : pathname.includes("compose")
                           ? "none"
                           : "",
                  }}
                  onClick={handlesidebar}
               >
                  <FeatherIcon icon="chevrons-left" className="feather-16" />
               </Link>
            </div>
            {/* /Logo */}
            <Link
               id="mobile_btn"
               className="mobile_btn"
               to="#"
               onClick={sidebarOverlay}
            >
               <span className="bar-icon">
                  <span />
                  <span />
                  <span />
               </span>
            </Link>
            {/* Header Menu */}
            <ul className="nav user-menu">
               {/* Search */}
               <li className="nav-item nav-searchinputs">
                  <div className="top-nav-search">
                     {/* <Link to="#" className="responsive-search">
                        <Search />
                     </Link>

                     <SearchBar /> */}
                  </div>
               </li>
               {/* /Search */}
               {/* Select Store */}
               <li className="nav-item dropdown has-arrow main-drop select-store-dropdown">
                  {
                     <Link
                        to="#"
                        className="dropdown-toggle nav-link select-store"
                        data-bs-toggle="dropdown"
                     >
                        <span className="user-info">
                           <span className="user-letter">
                              <ImageWithBasePath
                                 src={`${currentStore.logo}`}
                                 alt="Store Logo"
                                 className="img-fluid"
                              />
                           </span>
                           <span className="user-detail">
                              <span className="user-name">
                                 {currentStore.name}
                              </span>
                           </span>
                        </span>
                     </Link>
                  }

                  <div className="dropdown-menu dropdown-menu-right">
                     {stores?.map((item) => {
                        return (
                           <div key={item.id} className="dropdown-item">
                              <input
                                 type="checkbox"
                                 id={`checkbox-${item.id}`}
                                 value={item.id}
                                 onChange={() => handleSwitchCompany(item)}
                              />
                              <label
                                 htmlFor={`checkbox-${item.id}`}
                                 className="dropdown-item"
                              >
                                 {item.name}
                              </label>
                           </div>
                        );
                     })}

                     <div className="dropdown-item">
                        <input
                           type="checkbox"
                           id={"select_all"}
                           onClick={handleSelectAllStore}
                        />
                        <label htmlFor={"select_all"} className="dropdown-item">
                           All{" "}
                        </label>
                     </div>
                  </div>
               </li>
               {/* /Select Store */}
               {/* Flag */}
               <li className="nav-item dropdown has-arrow flag-nav nav-item-box">
                  <Link
                     className="nav-link dropdown-toggle"
                     data-bs-toggle="dropdown"
                     to="#"
                     role="button"
                  >
                     <ImageWithBasePath
                        src={`${
                           lang === "en"
                              ? "assets/img/flags/us.png"
                              : "assets/img/flags/vn.png"
                        }`}
                        alt="img"
                        height={16}
                     />
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                     <div onClick={() => handleLanguageChange("en")}>
                        <Link
                           to="#"
                           className={`dropdown-item ${
                              lang === "en" ? "active" : ""
                           }`}
                        >
                           <ImageWithBasePath
                              src="assets/img/flags/us.png"
                              alt="img"
                              height={16}
                           />
                           English
                        </Link>
                     </div>
                     <div onClick={() => handleLanguageChange("vi")}>
                        <Link
                           to="#"
                           className={`dropdown-item ${
                              lang === "vi" ? "active" : ""
                           }`}
                        >
                           <ImageWithBasePath
                              src="assets/img/flags/vn.png"
                              alt="img"
                              height={16}
                           />{" "}
                           Vietnamese
                        </Link>
                     </div>
                  </div>
               </li>
               {/* /Flag */}
               <li className="nav-item nav-item-box">
                  <Link
                     to="#"
                     id="btnFullscreen"
                     onClick={() => toggleFullscreen()}
                     className={
                        isFullscreen ? "Exit Fullscreen" : "Go Fullscreen"
                     }
                  >
                     <i data-feather="maximize" />
                     <FeatherIcon icon="maximize" />
                  </Link>
               </li>
               {/* Email */}
               {/* <li className="nav-item nav-item-box">
                  <Link to="/email">
                     <FeatherIcon icon="mail" />
                     <span className="badge rounded-pill">1</span>
                  </Link>
               </li> */}
               {/* Email */}
               {/* /Notifications */}
               <li className="nav-item dropdown nav-item-box">
                  <Link
                     to="#"
                     className="dropdown-toggle nav-link"
                     data-bs-toggle="dropdown"
                  >
                     <FeatherIcon icon="bell" />
                     <span className="badge rounded-pill">
                        {notification?.length}
                     </span>
                  </Link>
                  <div className="dropdown-menu notifications">
                     <div className="topnav-dropdown-header">
                        <span className="notification-title">
                           Notifications
                        </span>
                        <Link to="#" className="clear-noti">
                           {" "}
                           Clear All{" "}
                        </Link>
                     </div>
                     <div className="noti-content">
                        <ul className="notification-list">
                           {notification?.map((notification) => {
                              return (
                                 <li
                                    key={notification.id}
                                    className="notification-message"
                                 >
                                    <Link
                                       to={`${linkToNotification(
                                          notification.res_model,
                                          notification.res_id
                                       )}`}
                                    >
                                       <div className="media d-flex">
                                          <div className="media-body flex-grow-1">
                                             <p className="noti-details">
                                                <i
                                                   className={`fa ${notification.icon}`}
                                                />
                                                <span> </span>
                                                <span className="noti-title">
                                                   {notification.display_name}
                                                </span>
                                             </p>
                                             <p className="noti-time">
                                                <span className="notification-time">
                                                   <span> </span>
                                                   Due to :{" "}
                                                   {convertPassiveDate(
                                                      notification.date_deadline
                                                   )}
                                                </span>
                                             </p>{" "}
                                             <p className="noti-time">
                                                <span className="notification-time">
                                                   <span> </span>
                                                   State : {notification.state}
                                                </span>
                                             </p>
                                          </div>
                                       </div>
                                    </Link>
                                 </li>
                              );
                           })}
                        </ul>
                     </div>
                     <div className="topnav-dropdown-footer">
                        <Link to="/activities">View all Notifications</Link>
                     </div>
                  </div>
               </li>
               {/* /Notifications */}
               <li className="nav-item nav-item-box">
                  <Link to="/general-settings">
                     {/* <i data-feather="settings" /> */}
                     <FeatherIcon icon="settings" />
                  </Link>
               </li>
               <li className="nav-item dropdown has-arrow main-drop">
                  <Link
                     to="#"
                     className="dropdown-toggle nav-link userset"
                     data-bs-toggle="dropdown"
                  >
                     <span className="user-info">
                        <span className="user-letter">
                           <img
                              src={imageSrc}
                              alt="img"
                              className="img-fluid w-100 h-100"
                           />
                        </span>
                        <span className="user-detail">
                           <span className="user-name">
                              {getValues("name")}
                           </span>
                           <span className="user-role">
                              {getValues("email")}
                           </span>
                        </span>
                     </span>
                  </Link>
                  <div className="dropdown-menu menu-drop-user">
                     <div className="profilename">
                        {/* <div className="profileset">

                  <span className="user-img">
                    <img src={imageSrc} alt="img" className=" w-100 h-100" />
                    <span className="status online" />
                  </span>
                  <div className="profilesets">
                    <h6>{getValues("name")}</h6>
                    <h5>{getValues("email")}</h5>
                  </div>
                </div> */}

                        <hr className="m-0" />
                        <Link className="dropdown-item" to={route.profile}>
                           <User className="me-2" />
                           {t("my_profile")}
                        </Link>
                        <Link
                           className="dropdown-item"
                           to={route.generalsettings}
                        >
                           <Settings className="me-2" />
                           {t("settings")}
                        </Link>
                        <hr className="m-0" />
                        <form onSubmit={handleSubmit(onLogout)}>
                           <button
                              type="submit"
                              style={{
                                 backgroundColor: "transparent",
                                 border: "none",
                                 display: "inline",
                                 padding: "0px",
                                 width: "100%",
                              }}
                           >
                              <div className="dropdown-item logout pb-0">
                                 <ImageWithBasePath
                                    src="assets/img/icons/log-out.svg"
                                    alt="img"
                                    className="me-2"
                                 />{" "}
                                 {t("logout")}
                              </div>
                           </button>
                        </form>
                     </div>
                  </div>
               </li>
            </ul>
            {/* /Header Menu */}
            {/* Mobile Menu */}
            <div className="dropdown mobile-user-menu">
               <Link
                  to="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
               >
                  <i className="fa fa-ellipsis-v" />
               </Link>
               <div className="dropdown-menu dropdown-menu-right">
                  <Link className="dropdown-item" to="profile">
                     {t("my_profile")}
                  </Link>
                  <Link className="dropdown-item" to="generalsettings">
                     {t("settings")}
                  </Link>
                  <form onSubmit={handleSubmit(onLogout)}>
                     <button
                        type="submit"
                        style={{
                           backgroundColor: "transparent",
                           border: "none",
                           display: "inline",
                           padding: "0px",
                           width: "100%",
                        }}
                     >
                        <div className="dropdown-item logout pb-0">
                           <ImageWithBasePath
                              src="assets/img/icons/log-out.svg"
                              alt="img"
                              className="me-2"
                           />{" "}
                           {t("logout")}
                        </div>
                     </button>
                  </form>
               </div>
            </div>
            {/* /Mobile Menu */}
         </div>
      </>
   );
};

export default Header;
