import requests from "./httpService";
import { callPath, uploadFile } from "./endpoint";

const ExcelService = {
   uploadFile: async ({ modal, files }) => {
      return requests.post(`${uploadFile}?model=${modal}`, files, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   uploadIdFile: async ({ modal, files, id }) => {
      return requests.post(`${uploadFile}?model=${modal}&id=${id}`, files, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   ParsePreview: async ({ id }) => {
      const jsonData = {
         model: "base_import.import",
         method: "parse_preview",
         ids: [id],
         kwargs: {
            options: {
               import_skip_records: [],
               import_set_empty_fields: [],
               fallback_values: {},
               name_create_enabled_fields: {},
               encoding: "",
               separator: "",
               quoting: '"',
               date_format: "",
               datetime_format: "",
               float_thousand_separator: ",",
               float_decimal_separator: ".",
               advanced: true,
               has_headers: true,
               keep_matches: false,
               limit: 2000,
               sheets: [],
               sheet: "",
               skip: 0,
               tracking_disable: true,
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
   ExecuteImport: async ({ columns, fields, idFile, options, dryrun }) => {
      const jsonData = {
         model: "base_import.import",
         method: "execute_import",
         ids: [idFile],
         kwargs: {
            fields: fields,
            columns: columns,
            options: options,
            dryrun: dryrun,
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },
};
export default ExcelService;
