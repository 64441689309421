// import qs from "qs";
import requests from "./httpService";
// import Cookies from "js-cookie";
import {
   callPath,
   createPath,
   updatePath,
   uploadFilePath,
   companyPath,
} from "./endpoint";

const StoreService = {
   getAll: async () => {
      const jsonData = {
         model: "res.company",
         method: "web_search_read",
         kwargs: {
            domain: [],
            specification: {
               id: {},
               name: {},
               sequence: { fields: { display_name: {} } },
               email: {},
               phone: {},
               display_name: {},
               logo: {},
               email_formatted: {},
               country_id: { fields: { display_name: {} } },
               active: {},
               partner_id: { fields: { display_name: {} } },
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   getAllowedStore: async (userId) => {
      const jsonData = {
         model: "res.company",
         method: "web_search_read",
         kwargs: {
            domain: [["user_ids", "=", userId]],
            specification: {
               id: {},
               name: {},
               logo: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   createStore: async (body) => {
      return requests.post(createPath, body, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },

   updateStore: async (body) => {
      return requests.post(updatePath, body, {
         headers: {
            "Content-Type": "application/x-www-form-urlencoded",
         },
      });
   },

   getStoreById: async (storeId) => {
      console.log(storeId);
      const jsonData = {
         model: "res.partner",
         method: "web_search_read",
         kwargs: {
            domain: [
               ["store_rank", ">", 0],
               ["id", "=", storeId],
            ],
            specification: {
               supplier_rank: {},
               store_rank: {},
               user_ids: {},
               bank_ids: {
                  fields: {
                     id: {},
                     acc_number: {},
                     acc_holder_name: {},
                     bank_id: { fields: { display_name: {} } },
                  },
               },
               name: {},
               type: {},
               contact_address: {},
               date: {},
               id: {},
               phone: {},
               email: {},
               image_256: {},
               complete_name: {},
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   setImage: async (body, queryParams) => {
      const query = new URLSearchParams(queryParams).toString();
      return requests.post(uploadFilePath + "?" + query, body, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      });
   },

   archiveStore: async (storeId) => {
      const jsonData = {
         model: "res.company",
         method: "action_archive",
         ids: storeId,
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   switchCurrentUserCompany: async (companyId) => {
      const jsonData = {
         model: "res.users",
         domain: [
            // ["id", "=", await UserService.profile().then((res) => res.sub)],
         ],
         values: {
            company_id: parseInt(companyId),
         },
      };
      return requests.post(updatePath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   getCurrentCompany: async () => {
      return requests.get(companyPath, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },

   getUserCompany: async () => {
      const jsonData = {
         model: "res.users",
         method: "web_search_read",
         kwargs: {
            domain: [
               [
                  "user_ids",
                  "=",
                  2, //
               ],
            ],
            specification: {
               id: {},
               company_id: { fields: { name: {}, logo: {} } },
               company_ids: { fields: { name: {} } },
            },
         },
      };
      return requests.post(callPath, jsonData, {
         headers: {
            "Content-Type": "application/json",
         },
      });
   },
};

export default StoreService;
