/* eslint-disable react/prop-types */
import React from "react";
import { Controller } from "react-hook-form";

const RadioButtonGroup = ({
   name,
   control,
   modelsData,
   readonly,
   placeholder,
   required,
}) => (
   <Controller
      name={name}
      control={control}
      render={({ field }) => (
         <div className="flex flex-col">
            {modelsData[name]?.selection?.map((select) => (
               <div className="flex gap-1 items-center" key={select[0]}>
                  <input
                     type="radio"
                     {...field}
                     value={select[0]}
                     checked={
                        field.value ? field.value === select[0] : select[0]
                     }
                     readOnly={readonly}
                     placeholder={placeholder}
                     required={required}
                  />
                  <label htmlFor={select[0]}>{select[1]}</label>
               </div>
            ))}
         </div>
      )}
   />
);

export default RadioButtonGroup;
