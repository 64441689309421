import { MinusCircle, PlusCircle } from 'feather-icons-react/build/IconComponents';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import POSService from '../../core/services/POSService';

function QuantityProduct({ product, order_detail }) {
  const [quantity, setQuantity] = useState(product.qty);

  QuantityProduct.propTypes = {
    product: PropTypes.object.isRequired,
    order_detail: PropTypes.object.isRequired,
  };

  const handleDecrement = async () => {
    const numberValue = parseInt(quantity, 10);
    if (quantity > 0 && !isNaN(numberValue)) {
      await POSService.updateProductAmountInOrder(product.id,
        {
          order_id: order_detail.id,
          product_id: product.product_id.id,
          qty: numberValue - 1,
          price_subtotal_incl: product.price_subtotal_incl,
          price_subtotal: product.price_subtotal,
        }
      )
      setQuantity(quantity - 1);
    }
  };

  const handleIncrement = async () => {
    const numberValue = parseInt(quantity, 10);
    if (!isNaN(numberValue)) {
      await POSService.updateProductAmountInOrder(product.id,
        {
          order_id: order_detail.id,
          product_id: product.product_id.id,
          qty: numberValue + 1,
          price_subtotal_incl: product.price_subtotal_incl,
          price_subtotal: product.price_subtotal,
        }
      )

      setQuantity(numberValue + 1);
    }
  };

  const handleChange = async (e) => {
    const value = e.target.value;
    const numberValue = parseInt(value, 10);
    if (!isNaN(numberValue)) {
      await POSService.updateProductAmountInOrder(product.id,
        {
          order_id: order_detail.id,
          product_id: product.product_id.id,
          qty: numberValue,
          price_subtotal_incl: product.price_subtotal_incl,
          price_subtotal: product.price_subtotal,
        }
      )
      setQuantity(numberValue);
    }
  };

  return (
    <div className="qty-item text-center">
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-minus">Minus</Tooltip>}
      >
        <Link
          to="#"
          className="dec d-flex justify-content-center align-items-center"
          onClick={handleDecrement}
        >
          <MinusCircle className="feather-14" />
        </Link>
      </OverlayTrigger>

      <input
        type="text"
        className="form-control text-center"
        name="qty"
        value={quantity}
        onChange={handleChange}
        maxLength="8"
      />
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip-plus">Plus</Tooltip>}
      >
        <Link
          to="#"
          onClick={handleIncrement}
          className="inc d-flex justify-content-center align-items-center"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="plus"
        >
          <PlusCircle className="feather-14" />
        </Link>
      </OverlayTrigger>
    </div>
  );
}

export default QuantityProduct;
