import BrandService from "../services/BrandService";
import alertify from "alertifyjs";
import "../../../node_modules/alertifyjs/build/css/alertify.css";
import "../../../node_modules/alertifyjs/build/css/themes/semantic.css";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import { useTranslation } from "react-i18next";
const useBrandSubmit = () => {
  const { t } = useTranslation();
  const { setIsUpdate } = useContext(SidebarContext);
  const onSubmitModal = (id, name) => {
    if (id) {
      console.log("🚀 ~ onSubmitModal ~ id:", id)
      const brandData = {
        model: "product.brand",
        domain: [["id", "=", `${id}`]],
        values: { name: name },
      };
      BrandService.editOne(brandData)
        .then(() => {
          alertify.set("notifier", "position", "top-right");
          alertify.success(t("edit_success"));
          setIsUpdate(true);
        })
        .catch((err) => {
          alertify.set("notifier", "position", "top-right");
          alertify.error(err?.message || "Something went wrong");
          console.log("🚀 ~ onImageUpload ~ err:", err);
        });
    } else {
      const brandData = {
        model: "product.brand",
        values: { name: name },
      };
      BrandService.addOne(brandData)
        .then(() => {
          alertify.set("notifier", "position", "top-right");
          alertify.success(t("add_success"));
          setIsUpdate(true);
        })
        .catch((err) => {
          alertify.set("notifier", "position", "top-right");
          alertify.error(err?.message || "Something went wrong");
          console.log("🚀 ~ onImageUpload ~ err:", err);
        });
    }
  };
  const onDelete = (id) => {
    const brandData = {
      model: "product.brand",
      ids: id,
      method: "unlink",
    };
    BrandService.deleteOne(brandData)
      .then(() => {
        alertify.set("notifier", "position", "top-right");
        alertify.success(t("delete_success"));
      })
      .catch((err) => {
        alertify.set("notifier", "position", "top-right");
        alertify.error(err?.message || "Something went wrong");
      });
  };
  return {
    onDelete,
    onSubmitModal,
  };
};

export default useBrandSubmit;
