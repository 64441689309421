import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useBrandSubmit from "../../hooks/useBrandSubmit";
import { useState } from "react";
import PropTypes from "prop-types";

const AddBrand = () => {
  const { t } = useTranslation();
  const [brandName, setBrandName] = useState("");
  const { onSubmitModal } = useBrandSubmit();
  return (
    <>
      {/* Add Category */}
      <div className="modal fade" id="add-brand-list">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>{t("add_new_brand")}</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <div className="mb-3">
                    <label className="form-label">
                      {t("add_brand_name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={brandName}
                      onChange={(e) => setBrandName(e.target.value)}
                    />
                  </div>
                  <div className="modal-footer-btn">
                    <Link
                      to="#"
                      className="btn btn-cancel me-2"
                      data-bs-dismiss="modal"
                    >
                      {t("cancel")}
                    </Link>
                    <button
                      type="submit"
                      className="btn btn-submit"
                      onClick={(e) => {
                        onSubmitModal(false, brandName),
                          e.preventDefault();
                      }}
                      data-bs-dismiss="modal"
                    >
                      {t("submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Category */}
    </>
  );
};
AddBrand.defaultProps = {
  data: [],
  loading: false,
};
AddBrand.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};
export default AddBrand;
