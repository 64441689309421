/* eslint-disable react/prop-types */
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import RadioButtonGroup from "./RadioButtonGroup";
import SelectDropdown from "./SelectDropdown";

const SelectionField = ({
   name,
   control,
   modelsData,
   widget,
   readonly,
   placeholder,
   required,
   savedDataDetail,
}) => {
   if (widget === "radio") {
      return (
         <RadioButtonGroup
            name={name}
            control={control}
            modelsData={modelsData}
            readonly={readonly}
            placeholder={placeholder}
            required={required}
         />
      );
   }

   return (
      <SelectDropdown
         name={name}
         control={control}
         modelsData={modelsData}
         savedDataDetail={savedDataDetail}
      />
   );
};

export default SelectionField;
