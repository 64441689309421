/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

// const statusOptions = [
//   ["backlog", "Backlog"],
//   ["todo", "To Do"],
//   ["inprocess", "In Process"],
//   ["done", "Done"],
// ];

const StatusBar = ({ name, register, control, statusOptions, setValue }) => {
   useEffect(() => {
      if (statusOptions && statusOptions.length > 0) {
         setValue(name, statusOptions?.[0]?.[0]);
      }
   }, [register, setValue, name, statusOptions]);

   return (
      <Controller
         name={name}
         control={control}
         render={({ field }) => (
            <div className="flex items-center">
               {statusOptions.map((option, index) => (
                  <StatusOption
                     key={option[0]}
                     option={option}
                     field={field}
                     register={register}
                     isFirst={index === 0}
                     isLast={index === statusOptions?.length - 1}
                  />
               ))}
            </div>
         )}
      />
   );
};

export default StatusBar;

const StatusOption = ({ option, field, isFirst, isLast }) => {
   const [value, label] = option;
   const isActive = field?.value === value;

   return (
      <label
         key={value}
         className={`relative font-semibold cursor-default py-2 pl-8 pr-6 flex-shrink-0 border-y-2 ${
            isActive
               ? "text-[#111827] bg-[#fef4ec]"
               : "text-[rgb(31,41,55)] border-gray-300 bg-[#e7e9ed]"
         } ${isFirst ? "rounded-l-lg border-l-2" : "rounded-none"} 
       ${isLast ? "rounded-r-lg border-r-2" : "rounded-none"}
     `}
      >
         <input
            type="radio"
            {...field}
            value={value}
            checked={isActive}
            className="hidden"
            disabled
         />
         <span className="block text-inherit">{label}</span>
         {!isLast && <Arrow isActive={isActive} />}
      </label>
   );
};

const Arrow = ({ isActive }) => (
   <div
      className={`absolute z-[10] inset-y-0 h-full right-0 transform translate-x-full border-transparent border-[20px] ${
         isActive ? "!border-l-[#fef4ec]" : "!border-l-[#e7e9ed]"
      }`}
   />
);
