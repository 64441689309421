/* eslint-disable react/prop-types */
import React from "react";
import StatusBar from "./Statusbar";

const StatusBarSection = ({
   checkStatusBar,
   control,
   register,
   modelsData,
   setValue,
}) => {
   const statusOptions = checkStatusBar
      ? modelsData?.[checkStatusBar?.name]?.selection
      : [];
   return (
      checkStatusBar && (
         <div className="table-top !mb-0">
            <div className="ml-auto mt-1 mr-1">
               <StatusBar
                  name={checkStatusBar?.fieldValue?.name}
                  control={control}
                  register={register}
                  statusOptions={statusOptions}
                  setValue={setValue}
               />
            </div>
         </div>
      )
   );
};

export default StatusBarSection;
