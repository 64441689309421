/* eslint-disable react/prop-types */
import React from "react";
import InputField from "../InputField";
import DateField from "./DateField";
import Many2OneField from "./Many2OneField";
import StatusBar from "./Statusbar/Statusbar";
import Many2ManyCheckbox from "./Many2ManyCheckbox";
import Many2OneButton from "./Many2OneButton";
import BinaryField from "./BinaryField";
import FileUploadDownload from "./FileUploadDownload";
import SelectionField from "./SelectionField";
import TextField from "./TextField";
import NumberField from "./NumberField";
import Many2ManyTag from "./Many2ManyTag";

const RenderWidget = (type, widget, props) => {
   const {
      name,
      control,
      register,
      setValue,
      isReadOnly,
      isPlaceHolder,
      required,
      relation,
      savedDataDetail,
      modelsData,
   } = props;

   switch (type) {
      case "char":
      case "html":
         return (
            <InputField
               type={type}
               register={register}
               name={name}
               readonly={isReadOnly}
               placeHolder={isPlaceHolder}
               required={required}
               savedDataDetail={savedDataDetail}
            />
         );
      case "date":
         return <DateField name={name} control={control} />;
      case "many2one":
         return (
            <Many2OneField
               relation={relation}
               name={name}
               control={control}
               savedDataDetail={savedDataDetail}
            />
         );
      case "status_bar":
         return <StatusBar relation={relation} name={name} control={control} />;
      case "many2one_checkbox":
         return (
            <Many2ManyCheckbox
               relation={relation}
               name={name}
               control={control}
            />
         );
      case "many2one_button":
         return (
            <Many2OneButton relation={relation} name={name} control={control} />
         );
      case "many2many":
         if (widget === "many2many_tags") {
            return (
               <Many2ManyTag
                  relation={relation}
                  name={name}
                  control={control}
               />
            );
         } else {
            return <div></div>;
         }
      case "binary_field":
         return (
            <BinaryField relation={relation} name={name} control={control} />
         );
      case "file_upload_download":
         return (
            <FileUploadDownload
               relation={relation}
               name={name}
               control={control}
            />
         );
      case "selection":
         if (widget !== "statusbar") {
            return (
               <SelectionField
                  name={name}
                  control={control}
                  modelsData={modelsData}
                  widget={widget}
                  readonly={isReadOnly}
                  placeholder={isPlaceHolder}
                  savedDataDetail={savedDataDetail}
                  register={register}
                  setValue={setValue}
               />
            );
         }
         break;
      case "binary":
         return (
            <BinaryField
               name={name}
               control={control}
               readonly={isPlaceHolder}
               savedDataDetail={savedDataDetail}
            />
         );
      case "text":
         return <TextField />;
      case "integer":
      case "float":
      case "monetary":
         return (
            <NumberField
               name={name}
               register={register}
               savedDataDetail={savedDataDetail}
            />
         );
      default:
         return null;
   }
};

export default RenderWidget;
