/* eslint-disable no-unused-vars */
import UserService from "../services/UserService";
import alertify from "alertifyjs";
import { CookieStorage } from "../utils/Cookies";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";
import SettingsService from "../services/SettingsService";
import { useTranslation } from "react-i18next";

const useLoginSubmit = ({ navigate, isRemember }) => {
   const { setIsLoading } = useContext(SidebarContext);
   const { t } = useTranslation();
   const onSubmit = (data) => {
      setIsLoading(true);
      UserService.login(data)
         .then((res) => {
            alertify.set("notifier", "position", "top-right");
            alertify.success(t("success_sign_in"));

            CookieStorage.setToken(res.access_token);
            CookieStorage.setRefreshToken(res.refresh_token);
            // Load Config
            SettingsService.loadConfig()
               .then((res) => {
                  CookieStorage.setSetting(res.value);
               })
               .catch((err) => console.error(err));

            setIsLoading(false);
            navigate("/sales-dashboard");
         })
         .catch((err) => {
            setIsLoading(false);
            console.error("🚀 ~ onSubmit ~ err:", err);
            alertify.set("notifier", "position", "top-right");
            alertify.error(err?.detail || t("error_sign_in"));
         });
   };
   return { onSubmit };
};

export default useLoginSubmit;
